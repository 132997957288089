import React, { useEffect, useState } from 'react';
import styles from './footer.module.css';
import { Container, Typography } from '@mui/material';
import Footer_Picture from '../../assets/footer_payment (2).png'
import axios from 'axios';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';



function Footer() {

    const [data, setData] = useState('');
    const [logo, setLogo] = useState('');
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());


    useEffect(() => {
        (async () => {
            const navLink = await axios.get('https://developer-ma.com/ag_website/api/navbarlinks');
            const socialLink = await axios.get('https://developer-ma.com/ag_website/api/socialmedialinks');
            setData([{
                title: 'Social Media',
                links: socialLink?.data
            },
            {
                title: 'Company',
                links: navLink?.data,
            },
            {
                title: 'GET IN TOUCH',
                links: [
                    { name: ' Agile Kernel Tourism LLC ', link: '#' },
                    { name: 'No 35, 10th Floor, Exponenta Business Center,', link: '#' },
                    { name: ' Crystal Tower, Business Bay.', link: '#' },
                    { name: 'Dubai – United Arab Emirates', link: '#' },
                ],
            },
            {
                title: 'Support 24/7',
                links: [
                    { name: '+971 505042027', link: 'https://wa.me/+971505042027?text=Hi, can i get more information?', style: { marginTop: '55px' } },
                    { name: 'info@agiletourism.com', link: 'mailto:info@agiletourism.com', style: { marginTop: '35px', textDecoration: "underline" } },
                ],
            }
            ])
        })();

        (async () => {
            try {
                const response = await axios.get('https://developer-ma.com/ag_website/api/getLogo');
                setLogo(response?.data)
            } catch (error) {
            }
        })();
        setCurrentYear(new Date().getFullYear());
    }, [])




    return (
        <Container>
            {Array.isArray(data) ?
                <footer style={{ marginTop: 120 }} className={`mt-5 container ${styles.customFooter}`}>
                    <div className="row my- py-4" style={{ border: "none", borderTop: "1px solid #e0e0e0", borderBottom: "1px solid #e0e0e0" }}>
                        {data?.map((section, index) => {
                            return (
                                <div key={index} className="col-md-3 col-sm-6 col-md">
                                    <h5>{section.title}</h5>
                                    <ul className="list-unstyled text-small">
                                        {section?.links?.map((item, linkIndex) => {
                                            const image = section?.title === 'Social Media' && JSON.parse(item?.images);
                                            return (
                                                <li style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', margin: '10px' }} key={linkIndex}>
                                                    {section?.title === 'Social Media' && <div style={{ width: 36 }}>
                                                        <img loading='lazy' width={24} height={24} style={{ marginRight: 0 }} src={image} alt={image} />
                                                    </div>}
                                                    <a style={{ width: 'fit-content' }} className="text-muted " href={item?.link} target='_blank'>
                                                        <span style={{ width: 'fit-content' }} variant="body1">{item?.name}</span>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        })}
                       
                    </div>
                </footer> : <Skeleton paragraph={{ rows: 6 }} />}

            {/* Payment Gate ways */}

            <div className="paymentsGateways " style={{ borderBottom: "1px solid #e0e0e0", paddingBottom: "10px" }}>
                <h5 style={{ fontWeight: "bolder", marginLeft: "10px" }} className='py-2'>Ways You Can Pay</h5>
                <img loading='lazy' width={330} height={130} src={Footer_Picture} alt="Payment" className='py-3 my-3' style={{ marginLeft: "10px" }} />
            </div>


            {/* Final Footer */}
            <footer style={{ padding: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: "400" }}>
                <div>@{currentYear} Agile Dubai</div>
                <div>
                    <span style={{ marginRight: '10px', cursor: "pointer" }}>
                        <Link to='/dynamic/privacy-policy' style={{ color: "black" }}>
                            Privacy Policy
                        </Link>
                    </span>
                    <span style={{ marginRight: '10px', cursor: "pointer" }} >
                        <Link to='/dynamic/cookies-policy' style={{ color: "black" }}>
                            Cookies Policy
                        </Link>
                    </span>
                    <span style={{ marginRight: '10px', cursor: "pointer" }} >
                        <Link style={{ color: "black" }}>
                            Market
                        </Link>
                    </span>
                    <span style={{ marginRight: '10px', cursor: "pointer" }} >
                        <Link style={{ color: "black" }}>
                            Legal Notice
                        </Link>
                    </span>
                    {/* <span style={{ marginRight: '10px', cursor: "pointer", color: "black" }} >Market</span>
                    <span style={{ marginRight: '10px', cursor: "pointer", color: "black" }} >Legal Notice</span> */}
                </div>
            </footer>
        </Container>
    );
}

export default Footer;
