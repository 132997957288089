import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FiHelpCircle } from "react-icons/fi";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { Button, Checkbox, Popover, Spin, notification } from "antd";
import { useSelector } from "react-redux";
import "./Navbar.css";
import axios from "axios";
import OTPInput from "react-otp-input";
import WhatsappButton from "../WhatsAppButton/whatsappButton";


const buttonWidth = 70;

function NavbarNew({ navFromHome, Style }) {
  const Navigate = useNavigate();
  const [WishListCount, setWishListCount] = useState(0);

  const GetData = async () => {
    const id = localStorage.getItem('user');
    try {
      if (id) {
        const response = await axios.get(`https://developer-ma.com/ag_website/api/wishlist/${id}`)
        const arrayLength = response?.data.length;
        setWishListCount(arrayLength)

      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    GetData()
  }, [])


  const navlinks = [
    {
      label: "Wishlist",
      url: "/wishlist",
      icon: <AiOutlineHeart size={20} />,
    },
    {
      label: "Cart",
      url: "/cart",
      icon: <AiOutlineShoppingCart size={20} />,
    },
    {
      label: "Help",
      url: "/help",
      icon: <FiHelpCircle size={20} />,
    },
  ];

  const count = useSelector(state => state?.cart?.count);

  const [Token, setToken] = useState(localStorage.getItem('Token'))
  const [login, setLogin] = useState({ login: true, signin: false, forgot: false, otp: false, forgotPassword: false });
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [signinData, setSigninData] = useState({ name: '', lname: '', email: '', password: '' });
  const [loading, setLoading] = useState({ login: false, signin: false })
  const [content, setContent] = useState('');
  const [logo, setLogo] = useState('');
  const [forgot, setForgot] = useState({ email: '', otp: '', password: '' });
  const [showNavbar, setShowNavbar] = useState(true);

  const handleLogout = () => {
    localStorage.removeItem('Token');
    localStorage.clear();
    setToken(localStorage.getItem('Token'))
    notification.success({
      message: 'Success',
      description: 'Logout Successfull',
    })
  };

  const handleLogin = async () => {
    setLoading({ ...loading, login: true });
    try {
      const url = 'https://developer-ma.com/ag_website/api/login';
      const response = await axios.post(url, loginData);
      notification.success({ message: response?.data?.message });
      localStorage.setItem('Token', response?.data?.token);
      localStorage.setItem('user', response?.data?.user?.id);
      setLoginData({ email: '', password: '' })
      setToken(localStorage.getItem('Token'))
      setLoading({ ...loading, login: false });
    } catch (error) {
      console.log(error)
      notification.error({ message: error?.response?.data?.message });
      setLoading({ ...loading, login: false });
    }
  }

  const handleRegister = async () => {
    setLoading({ ...loading, signin: true });
    try {
      const url = 'https://developer-ma.com/ag_website/api/register';
      const response = await axios.post(url, signinData);
      notification.success({ message: response?.data?.message });
      setSigninData({ email: '', password: '', name: '', lname: '' })
      setLoading({ ...loading, signin: false });
      setLogin({ login: true })
    } catch (error) {
      console.log(error)
      notification.error({ message: error?.response?.data?.error });
      setLoading({ ...loading, signin: false });
    }
  }

  const handleForgotEmail = async () => {
    try {
      const response = await axios.post('https://developer-ma.com/ag_website/api/forgotpassword', { email: forgot?.email })
      if (response?.data?.message === 'Successfully sent') {
        setLogin({ ...login, forgot: false, otp: true });
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleForgotPassword = async () => {
    try {
      if (forgot?.password) {
        const response = await axios.post('https://developer-ma.com/ag_website/api/checkotp', forgot)
        if (response) {
          notification.success({ message: response?.data?.message })
          setLogin({ login: true })
        }
      }
      else {
        notification.error({ message: 'Enter New Password' });
      }
    }
    catch (error) {
      console.log(error)
      notification.success({ message: error?.data?.message })
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get('https://developer-ma.com/ag_website/api/all_page_name');
        console.log('first', response?.data?.data[0])
        if (response?.data?.data) {
          setContent(response?.data?.data);
        }
      } catch (error) {
        console.log(error)
      }

    })();
    (async () => {
      try {
        const response = await axios.get('https://developer-ma.com/ag_website/api/getLogo');
        setLogo(response?.data)
      } catch (error) {
        console.log(error);
      }
    })()
  }, [])

  console.log('first--=', content)

  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (token) {
      setShowNavbar(true)
    } else {
      setShowNavbar(false)
    }
  })

  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (token) {
      setShowNavbar(true)
    } else {
      setShowNavbar(false)
    }
  })

  const ShowLoginPopup = (e) => {
    if (e?.label === "Help") {
      Navigate('/help')
    } else {
      setLogin({ login: true })
      notification.error({ message: 'Please Login for accessing anything.' });
    }
  }

  return (
    <>
      {showNavbar && (
        <Navbar
          style={{
            boxShadow: "none",
            backgroundColor: Style ? "#F8F8F8B2" : "",
          }}
          className="custom-navbar"
          variant="dark"
          expand="lg"
        >
          <>
            <style>
              {`
             @media (max-width: 991px) { 
               .custom-navbar {
                 background-color: #DBB065;
               }
               .nav-link {
                 color: white;
               }
               .LoginBtn {
                 margin-left: 0 !important;
                 color: white !important;
                 background-color: black !important;
               }
               
             }
             
           
           `}
            </style>
            <Container>
              {logo ? <Navbar.Brand as={NavLink} to="/" >
                <img loading='lazy'
                  width={80}
                  height={60}
                  src={logo}
                  alt={logo}
                  className="d-inline-block align-top"
                />
              </Navbar.Brand> : <Spin />}
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <FaBars color="black" />
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  className="ms-auto"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Popover
                    placement="bottomLeft"
                    content={() => (
                      <div style={{ width: 340 }}>
                        <Link to={`dynamic/${content[0]?.slug}`} className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            {content[0]?.page_heading}
                          </h6>
                        </Link>
                        <Link to='city_tour' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            City Tours
                          </h6>
                        </Link>
                        <Link to='adventure' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            Adventures
                          </h6>
                        </Link>
                        <Link to='attraction' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            Attractions
                          </h6>
                        </Link>
                        <Link to='luxuryexperiences' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            Luxury Experiences
                          </h6>
                        </Link>
                        <Link to='transportation' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            Airport Transfer
                          </h6>
                        </Link>
                        <Link to={`dynamic/${content[8]?.slug}`} className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            {content[8]?.page_heading}
                          </h6>
                        </Link>
                        <Link to={`dynamic/${content[3]?.slug}`} className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            {content[3]?.page_heading}
                          </h6>
                        </Link>
                        <Link to={`dynamic/${content[4]?.slug}`} className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            {content[4]?.page_heading}
                          </h6>
                        </Link>
                        <Link to={`dynamic/${content[5]?.slug}`} className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            {content[5]?.page_heading}
                          </h6>
                        </Link>
                        <Link to={`dynamic/${content[6]?.slug}`} className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            {content[6]?.page_heading}
                          </h6>
                        </Link>
                        {/* {content ? content?.map((item, index) => {
                          return (
                            <Link key={index} to={`dynamic/${item?.slug}`} className="text-dark">
                              <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                                {item?.page_heading}
                              </h6>
                            </Link>
                          )
                        }) : 'No Data Available'} */}
                      </div>
                    )}
                    trigger="click"
                    className="m-2"
                  >
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "black",
                        cursor: "pointer",
                        padding: "10px",
                      }}
                    >
                      Explore
                    </Typography>
                  </Popover>

                  {navlinks.map((e) => {
                    return (
                      <NavLink
                        key={e?.label}
                        className="m-2"
                        to={e.url}
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textDecoration: "none",
                          marginRight: "15px",
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <div style={{ position: 'relative' }}>
                            {e.icon}
                          </div>
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              position: 'relative'
                            }}
                          >
                            {e.label}
                            {e?.label === 'Cart' && <p style={{ position: 'absolute', left: -15, top: -5, fontSize: 10, width: 16, height: 16, borderRadius: '100%', backgroundColor: '#DBB065', fontWeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{count}</p>}
                            {e?.label === 'Wishlist' && <p style={{ position: 'absolute', left: -15, top: -5, fontSize: 10, width: 16, height: 16, borderRadius: '100%', backgroundColor: '#DBB065', fontWeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{WishListCount}</p>}
                          </Typography>
                        </Stack>
                      </NavLink>
                    );
                  })}

                  {!Token && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <Popover
                          placement="bottomLeft"
                          trigger={['click']}
                          content={() => (login?.login ?
                            <div style={{ width: 220 }}>
                              <label style={{ width: '100%', fontWeight: 600, fontSize: 16 }}>E-mail</label>
                              <input value={loginData?.email} onChange={e => setLoginData({ ...loginData, email: e.target.value })} placeholder="Enter your email Address" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="email" />
                              <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Password</label>
                              <input value={loginData?.password} onChange={e => setLoginData({ ...loginData, password: e.target.value })} placeholder="Enter your password" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="password" name="" id="" />
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <p onClick={() => setLogin({ ...login, login: false, forgot: true })} style={{ color: '#bd00ff', fontWeight: 600, cursor: 'pointer' }}>I forgot my password</p>
                              </div>
                              <Checkbox><span>Remember Me</span></Checkbox>
                              <button onClick={handleLogin} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 8, marginBottom: 8, border: 'none', opacity: loading?.login ? 0.4 : 1 }}>Login</button>
                              <span style={{ fontSize: 12, fontWeight: 500 }}>Not registered?
                                <span onClick={() => setLogin({ signin: true })} style={{ color: '#bd00ff', fontWeight: 600, cursor: 'pointer' }}> Create an account</span>
                              </span>
                            </div> : login?.signin ?
                              <div style={{ width: 220 }}>
                                <label style={{ width: '100%', fontWeight: 600, fontSize: 16 }}>Name</label>
                                <input onChange={e => setSigninData({ ...signinData, name: e.target.value })} placeholder="Enter your name" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="text" />
                                <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Last Name</label>
                                <input onChange={e => setSigninData({ ...signinData, lname: e.target.value })} placeholder="Enter your last name" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="text" />
                                <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>E-mail</label>
                                <input onChange={e => setSigninData({ ...signinData, email: e.target.value })} placeholder="Enter your email Address" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="email" />
                                <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Password</label>
                                <input onChange={e => setSigninData({ ...signinData, password: e.target.value })} placeholder="Enter your password" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="password" name="" id="" />
                                <Checkbox><span>Remember Me</span></Checkbox>
                                <button onClick={handleRegister} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 8, marginBottom: 8, border: 'none', opacity: loading?.signin ? 0.4 : 1 }}>Register</button>
                                <span style={{ fontSize: 12, fontWeight: 500 }}>Already have account?
                                  <span onClick={() => setLogin({ login: true })} style={{ color: '#bd00ff', fontWeight: 600, cursor: 'pointer' }}> Login</span>
                                </span>
                              </div> : login?.forgot ?
                                <div style={{ width: 220 }}>
                                  <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>E-mail</label>
                                  <input onChange={e => setForgot({ ...forgot, email: e.target.value })} placeholder="Enter your email" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="email" name="" id="" />
                                  <button onClick={handleForgotEmail} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 12, marginBottom: 0, border: 'none', opacity: loading?.signin ? 0.4 : 1 }}>Get Code</button>
                                  <span onClick={() => setLogin({ ...login, otp: false, forgotPassword: true })} style={{ color: '#bd00ff', fontWeight: 600, cursor: 'pointer' }}>Go To Login</span>
                                </div> : login?.otp ?
                                  <div style={{ width: 220 }}>
                                    <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Enter OTP</label>
                                    <OTPInput
                                      value={forgot?.otp}
                                      containerStyle={{
                                        margin: "auto",
                                        marginBottom: "20px",
                                        marginTop: 32,
                                        justifyContent: "center",
                                      }}
                                      onChange={e => setForgot({ ...forgot, otp: e })}
                                      inputStyle={{
                                        width: "48px",
                                        padding: "12px",
                                        border: "1px solid #ACACAC",
                                        borderRadius: 12
                                      }}
                                      numInputs={4}
                                      renderSeparator={
                                        <span style={{ marginInline: "5px" }}>  </span>
                                      }
                                      renderInput={(props) => <input {...props} />}
                                    />
                                    <button onClick={() => forgot.otp ? setLogin({ ...login, otp: false, forgotPassword: true }) : notification.error({ message: 'Enter Code' })} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 8, marginBottom: 8, border: 'none', opacity: loading?.signin ? 0.4 : 1 }}>Send</button>
                                  </div> : login?.forgotPassword &&
                                  <div style={{ width: 220 }}>
                                    <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Enter New Password</label>
                                    <input onChange={e => setForgot({ ...forgot, password: e.target.value })} placeholder="Enter new password" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="text" name="" id="" />
                                    <button onClick={handleForgotPassword} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 12, marginBottom: 0, border: 'none', opacity: loading?.signin ? 0.4 : 1 }}>Confirm Password</button>
                                  </div>

                          )}
                        >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <button
                              className="LoginBtn"
                              style={{
                                borderRadius: "15px",
                                color: "black",
                                border: "none",
                                padding: "5px 25px",
                                backgroundColor: "#DBB065",
                                marginLeft: "50px",
                                marginBottom: "10px",
                                fontWeight: "400",
                              }}
                              onClick={() => setLogin({ login: true })}
                            >
                              Login
                            </button>
                            <button
                              className="LoginBtn"
                              style={{
                                borderRadius: "15px",
                                color: "black",
                                border: "none",
                                padding: "5px 25px",
                                marginLeft: "50px",
                                fontWeight: "400",
                                background: "none"
                              }}
                              onClick={() => setLogin({ signin: true })}
                            >
                              SignUp
                            </button>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  )}
                  {Token && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <Link to="/">
                          <button
                            className="LoginBtn"
                            style={{
                              borderRadius: "15px",
                              color: "black",
                              border: "none",
                              padding: "5px 25px",
                              backgroundColor: "#DBB065",
                              marginLeft: "50px",
                              fontWeight: "400",
                            }}
                            onClick={handleLogout}
                          >
                            Logout
                          </button>
                        </Link>
                      </div>

                    </div>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </>
        </Navbar >
      )}

      {!showNavbar && (
        <Navbar
          style={{
            boxShadow: "none",
            backgroundColor: Style ? "#F8F8F8B2" : "",
          }}
          className="custom-navbar"
          variant="dark"
          expand="lg"
        >
          <>
            <style>
              {`
                @media (max-width: 991px) { 
                  .custom-navbar {
                    background-color: #DBB065;
                  }
                  .nav-link {
                    color: white;
                  }
                  .LoginBtn {
                    margin-left: 0 !important;
                    color: white !important;
                    background-color: black !important;
                  }
                  
                }
                
              
              `}
            </style>
            <Container>
              {logo ? <Navbar.Brand as={NavLink} to="/">
                <img loading='lazy'
                  width={80}
                  height={60}
                  src={logo}
                  alt={logo}
                  className="d-inline-block align-top"
                />
              </Navbar.Brand> : <Spin />}
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <FaBars color="black" />
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  className="ms-auto"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Popover
                    placement="bottomLeft"
                    content={() => (
                      <div style={{ width: 340 }}>
                        <Link to='city_tour' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            City Tour
                          </h6>
                        </Link>
                        <Link to='adventure' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            Adventures
                          </h6>
                        </Link>
                        <Link to='attraction' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            Attractions
                          </h6>
                        </Link>
                        {/* <Link to='luxuryexperiences' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            Luxury Experiences
                          </h6>
                        </Link> */}
                        <Link to='luxuryexperiences' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            Luxury Experiences
                          </h6>
                        </Link>
                        <Link to='transportation' className="text-dark">
                          <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                            Airport Transfer
                          </h6>
                        </Link>
                        {content ? content?.map((item, index) => {
                          return (
                            <Link key={index} to={`dynamic/${item?.slug}`} className="text-dark">
                              <h6 style={{ fontSize: "14px", fontWeight: 700, paddingBottom: "5px" }}>
                                {item?.page_heading}
                              </h6>
                            </Link>
                          )
                        }) : 'No Data Available'}
                      </div>
                    )}
                    trigger="click"
                    className="m-2"
                  >
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "black",
                        cursor: "pointer",
                        padding: "10px",
                      }}
                    >
                      Explore
                    </Typography>
                  </Popover>
                  <NavLink
                    className="m-2"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textDecoration: "none",
                      marginRight: "15px",
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={3}>
                      <Link to="/" onClick={() => { ShowLoginPopup() }}>
                        <div className="d-flex align-items-center justify-content-between" style={{ width: "80px" }}>
                          <div style={{ position: 'relative' }}>
                            <AiOutlineHeart size={20} color="black" />
                          </div>
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              position: 'relative'
                            }}
                          >
                            Wishlist
                          </Typography>
                        </div>
                      </Link>
                      <Link onClick={() => { ShowLoginPopup() }}>
                        <div className="d-flex align-items-center justify-content-between" style={{ width: "55px" }}>
                          <div style={{ position: 'relative' }}>
                            <AiOutlineShoppingCart size={20} color="black" />
                          </div>
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              position: 'relative'
                            }}
                          >
                            Cart
                          </Typography>
                        </div>
                      </Link>
                      <Link to="/help">
                        <div className="d-flex align-items-center justify-content-between" style={{ width: "60px" }}>
                          <div style={{ position: 'relative' }}>
                            <FiHelpCircle size={20} color="black" />
                          </div>
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              position: 'relative'
                            }}
                          >
                            Help
                          </Typography>
                        </div>
                      </Link>
                    </Stack>

                  </NavLink>


                  {!Token && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <Popover
                          placement="bottomLeft"
                          trigger={['click']}
                          content={() => (login?.login ?
                            <div style={{ width: 220 }}>
                              <label style={{ width: '100%', fontWeight: 600, fontSize: 16 }}>E-mail</label>
                              <input value={loginData?.email} onChange={e => setLoginData({ ...loginData, email: e.target.value })} placeholder="Enter your email Address" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="email" />
                              <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Password</label>
                              <input value={loginData?.password} onChange={e => setLoginData({ ...loginData, password: e.target.value })} placeholder="Enter your password" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="password" name="" id="" />
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <p onClick={() => setLogin({ ...login, login: false, forgot: true })} style={{ color: '#bd00ff', fontWeight: 600, cursor: 'pointer' }}>I forgot my password</p>
                              </div>
                              <Checkbox><span>Remember Me</span></Checkbox>
                              <button onClick={handleLogin} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 8, marginBottom: 8, border: 'none', opacity: loading?.login ? 0.4 : 1 }}>Login</button>
                              <span style={{ fontSize: 12, fontWeight: 500 }}>Not registered?
                                <span onClick={() => setLogin({ signin: true })} style={{ color: '#bd00ff', fontWeight: 600, cursor: 'pointer' }}> Create an account</span>
                              </span>
                            </div> : login?.signin ?
                              <div style={{ width: 220 }}>
                                <label style={{ width: '100%', fontWeight: 600, fontSize: 16 }}>Name</label>
                                <input onChange={e => setSigninData({ ...signinData, name: e.target.value })} placeholder="Enter your name" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="text" />
                                <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Last Name</label>
                                <input onChange={e => setSigninData({ ...signinData, lname: e.target.value })} placeholder="Enter your last name" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="text" />
                                <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>E-mail</label>
                                <input onChange={e => setSigninData({ ...signinData, email: e.target.value })} placeholder="Enter your email Address" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="email" />
                                <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Password</label>
                                <input onChange={e => setSigninData({ ...signinData, password: e.target.value })} placeholder="Enter your password" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="password" name="" id="" />
                                <Checkbox><span>Remember Me</span></Checkbox>
                                <button onClick={handleRegister} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 8, marginBottom: 8, border: 'none', opacity: loading?.signin ? 0.4 : 1 }}>Register</button>
                                <span style={{ fontSize: 12, fontWeight: 500 }}>Already have account?
                                  <span onClick={() => setLogin({ login: true })} style={{ color: '#bd00ff', fontWeight: 600, cursor: 'pointer' }}> Login</span>
                                </span>
                              </div> : login?.forgot ?
                                <div style={{ width: 220 }}>
                                  <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>E-mail</label>
                                  <input onChange={e => setForgot({ ...forgot, email: e.target.value })} placeholder="Enter your email" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="text" name="" id="" />
                                  <button onClick={handleForgotEmail} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 12, marginBottom: 0, border: 'none', opacity: loading?.signin ? 0.4 : 1 }}>Get Code</button>
                                  <span onClick={() => setLogin({ ...login, otp: false, forgotPassword: true })} style={{ color: '#bd00ff', fontWeight: 600, cursor: 'pointer' }}>Go To Login</span>
                                </div> : login?.otp ?
                                  <div style={{ width: 220 }}>
                                    <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Enter OTP</label>
                                    <OTPInput
                                      value={forgot?.otp}
                                      containerStyle={{
                                        margin: "auto",
                                        marginBottom: "20px",
                                        marginTop: 32,
                                        justifyContent: "center",
                                      }}
                                      onChange={e => setForgot({ ...forgot, otp: e })}
                                      inputStyle={{
                                        width: "48px",
                                        padding: "12px",
                                        border: "1px solid #ACACAC",
                                        borderRadius: 12
                                      }}
                                      numInputs={4}
                                      renderSeparator={
                                        <span style={{ marginInline: "5px" }}>  </span>
                                      }
                                      renderInput={(props) => <input {...props} />}
                                    />
                                    <button onClick={() => forgot.otp ? setLogin({ ...login, otp: false, forgotPassword: true }) : notification.error({ message: 'Enter Code' })} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 8, marginBottom: 8, border: 'none', opacity: loading?.signin ? 0.4 : 1 }}>Send</button>
                                  </div> : login?.forgotPassword &&
                                  <div style={{ width: 220 }}>
                                    <label style={{ width: '100%', fontWeight: 600, fontSize: 16, marginTop: 12 }}>Enter New Password</label>
                                    <input onChange={e => setForgot({ ...forgot, password: e.target.value })} placeholder="Enter new password" style={{ width: '100%', height: 42, borderRadius: 8, padding: 2 }} type="text" name="" id="" />
                                    <button onClick={handleForgotPassword} style={{ width: '100%', height: 40, color: '#fff', fontSize: 18, borderRadius: 8, backgroundColor: '#bd00ff', marginTop: 12, marginBottom: 0, border: 'none', opacity: loading?.signin ? 0.4 : 1 }}>Confirm Password</button>
                                  </div>

                          )}
                        >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <button
                              className="LoginBtn"
                              style={{
                                borderRadius: "15px",
                                color: "black",
                                border: "none",
                                padding: "5px 25px",
                                backgroundColor: "#DBB065",
                                marginLeft: "50px",
                                marginBottom: "10px",
                                fontWeight: "400",
                              }}
                              onClick={() => setLogin({ login: true })}
                            >
                              Login
                            </button>
                            <button
                              className="LoginBtn"
                              style={{
                                borderRadius: "15px",
                                color: "black",
                                border: "none",
                                padding: "5px 25px",
                                marginLeft: "50px",
                                fontWeight: "400",
                                background: "none"
                              }}
                              onClick={() => setLogin({ signin: true })}
                            >
                              SignUp
                            </button>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  )}
                  {Token && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <Link to="/">
                          <button
                            className="LoginBtn"
                            style={{
                              borderRadius: "15px",
                              color: "black",
                              border: "none",
                              padding: "5px 25px",
                              backgroundColor: "#DBB065",
                              marginLeft: "50px",
                              fontWeight: "400",
                            }}
                            onClick={handleLogout}
                          >
                            Logout
                          </button>
                        </Link>
                      </div>

                    </div>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </>
        </Navbar >
      )
      }
            <WhatsappButton />

    </>
  );
}

export default NavbarNew;
