import React from 'react';
import loader from '../../assets/textImg.png'
import './loader.css'
function PageLoader() {
    return (
        <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-center" style={{ width: "100%", height: "100vh" }}>
                <div className="col-md-12 text-center">
                    <center>
                        <img loading='lazy' src={loader} alt="" style={{ width: "400px" }} />
                        <div className="loader__element">

                        </div>
                    </center>
                </div>
            </div>
        </div>
    )
}

export default PageLoader