import { Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function RoomCard(props) {
    const { title, paragraphs, buttonText, imageUrl, data } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate('');

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div className="col-md-4 mb-4 p-2">
            <div className="card p-3" style={{ width: '360px', height: '510px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
                <img loading='lazy' src={imageUrl} className="card-img-top" alt="Card" style={{ height: '200px', objectFit: 'cover' }} />
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <ul>
                        {paragraphs.map((text, index) => (
                            <li key={index} className="card-text" style={{ fontSize: '16px' }}>{text}</li>
                        ))}
                    </ul>
                    <button onClick={() => navigate('/hotelbooking', { state: data })} className="btn btn-purple" style={{ borderRadius: '20px', backgroundColor: '#be23ff', color: 'white', float: 'right' }}>{buttonText}</button>
                </div>
            </div>
        </div>
    );
}

export default RoomCard;

