import React from "react";
import "./CityTourTwo.css";
import bgTourMain from "../../../assets/carTour.jpg";
import Typography from "@mui/material/Typography";
import { Grid, Container, Stack } from "@mui/material";
import MediaCard from "../../../components/Cards/MediaCard"
const CityTourTwo = () => {
  return (
    <>
      <div className="customTourBg">
        <div className="textImageContainer">
          <img loading='lazy'
            src={bgTourMain}
            alt="Main Background Image"
            className="mainBackgroundImage"
          />
          <Typography
            variant="h5"
            className="centeredImage"
            sx={{ fontSize: "100px", fontWeight: 900 }}
          >
            City Tours
          </Typography>
        </div>
      </div>

      <Stack sx={{ backgroundColor: "#E0E0E0" }} >
        <Container   >
          <Grid
            container
            spacing={1}
            mt={3}
            direction="row"
            justifyContent="center"
            alignItems="start"
            alignContent="center"
            wrap="wrap"
          >
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>

            <Grid padding={2} xs={12} sm={12} md={12} lg={12}>
              <Stack>
                <Typography variant="h6" sx={{ fontSize: "36px", fontWeight: 400, color: "black" }} >You might also like...</Typography>
              </Stack>
            </Grid>




            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
            <Grid padding={2} xs={12} sm={10} md={6} lg={4}>
              <MediaCard />
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </>
  );
};

export default CityTourTwo;
