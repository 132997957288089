import { configureStore } from '@reduxjs/toolkit'
import flight_Reducer from "./reducer.js";
import hotel_Reducer from "./HotelReducer.js";
import showcontent from './showcontent.jsx';
import hotel_offer from './HotelOffer.jsx';
import cartSlice from './cartSlice.js'

export const store = configureStore({
    reducer: {
        flight_data: flight_Reducer,
        hotel_data: hotel_Reducer,
        ShowContent: showcontent,
        hotel_offer: hotel_offer,
        cart: cartSlice
    }
})