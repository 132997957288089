import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectFlight: []
}

export const counterSlice = createSlice({
    name: 'flight_data',
    initialState,
    reducers: {
        selectFlightData: (state, action) => {
            state.selectFlight = [action.payload]
        },
        deleteHotelData: (state) => {
            state.selectFlight = []
        }
    },
})

export const { selectFlightData } = counterSlice.actions

export default counterSlice.reducer