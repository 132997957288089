import React, { useEffect, useState } from "react";
import { Avatar, Rate } from "antd";
import styles from './review.module.css'
import axios from "axios";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
function Review({ packageData }) {
  // const id = localStorage.getItem('Token')?.split('|')?.[0];
  const id = localStorage.getItem('user');
  const [reviewsData, setreviewsData] = useState([]);
  const [counts, setCount] = useState("");
  const [starRating, setStarRating] = useState([]);
  const [filterRating, setFilterRating] = useState("");
  const [serviceCount, setServiceCount] = useState("")
  const [TransportCount, setTransportCount] = useState("")
  const [GuidanceCount, setGuidanceCount] = useState("")
  const [moneyCount, setmoneyCount] = useState("")
  const [selectedRating, setSelectedRating] = useState(null);

  console.log(packageData.id, 'packageData')


  const averageRating = starRating.length > 0
    ? starRating.reduce((sum, rating) => sum + rating, 0) / starRating.length
    : 0;


  var services = 0;
  var guidence = 0;
  var trans = 0;
  var val_money = 0;
  const fetchData = async () => {
    const userId = localStorage.getItem('user');
    console.log(userId)
    try {
      const response = await axios.get(`https://developer-ma.com/ag_website/api/reviews/${packageData.activity_type}/${packageData.id}`);
      setreviewsData(response.data?.reviews);
      response.data?.reviews.map((item) => {
        services += item.services
        guidence += item.guidance
        trans += item.transportation
        val_money += item.value_money
      }
      )
      setServiceCount(services);
      setTransportCount(trans)
      setGuidanceCount(guidence)
      setmoneyCount(val_money)
      setCount(response.data?.count);
      setStarRating(response.data?.ratings);
      console.log(response?.data, '......')
    } catch (error) {
    }
  };

  const filterDataChange = async (e, rating) => {
    setSelectedRating(selectedRating === rating ? null : rating);
    try {
      const response = await axios.get(`https://developer-ma.com/ag_website/api/reviewsbyrating/${packageData.activity_type}/${packageData.id}/${e.target.value}`);
      setreviewsData([])
      if (e.target.value == 'All star rating Stars') {
        fetchData()
      }
      return (
        setreviewsData(response?.data)
      )
    } catch (error) {
    }
  };




  useEffect(() => {
    filterDataChange()
  }, [filterRating])


  useEffect(() => {
    fetchData()
  }, [])



  const progressBarData = [
    { title: "Guidance", width: `${GuidanceCount}%` },
    { title: "Transportation", width: `${TransportCount}%` },
    { title: "Value for Money", width: `${moneyCount}%` },
    { title: "Services", width: `${serviceCount}%` },
  ];


  const starRatingsData = [
    { rating: "All star rating" },
    {
      rating: 5,
      stars: <Rating sx={{ color: "#dbb065" }} readOnly defaultValue={5} />,
    },
    {
      rating: 4,
      stars: <Rating sx={{ color: "#dbb065" }} readOnly defaultValue={4} />,
    },
    {
      rating: 3,
      stars: <Rating sx={{ color: "#dbb065" }} readOnly defaultValue={3} />,
    },
    {
      rating: 2,
      stars: <Rating sx={{ color: "#dbb065" }} readOnly defaultValue={2} />,
    },
    {
      rating: 1,
      stars: <Rating sx={{ color: "#dbb065" }} readOnly defaultValue={1} />,
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row py-4">
          <h3 style={{ fontWeight: "bolder" }}>Customer Reviews</h3>
        </div>
        {reviewsData?.length ? <>
          <div className="row">
            <div
              className={`col-lg-6 col-md-6 col-sm-12 ${styles.cutomerReview}`}
            >
              <div className="cols-12" style={{ fontWeight: "bolder" }}>
                Overall rating
              </div>
              <div
                className="cols-12  text-center p-4 "
                style={{ fontWeight: "bolder", fontSize: "20px" }}
              >
                {averageRating}/5
              </div>
              <div className="cols-12 text-center p-4" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                <center>
                  <Stack>
                    <Rating name="half-rating-read" value={averageRating} precision={0.5} readOnly />
                  </Stack>
                </center>
              </div>
              <div className="cols-12  text-center p-4">
                Based on {counts} reviews
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="cols-12" style={{ fontWeight: "bolder" }}>
                Overall rating
              </div>
              <div className="row py-4">
                {progressBarData.map((item, i) => (
                  <>
                    <div
                      key={i}
                    >
                      <div className="col-lg-12 col-md-12 col-sm-12 py-3" style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
                        {item.title}
                        <div
                          style={{
                            backgroundColor: "#e6f4ff",
                            height: "10px",
                            width: "50%",
                            borderRadius: "20px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#dbb065",
                              height: "100%",
                              width: item.width,
                              borderRadius: "20px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>

          {/* Review Section */}

          <div className="row my-5">
            <div className="col-lg-4">
              <div className="">
                <div className="py-3" style={{ marginTop: "20px" }}>
                  <h5 style={{ fontWeight: "bolder" }}>Star rating</h5>
                  {starRatingsData.map((rating) => (
                    <div className="py-2 d-flex align-items-center">
                      <input type="checkbox" className="mx-2" name="checkbox" onChange={(e) => filterDataChange(e, rating.rating)} value={rating.rating} /> {rating.rating} <span className="mx-2" >Stars</span>
                      <span className="mx-1" > {rating.stars}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="col-lg-8"
              style={{ height: "700px", overflow: "hidden", overflowY: "scroll" }}
            >
              {reviewsData?.length > 0 ? (
                reviewsData.map((review) => (
                  <>
                    <div className="rate py-2">
                      <Stack>
                        <Rating name="half-rating-read" value={review.rating} precision={0.5} readOnly />
                      </Stack>
                    </div>
                    <div className={`${styles.review} py-1`}>{review.review}</div>
                    <div className="user" style={{ paddingBottom: "30px" }}>
                      <span>
                        <Avatar size={44} src={review.avatar} />
                        <span style={{ fontSize: "17px", marginLeft: "10px" }}>
                          {review.user?.name} {review.user?.lname}
                        </span>
                      </span>
                    </div>
                  </>
                ))
              ) : (
                <p>Reviews not found.</p>
              )}

            </div>
          </div>
        </> : <h2 className="text-center">No Reviews Found</h2>}
      </div>
    </>

  );
}

export default Review;

