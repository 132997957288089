import React, { useEffect, useState } from "react";
import { Steps, message, notification } from "antd";
import { Typography, Stack, TextField, Button, CircularProgress, InputLabel, Input, FormHelperText } from "@mui/material";
import styles from '../../Pages/FlightTab/Flight_ticket.module.css'
import { AiOutlineLock } from "react-icons/ai";
import axios from "axios";
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import './index.css'
import { useNavigate } from "react-router-dom";
import './index.css'
import { PhoneInput } from "react-international-phone";
import { OutlinedInput } from '@mui/material';
import { FormControl } from '@mui/material';

const { Step } = Steps;

const steps = [
  {
    title: "Personal",
    content: "First-content",
  },
  {
    title: "Payment",
    content: "Last-content",
  }
];


const Steeper = ({ setUserDetails, userDetails, data, promo, percen }) => {

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const Navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const url = 'https://developer-ma.com/ag_website/api/bookingCity'
  const stripe = useStripe();
  const elements = useElements();
  const [loader, setLoader] = useState(false);
  const cardElementStyle = {
    marginTop: 120,
    base: {
      fontSize: '16px',
      fontFamily: 'Arial, sans-serif',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: 'red',
    },
  };

  const travellers = data?.infant + data?.children + data?.adult


  const next = () => {
    if (userDetails.name && userDetails.email && userDetails.country && userDetails.phone) {
      setCurrent(current + 1);
    }
    else {
      notification.error({ message: 'Enter Details' })
    }
  };

  const handleData = () => {
    const item = data?.map(value => {
      const packageData = value?.all_data;
      const price = value?.price * Number(value?.no_of_travellers);
      return {
        'activity_type': value?.activity_type?.toLowerCase(),
        'date': value.date,
        'time': value.time_string,
        'quantity': value?.no_of_travellers,
        'no_of_travellers': value?.no_of_travellers,
        'activity_id': packageData?.id,
        'price': typeof percen !== 'number' ? price : price - (price * percen),
        'country': userDetails.country,
      }
    });
    return item;
  }

  const handleStripeToken = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem('Token');
    setLoader(true);
    if (!userToken) {
      setLoader(false);
      return notification.error({ message: 'Please login For Booking' })
    }
    else if (!name) {
      setLoader(false);
      return notification.error({ message: 'Please Enter Name' })
    }
    try {
      const { token, error } = await stripe.createToken(elements.getElement(CardElement))
      if (error) {
        console.log(error)
        notification.error({ message: error.message })
        setLoader(false);
      }
      else if (token) {
        const response = await axios.post(url, {
          data: {
            'activity_type': 'booking',
            'notes': '',
            'customer_name': userDetails.name,
            'customer_email': userDetails.email,
            'payment_method': 'stripe',
            'customer_phone': userDetails.phone,
            'customer_id': userToken?.split('|')?.[0],
            'id': token.id,
            'country': userDetails.country,
            'total_price': promo,
            'item': handleData()
          }
        }, { headers: { 'Authorization': 'Bearer ' + userToken } })
        if (response) {
          console.log(response);
          setLoader(false);
          Navigate('/')
          notification.success({ message: response.data })
        }
      }
    }
    catch (e) {
      console.log(e);
      setLoader(false);
      notification.error({ message: e?.response?.data?.message })
    }
  }


  return (
    <div>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">
        {current === 0 && (
          <Stack padding={2}>
            <Stack direction="column">
              <Typography
                sx={{ fontSize: "24px", fontWeight: "bold" }}
                variant="h3"
              >
                Enter your personal detail
              </Typography>
            </Stack>


            <Stack margin={1} direction="row" justifyContent="start" alignItems="center" spacing={1}  >
              <AiOutlineLock color="#DBB065" />
              <Typography variant="body1" sx={{ fontSize: "12px", fontWeight: 600 }} >Checkout is fast and secure</Typography>
            </Stack>



            <Stack className="tour" direction="column" marginY={3} spacing={2}>
              <TextField
                id="FullName"
                label="Full Name"
                value={userDetails.name}
                onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
              />
              <TextField
                id="FullName"
                label="Email"
                value={userDetails.email}
                onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
              />
              <TextField
                id="FullName"
                label="Country"
                value={userDetails.country}
                onChange={(e) => setUserDetails({ ...userDetails, country: e.target.value })}
              />
              <PhoneInput
                style={{ width: '100%', border: 'none', height: 56 }}
                defaultCountry="ua"
                value={phone}
                onChange={(value) => setUserDetails({ ...userDetails, phone: value })}
              />
            </Stack>
          </Stack>
        )}
        {current === 1 &&
          <form style={{ margin: '32px 0px' }} onSubmit={handleStripeToken}>
            <input
              onChange={(e) => setName(e.target.value)}
              placeholder="Name on card"
              style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: 'none', outline: 'none', paddingLeft: 30, fontSize: 16, marginBottom: 16 }}
              type="text"
              className="name-input"
            />
            <CardElement options={{ style: cardElementStyle }} />
            <div style={{ marginTop: 32, display: 'flex', gap: 24 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#BD00FF",
                  padding: "8px 24px",
                  borderRadius: "24px",
                  "&:hover, &:focus": {
                    backgroundColor: "#BD00FF",
                  },
                }}
                onClick={() => setCurrent(0)}
              >
                Previous
              </Button>
              {!loader ?
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#BD00FF",
                    padding: "8px 24px",
                    borderRadius: "24px",
                    "&:hover, &:focus": {
                      backgroundColor: "#BD00FF",
                    },
                  }}
                >
                  Submit Payment
                </Button> :
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#BD00FF",
                    padding: "8px 24px",
                    borderRadius: "24px",
                    "&:hover, &:focus": {
                      backgroundColor: "#BD00FF",
                    },
                  }}
                >
                  <CircularProgress color="inherit" size={18} />
                </Button>

              }
            </div>
          </form>
        }
      </div>

      <Stack direction="column" justifyContent="start" alignItems="start">
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#BD00FF",
                padding: "8px 45px",
                borderRadius: "14px",
                "&:hover, &:focus": {
                  backgroundColor: "#BD00FF",
                },
              }}
              onClick={next}
            >
              Continue
            </Button>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default Steeper;
