import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Rating,
  Stack,
  Typography,
  Grid,
  Button,
  Divider,
  Breadcrumbs,
} from "@mui/material";
import "./SingleCard.css";
import Review from "../../components/CustomerReviews/Review";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { AiOutlineCaretDown } from "react-icons/ai";
import styles from "../../../src/components/Order/order.module.css";
import dayjs from "dayjs";
import { Carousel, notification, Modal, Input, Form, InputNumber, Spin } from "antd";
import { addCart } from "../../store/cartSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Rate } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { FaWhatsapp } from 'react-icons/fa';
import { CiPhone } from 'react-icons/ci';
import { MdOutlineEmail } from 'react-icons/md';
import "swiper/css";

const desc = ["bad", "normal", "good", "wonderful"];

const SingleCard = ({ props, activity_type }) => {
  const scrollableDivRef = useRef(null);
  const [infantCount, setInfantCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);
  const [adultCount, setAdultCount] = useState(0);
  const [travellerCount, setTravellerCount] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [showAvailiblity, setShowAvailiblity] = useState(false);
  const [selectedTime, setSelectedTime] = useState({ time: "", id: "" });
  const dispatch = useDispatch();
  const today = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();
  const [value, setValue] = useState(4);
  const [Guidance, setGuidance] = useState(4);
  const [Services, setServices] = useState(4);
  const [ValueMoney, setValueMoney] = useState(4);
  const [Transportation, setTransportation] = useState(4);
  const [reviewText, setReviewText] = useState("");
  const [counts, setCount] = useState("");
  const [starRating, setStarRating] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 40,
      behavior: "smooth",
    });
  }, []);

  const contentStyle = {
    margin: 0,
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleIncrement = (option) => {
    switch (option) {
      case "infant":
        setInfantCount(infantCount + 1);
        break;
      case "children":
        setChildrenCount(childrenCount + 1);
        break;
      case "adult":
        setAdultCount(adultCount + 1);
        break;
      default:
        break;
    }
  };
  const handleDecrement = (option) => {
    switch (option) {
      case "infant":
        if (infantCount > 0) {
          setInfantCount(infantCount - 1);
        }
        break;
      case "children":
        if (childrenCount > 0) {
          setChildrenCount(childrenCount - 1);
        }
        break;
      case "adult":
        if (adultCount > 0) {
          setAdultCount(adultCount - 1);
        }
        break;
      default:
        break;
    }
  };

  const location = useLocation();
  const packageData = location?.state?.data;

  const addDetail = () => {
    const element = document.getElementById("section-1");
    if (adultCount < 1) {
      element.scrollIntoView({ behavior: "smooth" });
      return notification.error({
        message: "Please add at least one adult traveller",
      });
    } else {
      if (infantCount + childrenCount + adultCount < 1) {
        element.scrollIntoView({ behavior: "smooth" });
        return notification.error({ message: "Enter at least one traveller" });
      }
      if (!selectedDate) {
        element.scrollIntoView({ behavior: "smooth" });
        return notification.error({ message: "Select Date" });
      }
      if (!selectedTime.time) {
        element.scrollIntoView({ behavior: "smooth" });
        return notification.error({ message: "Select Time" });
      }
      navigate("/checkout", {
        state: [
          {
            activity_type: packageData.activity_type,
            price: packageData.newPrice,
            id: packageData.id,
            date: selectedDate,
            time: selectedTime.id,
            time_string: selectedTime?.time,
            no_of_travellers:
              Number(infantCount) + Number(childrenCount) + Number(adultCount),
            all_data: packageData,
          },
        ],
      });
    }
  };

  const {
    title,
    singleLineDetail,
    longDetail,
    oldPrice,
    newPrice,
    images,
    highlights,
    full_decription,
    includes,
    not_suitable,
    important_information,
    meeting_point,
    time,
  } = location?.state?.data;

  const imagesArray = JSON.parse(images.replace(/\\/g, ""));
  const imageSrcs = imagesArray.map((image) => image.replace(/\\/g, ""));
  const discountPercentage = oldPrice
    ? ((oldPrice - newPrice) / oldPrice) * 100
    : 0;
  const roundedDiscount = discountPercentage.toFixed(0);

  const imageContainerStyle = {
    marginBottom: "8px",
  };

  const handleAddCart = async (data) => {
    const token = localStorage.getItem("Token");
    // const id = localStorage.getItem('Token')?.split('|')?.[0];
    const id = localStorage.getItem("user");
    const element = document.getElementById("section-1");
    if (!token) {
      return notification.error({ message: "Please Login to Add Cart" });
    }

    if (adultCount < 1) {
      element.scrollIntoView({ behavior: "smooth" });
      return notification.error({
        message: "Please add at least one adult traveller",
      });
    } else {
      if (adultCount < 1) {
        element.scrollIntoView({ behavior: "smooth" });
        return notification.error({ message: "Enter Travellers" });
      }
      if (!selectedDate) {
        element.scrollIntoView({ behavior: "smooth" });
        return notification.error({ message: "Select Date" });
      }
      if (!selectedTime.time) {
        element.scrollIntoView({ behavior: "smooth" });
        return notification.error({ message: "Select Time" });
      }

      try {
        const response = await axios.post(
          `https://developer-ma.com/ag_website/api/addtocart?user_id=${id}&activity_id=${data?.id
          }&activity_type=${data?.activity_type}&no_of_travellers=${infantCount + childrenCount + adultCount
          }&date=${dayjs(selectedDate).format("YYYY-MM-DD")}&start_time=${selectedTime?.time
          }&time_id=${selectedTime?.id}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const response2 = await axios(
          `https://developer-ma.com/ag_website/api/cart/${id}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        notification.success({ message: response?.data?.message });
        if (response2) {
          dispatch(addCart(response2?.data?.length));
        }
      } catch (error) {
        console.log(error);
        notification.success({ message: error?.response?.data?.message });
      }
    }
  };

  const handlePostRequest = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      notification.error({
        message: "Error",
        description: "Log in to add a review",
      });
      return;
    }

    const userId = localStorage.getItem("user");
    if (!userId) {
      notification.error({
        message: "Error",
        description: "User ID not found. Please log in again.",
      });
      return;
    }

    if (reviewText === "") {
      notification.error({
        message: "Error",
        description: "Review cannot be empty!",
      });
      return;
    }

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const url = `https://developer-ma.com/ag_website/api/reviews?activity_type=${encodeURIComponent(packageData.activity_type)}&activity_id=${encodeURIComponent(packageData.id)}&user_id=${userId}&review=${encodeURIComponent(reviewText)}&rating=${value}&guidance=${encodeURIComponent(Guidance)}&transportation=${encodeURIComponent(Transportation)}&value_money=${encodeURIComponent(ValueMoney)}&services=${encodeURIComponent(Services)}`;

      const response = await axios.post(url, {}, { headers });

      notification.success({
        message: "Success",
        description: "Review created Successfully!",
      });
      fetchData();
    } catch (error) {
      notification.error({
        message: "Error",
        description: error?.message || "An error occurred while creating the review.",
      });
    }

    setReviewText("");
  };


  // const id = localStorage.getItem('Token')?.split('|')?.[0];
  const id = localStorage.getItem("user");

  const averageRating =
    starRating.length > 0
      ? starRating.reduce((sum, rating) => sum + rating, 0) / starRating.length
      : 0;

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://developer-ma.com/ag_website/api/reviews/${packageData.activity_type}/${packageData.id}`);
      setCount(response.data?.count);
      setStarRating(response.data?.ratings);
    } catch (error) { }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChange = (currentSlide) => { };


  // Contact Form Code
  const [showForm, setShowForm] = useState(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    bookingdetails: '',
    message: '',
    adults: 0,
    children: 0
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const response = await axios.post('https://developer-ma.com/ag_website/api/llcontactform', formData);
      console.log('Response:', response); // Log response for debugging
      notification.success({
        message: "Thank You We Will get Back to You within 24 hours"
      });
      setShowForm(false);
      setLoading(false);
      form.resetFields();
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } catch (error) {
      console.error('Error:', error);
      notification.error({
        message: 'An error occurred. Please try again later.'
      });
      setLoading(false);

    }
  };

  const pathname = window.location.pathname.split('/')[1]

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        centered
        footer={false}
      >
        <Container className="my-5">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {imagesArray.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    loading="lazy"
                    src={item}
                    style={{ width: "100%", height: "100vh" }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      </Modal>
      <Container>
        <Stack direction="column" spacing={1}>
          <Stack>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" to="/" className="text-dark p">
                <span style={{ fontSize: "12px" }}> {pathname}</span>
              </Link>

              <Typography style={{ fontSize: "12px" }} color="text.primary">
                {title}
              </Typography>
            </Breadcrumbs>
          </Stack>

          {/* Title */}
          <Stack>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
            >
              {pathname}
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", fontSize: { xs: "24px", md: "36px" } }}
            >
              {title}
            </Typography>
          </Stack>

          {/* Rating and Provider Info */}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row">
              <center>
                <Stack spacing={1}>
                  <Rating
                    name="half-rating-read"
                    value={averageRating}
                    precision={0.5}
                    readOnly
                  />
                </Stack>
              </center>
              <span style={{ marginLeft: "3px" }}> {averageRating}/5</span>
            </Stack>
            <Typography
              variant="body1"
              sx={{ color: "#DBB065", fontSize: { xs: "14px", md: "16px" } }}
            >
              {counts} Reviews
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
            >
              Activity Provider By Agile Tours
            </Typography>
          </Stack>
        </Stack>
      </Container>

      {/* Image Section */}
      <Container style={{ height: "100%", minHeight: 500 }}>
        {imageSrcs.length > 3 && (
          <Grid mt={1} container spacing={0.5}>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <div style={imageContainerStyle}>
                <img
                  loading="lazy"
                  src={imageSrcs[0]}
                  style={{ objectFit: "cover", width: "100%", height: 500 }}
                  alt="Image 1"
                />
              </div>
            </Grid>

            <Grid item xl={6} md={6} sm={12} xs={12}>
              <div style={imageContainerStyle}>
                <img
                  loading="lazy"
                  src={imageSrcs[1]}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    display: "block",
                    height: 500 / 2,
                  }}
                  alt="Image 2"
                />
              </div>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={0.5}
              >
                <Grid item xl={6} md={6} sm={6} xs={12}>
                  <div style={imageContainerStyle}>
                    <img
                      loading="lazy"
                      src={imageSrcs[2]}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: 480 / 2,
                      }}
                      alt="Image 3"
                    />
                  </div>
                </Grid>
                <Grid
                  style={{ position: "relative", cursor: "pointer" }}
                  onClick={showModal}
                  item
                  xl={6}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  {imageSrcs.length > 4 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 285,
                        height: 480 / 2,
                        backgroundColor: "rgba(0,0,0,0.8)",
                        zIndex: 10,
                        opacity: 0.7,
                        marginTop: 4,
                        marginLeft: 4,
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          fontSize: 48,
                          fontWeight: 500,
                        }}
                      >
                        +{imageSrcs.length - 4}
                      </p>
                    </div>
                  )}
                  <div style={imageContainerStyle}>
                    <img
                      loading="lazy"
                      src={imageSrcs[3]}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: 480 / 2,
                      }}
                      alt="Image 4"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {imageSrcs?.length === 3 && (
          <Grid mt={1} container spacing={0.5}>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <div style={imageContainerStyle}>
                <img
                  loading="lazy"
                  src={imageSrcs[0]}
                  style={{ objectFit: "cover", width: "100%", height: 500 }}
                  alt="Image 1"
                />
              </div>
            </Grid>

            <Grid item xl={6} md={6} sm={12} xs={12}>
              <div style={imageContainerStyle}>
                <img
                  loading="lazy"
                  src={imageSrcs[1]}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    display: "block",
                    height: 500 / 2,
                  }}
                  alt="Image 2"
                />
              </div>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={0.5}
              >
                <Grid item xl={12} md={12} sm={12} xs={12}>
                  <div style={imageContainerStyle}>
                    <img
                      loading="lazy"
                      src={imageSrcs[2]}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: 480 / 2,
                      }}
                      alt="Image 3"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {imageSrcs?.length === 2 && (
          <Grid mt={1} container spacing={0.5}>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <div style={imageContainerStyle}>
                <img
                  loading="lazy"
                  src={imageSrcs[0]}
                  style={{ objectFit: "cover", width: "100%", height: 500 }}
                  alt="Image 1"
                />
              </div>
            </Grid>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <div style={imageContainerStyle}>
                <img
                  loading="lazy"
                  src={imageSrcs[1]}
                  style={{ objectFit: "cover", width: "100%", height: 500 }}
                  alt="Image 1"
                />
              </div>
            </Grid>
          </Grid>
        )}

        {imageSrcs?.length === 1 && (
          <Grid mt={1} container spacing={0.5}>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <div style={imageContainerStyle}>
                <img
                  loading="lazy"
                  src={imageSrcs[0]}
                  style={{ objectFit: "cover", width: "100%", height: 500 }}
                  alt="Image 1"
                />
              </div>
            </Grid>
          </Grid>
        )}
      </Container>

      {/* Description Section */}
      <Container>
        {/* <Grid container spacing={2} style={{ marginTop: "2px" }}>
          <Grid item md={8}>
            <Stack>
              <Typography
                variant="h4"
                className="customFontSize"
                sx={{ fontSize: "20px", width: "100%" }}
              >
                <div dangerouslySetInnerHTML={{ __html: singleLineDetail }} />
              </Typography>
              <br />
              <br />
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack>
              <Stack sx={{ backgroundColor: "#D9D9D9", padding: "15px" }}>
                <Typography textAlign="end" color="#BD00FF" variant="h5">
                  Upto {roundedDiscount}% discount
                </Typography>
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "#BD00FF",
                  padding: "10px",
                  borderBottomRightRadius: "14px",
                  borderBottomLeftRadius: "14px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack sx={{ color: "white" }}>
                    <Typography textAlign="start" variant="h6">
                      <span style={{ fontWeight: "bolder" }}>
                        <del>{oldPrice?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</del> AED 
                        {newPrice
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        AED
                      </span>
                      <br />
                      per person
                    </Typography>
                  </Stack>
                  <Stack>
                    <div onClick={addDetail}>
                      <Button variant="contained" className="customBookNowBtn">
                        Book now
                      </Button>
                    </div>
                  </Stack>
                </Stack>
                <Stack mt={5}></Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid> */}

        <Grid container spacing={0} marginTop={8}>
          <Grid item md={8}>
            <Stack direction="column" spacing={0}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", fontSize: "20px" }}
              >
                About this activity
              </Typography>
              <div className="mt-3" dangerouslySetInnerHTML={{ __html: longDetail }} />
            </Stack>
          </Grid>
        </Grid>
      </Container>

      {/* Main Code */}
      {/* <Container>
        <div
          ref={scrollableDivRef}
          id="section-1"
          className={`${styles.slotBooker} p-4`}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5 style={{ fontWeight: "bolder" }}>
                Select participant and date
              </h5>
            </div>
          </div>
          <div className="row py-4 g-4">
            <div className="col-md-6 col-sm-12">
              <div
                className={`${styles.customDiv}`}
                onClick={() => setTravellerCount(!travellerCount)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ flex: 1 }}>Select number of travellers</span>
                  <span>
                    <AiOutlineCaretDown />
                  </span>
                </div>
              </div>
              {travellerCount && (
                <div
                  style={{
                    width: 300,
                    border: "1px solid black",
                    borderRadius: 6,
                    padding: 10,
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10",
                    }}
                  >
                    <span style={{ padding: 5, fontWeight: 600 }}>Adult</span>
                    <div>
                      <button
                        onClick={() => handleDecrement("adult")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        -
                      </button>
                      <span style={{ padding: 10, width: 50 }}>
                        {adultCount}
                      </span>
                      <button
                        onClick={() => handleIncrement("adult")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <Divider
                    sx={{
                      width: { lg: "100%", md: "100%", sm: "100%" },
                      borderTop: "1px solid #000",
                      margin: "0.5rem 0",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10",
                    }}
                  >
                    <span style={{ padding: 5, fontWeight: 600 }}>
                      Children
                    </span>
                    <div>
                      <button
                        onClick={() => handleDecrement("children")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        -
                      </button>
                      <span style={{ padding: 10 }}>{childrenCount}</span>
                      <button
                        onClick={() => handleIncrement("children")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <Divider
                    sx={{
                      width: { lg: "100%", md: "100%", sm: "100%" },
                      borderTop: "1px solid #000",
                      margin: "0.5rem 0",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10",
                    }}
                  >
                    <span style={{ padding: 5, fontWeight: 600 }}>Infant</span>
                    <div>
                      <button
                        onClick={() => handleDecrement("infant")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        -
                      </button>
                      <span style={{ padding: 10 }}>{infantCount}</span>
                      <button
                        onClick={() => handleIncrement("infant")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 col-sm-12">
              <div name="" id="" className={`${styles.customDropdowmMenu}`}>
                {true && (
                  <input
                    type="date"
                    className={`${styles.customDropdowmMenu}`}
                    value={selectedDate}
                    onChange={handleDateChange}
                    min={today}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <button
                className={`${styles.CheckAvailiblityButton}`}
                onClick={() => setShowAvailiblity(true)}
              >
                Check availability
              </button>
            </div>
          </div>
        </div>


        <div className="py-4">
          {showAvailiblity && (
            <div className={`${styles.CitySlot}`}>
              <div className="row p-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h5 style={{ fontWeight: "bolder" }}>{title}</h5>
                </div>
                <hr style={{ color: "black", marginTop: 24 }} />
                <div className="timeLocation">
                  <div className="row py-2">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <h5 style={{ fontWeight: "bolder" }}>
                        Select a starting time
                      </h5>
                    </div>
                    <div style={{ display: "flex", gap: 16 }}>
                      {time?.map((item) => {
                        return (
                          <div key={item?.id} className="">
                            <button
                              onClick={() =>
                                setSelectedTime({
                                  id: item.id,
                                  time: item.time,
                                })
                              }
                              style={{
                                backgroundColor:
                                  item?.id === selectedTime.id
                                    ? "#be23ff"
                                    : "#c1c4cf",
                              }}
                              className={`${styles.startingTimeButtonActive} my-2`}
                            >
                              {item?.time}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`${styles.CardFooter} p-4`}>
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="col">
                        <h5>Total Price</h5>
                      </div>
                      <div style={{ display: "flex", gap: 24 }}>
                        <h5>
                          {newPrice *
                            (infantCount + childrenCount + adultCount)}{" "}
                          AED
                        </h5>
                      </div>
                      <div className="col">
                        <h5>All taxes and fees included</h5>
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddCart(packageData)}
                      className="col-lg-4 d-flex align-items-center justify-content-center"
                    >
                      <button>Add to Cart</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div> 
      </Container> */}
      {/* Main Code */}

      <Container>
        <div
          ref={scrollableDivRef}
          id="section-1"
          className={`${styles.slotBooker} p-4`}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5 style={{ fontWeight: "bolder" }}>
                Select participant and date
              </h5>
            </div>
          </div>
          <div className="row py-4 g-4">
            <div className="col-md-6 col-sm-12">
              <div
                className={`${styles.customDiv}`}
                onClick={() => setTravellerCount(!travellerCount)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ flex: 1 }}>Select number of travellers</span>
                  <span>
                    <AiOutlineCaretDown />
                  </span>
                </div>
              </div>
              {travellerCount && (
                <div
                  style={{
                    width: 300,
                    border: "1px solid black",
                    borderRadius: 6,
                    padding: 10,
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10",
                    }}
                  >
                    <span style={{ padding: 5, fontWeight: 600 }}>Adult</span>
                    <div>
                      <button
                        onClick={() => handleDecrement("adult")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        -
                      </button>
                      <span style={{ padding: 10, width: 50 }}>
                        {adultCount}
                      </span>
                      <button
                        onClick={() => handleIncrement("adult")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <Divider
                    sx={{
                      width: { lg: "100%", md: "100%", sm: "100%" },
                      borderTop: "1px solid #000",
                      margin: "0.5rem 0",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10",
                    }}
                  >
                    <span style={{ padding: 5, fontWeight: 600 }}>
                      Children
                    </span>
                    <div>
                      <button
                        onClick={() => handleDecrement("children")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        -
                      </button>
                      <span style={{ padding: 10 }}>{childrenCount}</span>
                      <button
                        onClick={() => handleIncrement("children")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <Divider
                    sx={{
                      width: { lg: "100%", md: "100%", sm: "100%" },
                      borderTop: "1px solid #000",
                      margin: "0.5rem 0",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10",
                    }}
                  >
                    <span style={{ padding: 5, fontWeight: 600 }}>Infant</span>
                    <div>
                      <button
                        onClick={() => handleDecrement("infant")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        -
                      </button>
                      <span style={{ padding: 10 }}>{infantCount}</span>
                      <button
                        onClick={() => handleIncrement("infant")}
                        style={{
                          width: 30,
                          borderRadius: 50,
                          backgroundColor: "#dbb065",
                          color: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 col-sm-12">
              <div name="" id="" className={`${styles.customDropdowmMenu}`}>
                {true && (
                  <input
                    type="date"
                    className={`${styles.customDropdowmMenu}`}
                    value={selectedDate}
                    onChange={handleDateChange}
                    min={today}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <button
                className={`${styles.CheckAvailiblityButton}`}
                onClick={() => setShowAvailiblity(true)}
              >
                Check availability
              </button>
            </div>
          </div>
        </div>


        <div className="py-4">
          {showAvailiblity && (
            <div className={`${styles.CitySlot}`}>
              {/* <div className="row p-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h5 style={{ fontWeight: "bolder" }}>{title}</h5>
                </div>
                <hr style={{ color: "black", marginTop: 24 }} />
                <div className="timeLocation">
                  <div className="row py-2">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <h5 style={{ fontWeight: "bolder" }}>
                        Select a starting time
                      </h5>
                    </div>
                    <div style={{ display: "flex", gap: 16 }}>
                      {time?.map((item) => {
                        return (
                          <div key={item?.id} className="">
                            <button
                              onClick={() =>
                                setSelectedTime({
                                  id: item.id,
                                  time: item.time,
                                })
                              }
                              style={{
                                backgroundColor:
                                  item?.id === selectedTime.id
                                    ? "#be23ff"
                                    : "#c1c4cf",
                              }}
                              className={`${styles.startingTimeButtonActive} my-2`}
                            >
                              {item?.time}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div> */}
              <div>
                <div className={`${styles.CardFooter} p-4`}>
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="col">
                        <h5>Total Price</h5>
                      </div>
                      <div style={{ display: "flex", gap: 24 }}>
                        <h5>
                          {newPrice *
                            (infantCount + childrenCount + adultCount)}{" "}
                          AED
                        </h5>
                      </div>
                      <div className="col">
                        <h5>All taxes and fees included</h5>
                      </div>
                    </div>
                    <div>
                      {!showForm && (
                        <div className="col-lg-12 d-flex align-items-center justify-content-end">
                          <button onClick={() => setShowForm(true)}>Book Now</button>
                        </div>
                      )}
                      {showForm && (
                        <>
                          <Form form={form} layout="vertical" onFinish={handleSubmit} className="mt-4">
                            <h1>Contact Form</h1>
                            <div className="row">
                              <div className="col-md-6">
                                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name' }]}>
                                  <Input style={{ height: '40px' }} placeholder="Name" value={formData.name} onChange={(e) => handleChange('name', e.target.value)} />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
                                  <Input style={{ height: '40px' }} type="email" placeholder="Email" value={formData.email} onChange={(e) => handleChange('email', e.target.value)} />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                                  <Input style={{ height: '40px' }} type="tel" placeholder="Phone" value={formData.phone} onChange={(e) => handleChange('phone', e.target.value)} />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item label="Booking Details" name="bookingdetails" rules={[{ required: true, message: 'Please enter booking details' }]}>
                                  <Input style={{ height: '40px' }} placeholder="Booking Details" value={formData.bookingdetails} onChange={(e) => handleChange('bookingdetails', e.target.value)} />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <Form.Item label="Adults" name="adults" rules={[{ required: true, message: 'Please enter number of adults' }]}>
                                  <Input style={{ height: '40px' }} type="number" placeholder="Adults" value={formData.adults} onChange={(e) => handleChange('adults', e.target.value)} />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item label="Children" name="children" rules={[{ required: true, message: 'Please enter number of children' }]}>
                                  <Input style={{ height: '40px' }} type="number" placeholder="Children" value={formData.children} onChange={(e) => handleChange('children', e.target.value)} />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <Form.Item label="Message" name="message" rules={[{ required: true, message: 'Please enter a message' }]}>
                                  <Input.TextArea rows={3} placeholder="Message" value={formData.message} onChange={(e) => handleChange('message', e.target.value)} />
                                </Form.Item>
                              </div>
                            </div>
                            <Spin spinning={loading}>
                              <div className="row">
                                <div className="col-md-12 d-flex align-items-center justify-content-end">
                                  <Form.Item>
                                    <button type="primary" htmlType="submit">Submit</button>
                                  </Form.Item>
                                </div>
                              </div>
                            </Spin>
                          </Form>

                          <div className="socialbtns col-md-12 mt-2">
                            <button className="My-Btn-Style-Whatsapp" onClick={() => window.open('https://wa.me/+971505042027?text=Hi, can i get more information?', '_blank')} rel='noopener noreferrer'>
                              <span><FaWhatsapp className="icon-style-size me-2" /></span>
                              WhatsApp
                            </button>
                            <button className="My-Btn-Style-Call" onClick={() => window.open('tel:+971505042027')}>
                              <span><CiPhone className="icon-style-size me-2" /></span>
                              Call Us
                            </button>
                            <button className="My-Btn-Style-Call" onClick={() => window.open('mailto:mohamed.Irzan@agiletourism.com')}>
                              <span><MdOutlineEmail className="icon-style-size me-2" /></span>
                              Email Us
                            </button>
                          </div>
                        </>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
      <Container>
        <Grid marginY={6} container item>
          <Grid sm={12} md={8} lg={12}>
            <Grid container marginY={3}>
              <Grid sm={12} md={2} lg={4}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Highlights
                </Typography>
              </Grid>
              <Grid sm={12} md={10} lg={4}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "16px",
                    lineHeight: "22px",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: highlights }} />
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{
                width: { lg: "70%", md: "100%", sm: "100%" },
                borderTop: "1px solid #000",
                margin: "1rem 0",
              }}
            />
          </Grid>
          <Grid sm={12} md={8} lg={12}>
            <Grid container marginY={3}>
              <Grid sm={12} md={2} lg={4}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Full description
                </Typography>
              </Grid>
              <Grid sm={12} md={10} lg={10}>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <div style={{ marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: full_decription }} />
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{
                width: { lg: "70%", md: "100%", sm: "100%" },
                borderTop: "1px solid #000",
                margin: "1rem 0",
              }}
            />
          </Grid>
          <Grid sm={12} md={8} lg={12}>
            <Grid container>
              <Grid sm={12} md={2} lg={4}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Includes
                </Typography>
              </Grid>
              <Grid sm={12} md={10} lg={4}>
                <Typography
                  variant="body1"
                  styles={{ fontSize: "16px", lineHeight: "14px" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: includes }} />
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{
                width: { lg: "70%", md: "100%", sm: "100%" },
                borderTop: "1px solid #000",
                margin: "1rem 0",
              }}
            />
          </Grid>
          <Grid container>
            <Grid item sm={12} md={8} lg={6}>
              <Grid container marginY={3}>
                <Grid item sm={12} md={2} lg={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    Not suitable for
                  </Typography>
                </Grid>
                <Grid item sm={12} md={10} lg={8}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "16px", textAlign: "justify" }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: not_suitable }} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12} md={8} lg={6}>
              <Grid container marginY={3}>
                <Grid item sm={12} md={2} lg={4}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    Meeting point
                  </Typography>
                </Grid>
                <Grid item sm={12} md={10} lg={8}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "16px", textAlign: "justify" }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: meeting_point }} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid sm={12} md={8} lg={12}>
            <Grid container marginY={3}>
              <Grid sm={12} md={2} lg={4}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Important information
                </Typography>
              </Grid>
              <Grid sm={12} md={10} lg={4}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "16px", textAlign: "justify" }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: important_information }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{
                width: { lg: "70%", md: "100%", sm: "100%" },
                borderTop: "1px solid #000",
                margin: "1rem 0",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      {/* Customer Reviews */}

      <Container>
        <div className="row border p-4">
          <div className="col-md-12 pb-2">
            <h3 style={{ fontWeight: "bolder" }}>Write a Review.</h3>
          </div>
          <div className="col-md-12">
            <textarea
              name=""
              id=""
              cols="140"
              rows="8"
              style={{
                width: "100%",
                border: "1px solid gray",
                padding: "10px",
                borderRadius: "2px",
              }}
              placeholder="Your review*"
              onChange={(e) => setReviewText(e.target.value)}
              value={reviewText}
            ></textarea>
          </div>
          <div className="row d-flex align-items-center my-1">
            <div className="col-md-6 mb-2 mt-2">
              <h5>Over all rating</h5>
            </div>
            <div className="col-md-6 mb-2 mt-2">
              <span>
                <Rate
                  count={5}
                  tooltips={desc}
                  onChange={setValue}
                  value={value}
                />
                {value ? (
                  <span className="ant-rate-text">{desc[value - 2]}</span>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <hr />
          <div className="row d-flex align-items-center">
            <div className="col-md-6 mb-2 mt-2">
              <h5>Guidance</h5>
            </div>
            <div className="col-md-6 mb-2 mt-2">
              <span>
                <Rate
                  count={5}
                  tooltips={desc}
                  onChange={setGuidance}
                  value={Guidance}
                />
                {value ? (
                  <span className="ant-rate-text">{desc[value - 2]}</span>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-md-6 mb-2 mt-2">
              <h5>Transportation</h5>
            </div>
            <div className="col-md-6 mb-2 mt-2">
              <span>
                <Rate
                  count={5}
                  tooltips={desc}
                  onChange={setTransportation}
                  value={Transportation}
                />
                {value ? (
                  <span className="ant-rate-text">{desc[value - 2]}</span>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-md-6 mb-2 mt-2">
              <h5>Value for Money</h5>
            </div>
            <div className="col-md-6 mb-2 mt-2">
              <span>
                <Rate
                  count={5}
                  tooltips={desc}
                  onChange={setValueMoney}
                  value={ValueMoney}
                />
                {value ? (
                  <span className="ant-rate-text">{desc[value - 2]}</span>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-md-6 mb-2 mt-2">
              <h5>Services</h5>
            </div>
            <div className="col-md-6 mb-2 mt-2">
              <span>
                <Rate
                  count={5}
                  tooltips={desc}
                  onChange={setServices}
                  value={Services}
                />
                {value ? (
                  <span className="ant-rate-text">{desc[value - 2]}</span>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <hr />
          <div className="col-md-12 mt-2">
            <button
              onClick={handlePostRequest}
              style={{
                padding: "10px",
                width: "max-content",
                background: "#DBB065",
                border: "none",
                borderRadius: "4px",
              }}
            >
              Submit Review
            </button>
          </div>
        </div>
      </Container>
      <Container>
        <Review packageData={packageData} />
      </Container>
    </>
  );
};

export default SingleCard;
