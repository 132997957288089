import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "../../Pages/Home/Home";
import SingleCard from "../../Pages/SingleCardDetailPage/SingleCard";
import ShopingCart from "../../Pages/ShoppingCart/ShopingCart";
import Review from "../../components/CustomerReviews/Review";
import Footer from "../../components/Footer/Footer";
import Order from "../../components/Order/order";
import NavbarNew from "../../components/Navbar/NavbarNew";
import CheckOutProcess from "../../components/CheckOutProcess/CheckOutProcess";
import { Typography, Stack } from '@mui/material'
import Help from "../../Pages/StaticPages/CityTour/CityTour";
import CityTourTwo from "../../Pages/StaticPages/CityTourTwo/CityTourTwo";
import CityTourThree from "../../Pages/StaticPages/CityTourThree/CityTourThree";
import FlightTicket from "../../Pages/FlightTab/Flight_ticket";
import HotelInfo from "../../Pages/HotelTab/HotelDetail/HotelDetail"
import HotelDetails from "../../Pages/HotelTab/HotelDetail/HotelDetail";
import HotelFilter from "../../Pages/HotelTab/HotelFilter";
import HotelReservation from "../../Pages/HotelTab/HotelResevation/HotelReservation";
import Registration from "../../Pages/auth/auth";
import DynamicPages from "../../Pages/DynamicPages";
import Cart from '../../Pages/Cart'
import CityTour from "../../Pages/citytour";
import Adventure from "../../Pages/adventure";
import Attraction from "../../Pages/attraction";
import VipTrannsportation from "../../Pages/viptransportation";
import Transportation from "../../Pages/Transportation";
import HotelBooking from "../../Pages/HotelTab/hotelbooking";
import Holiday from "../../Pages/HolidayPackages/Holiday";


const NavbarConditional = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";

  return isHome ? null : <NavbarNew navFromHome={false} />;
};

const RouterApp = () => {
  return (
    <>
      <Router>
        <NavbarConditional />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:category/:id" element={<SingleCard />} />
          <Route path="/wishlist" element={<ShopingCart />} />
          <Route path="/3" element={<Review />} />
          <Route path="/4" element={<Order />} />
          <Route path="/checkout" element={<CheckOutProcess />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/help" element={<Help />} />
          <Route path="/city_tour" element={<CityTour />} />
          <Route path="/adventure" element={<Adventure />} />
          <Route path="/attraction" element={<Attraction />} />
          <Route path="/transportation" element={<Transportation />} />
          <Route path="/luxuryexperiences" element={<VipTrannsportation />} />
          <Route path="/ticket" element={<FlightTicket />} />
          <Route path="/hotelavailiblity" element={<HotelInfo />} />
          <Route path="/hoteldetails" element={<HotelFilter />} />
          <Route path="/hotelreservation" element={<HotelReservation />} />
          <Route path="/auth" element={<Registration />} />
          <Route path="/dynamic/:id" element={<DynamicPages />} />
          <Route path='/hotelbooking' element={<HotelBooking />} />
          <Route path='/holiday' element={<Holiday />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default RouterApp;
