import React, { useEffect, useState } from "react";
import { Container, Grid, Typography, Stack, Button } from "@mui/material";
import bgMain from "../../assets/bgSlider.jpg";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlinePeopleAlt } from "react-icons/md";
import {
  AiOutlineCalendar,
  AiOutlineCheckCircle,
  AiOutlineDelete,
  AiOutlineStar,
} from "react-icons/ai";
import axios from "axios";
import { axiosInstance } from '../../config/http/http';
import { Skeleton, notification } from "antd";
import dayjs from "dayjs";


const ShopingCart = () => {
  const [data, setData] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('Token');


  const GetData = async () => {
    // const id = localStorage.getItem('Token')?.split('|')?.[0];
    const id = localStorage.getItem('user');
    try {
      if (id) {
        const response = await axios.get(`https://developer-ma.com/ag_website/api/wishlist/${id}`)
        setData(response?.data)
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
      setData("")
      setLoading(false);
    }
  }

  useEffect(() => {
    GetData()
  }, [])


  const deleteWishlistItem = async (item) => {
    try {
      const response = await axios.get(`https://developer-ma.com/ag_website/api/wishlistDelete/${item}`);
      GetData()
    } catch (error) {
      console.error('Error deleting:', error);
    }
  };

  if (!token) {
    return navigate('/');
  }

  return (
    token &&
    <Container>
      {!loading ? Array.isArray(data) ? data?.map(value => {
        const item = value?.city; 
        return (item &&
          <Grid
            key={item?.id}
            marginY={3}
            container
            justifyContent="space-between"
            alignItems="start"

          >
            <Grid xs={12} sm={12} md={8} lg={8}>

              <Stack direction="row" marginY={3} justifyContent="space-between">
                <Stack direction="row" spacing={2}>
                  <img loading='lazy' src={JSON.parse(item?.images)?.[0]?.replace(/\\/g, "")} width="200px" height="200px" alt="" />
                  <Stack width={400}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {item?.title}
                    </Typography>
                    <Typography variant="p">
                    </Typography>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">4.1</Typography>
                      <Stack direction="row" marginLeft={1}>
                        <AiOutlineStar />
                        <AiOutlineStar />
                        <AiOutlineStar />
                        <AiOutlineStar />
                        <AiOutlineStar />
                      </Stack>
                    </Stack>
                    <Stack direction="column">
                      <Stack direction="row" alignItems="center">
                        <AiOutlineCalendar />
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "16px" }}
                          marginLeft={1}
                        >
                          {item?.fromDate ? dayjs(item?.fromDate).format('ddd, MMM DD YYYY') : ''}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <AiOutlineCalendar />
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "16px" }}
                          marginLeft={1}
                        >
                          Total Days {item?.totalDays}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <AiOutlineCheckCircle />
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "16px" }}
                          marginLeft={1}
                        >
                          Free cancellation
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "16px" }}
                        color="blue"
                      >
                        <Link to="/citytour" state={{ data: { ...item, time: item?.packagetime } }} >
                          Change date or participant(s)
                        </Link>
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "13px", padding: "3px", cursor: "pointer", borderRadius: "6px", marginTop: "20px", border: "1px solid red", width: "max-content", justifyContent: "center", alignItems: "center" }}
                        color="red"
                        onClick={() => deleteWishlistItem(value.item)}
                      >
                        Remove this item from wishlist
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={3}>
                  {/* <Stack>
                    <Typography variant="h5">
                      {item?.newPrice} AED
                    </Typography>
                  </Stack> */}
                </Stack>
              </Stack>
            </Grid>

            {/* 00000000000000000000 */}

            {/* <Grid paddingX={3} marginTop={2} xs={12} sm={12} md={4} lg={4}>
              <Stack sx={{ border: "1px solid black", borderRadius: "10px" }}>
                <Stack padding={3}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack>
                      <Typography variant="body2">Total (1 item):</Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="h6">{item?.newPrice} AED</Typography>
                      <Typography variant="caption" sx={{ fontSize: "10px" }}>
                        All taxes and fees included
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack marginTop={2}>
                    <Link to={`/citytour/${item?.title?.replaceAll(' ', '-')}`} state={{ data: { ...item, time: item?.packagetime } }} >
                      <Button
                        variant="contained"
                        className="w-100"
                        sx={{
                          backgroundColor: "#BD00FF",
                          padding: "8px 16px",
                          borderRadius: "25px",
                          "&:hover": {
                            backgroundColor: "#BD00FF",
                          },
                        }}
                      >
                        Buy Now
                      </Button>
                    </Link>

                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="start"
                    spacing={2}
                    marginTop={2}
                  >
                    <Stack>
                      <AiOutlineCheckCircle />
                    </Stack>
                    <Stack direction="column">
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold" }}
                        color="initial"
                      >
                        Pay nothing today
                      </Typography>
                      <Typography variant="caption" color="initial">
                        Book now and pay on {item?.fromDate ? dayjs(item?.fromDate).format('MMM DD') : ''}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="start"
                    spacing={2}
                    marginTop={2}
                  >
                    <Stack>
                      <AiOutlineCheckCircle />
                    </Stack>
                    <Stack direction="column">
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold" }}
                        color="initial"
                      >
                        Pay nothing today
                      </Typography>
                      <Typography variant="caption" color="initial">
                        Book now and pay on {item?.fromDate ? dayjs(item?.fromDate).format('MMM DD') : ''}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Grid> */}

            <Grid paddingX={3} marginTop={2} xs={12} sm={12} md={4} lg={4}>
              <Stack sx={{ border: "1px solid black", borderRadius: "10px" }}>
                <Stack padding={3}>
                  <Stack direction="row" justifyContent="space-between" alignItems='flex-start'>
                    <Stack>
                      <Typography variant="body2">Total (1 item):</Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="h6">{item?.newPrice} AED</Typography>
                      <Typography variant="caption" sx={{ fontSize: "10px" }}>
                        All taxes and fees included
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack marginTop={2}>
                    <Link to={`/citytour/${item?.title?.replaceAll(' ', '-')}`} state={{ data: { ...item, time: item?.packagetime } }} >
                      <Button
                        variant="contained"
                        className="w-100"
                        sx={{
                          backgroundColor: "#BD00FF",
                          padding: "8px 16px",
                          borderRadius: "25px",
                          "&:hover": {
                            backgroundColor: "#BD00FF",
                          },
                        }}
                      >
                        Buy Now
                      </Button>
                    </Link>

                  </Stack>
                </Stack>
              </Stack>

              <Stack
                sx={{
                  border: "1px solid black",
                  marginTop: "15px",
                  borderRadius: "10px",
                  padding: "20px"
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="start"
                  spacing={2}
                  marginTop={2}
                >
                  <Stack>
                    <AiOutlineCheckCircle />
                  </Stack>
                  <Stack direction="column">
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold" }}
                      color="initial"
                    >
                      Pay nothing today
                    </Typography>
                    <Typography variant="caption" color="initial">
                      Book now and pay on {item?.fromDate ? dayjs(item?.fromDate).format('MMM DD') : ''}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="start"
                  spacing={2}
                  marginTop={2}
                >
                  <Stack>
                    <AiOutlineCheckCircle />
                  </Stack>
                  <Stack direction="column">
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold" }}
                      color="initial"
                    >
                      Pay nothing today
                    </Typography>
                    <Typography variant="caption" color="initial">
                      Book now and pay on {item?.fromDate ? dayjs(item?.fromDate).format('MMM DD') : ''}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>

          </Grid>
        )
      }) : <div>
        <h1 style={{ marginTop: 24, textAlign: "center" }} className="my-5 py-5">No wishlist found   </h1>
      </div> : <Skeleton paragraph={{ rows: 8 }} />}
    </Container>
  );
};

export default ShopingCart;
