import React, { useState, useEffect, useRef } from 'react';
import styles from './Flight_ticket.module.css'
import Flight from '../../assets/plane.png';
import { Button, Collapse, Empty, notification } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import { Modal } from 'antd';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PhoneInput } from 'react-international-phone';


const { Panel } = Collapse;

function Flight_ticket() {
    const navigate = useNavigate();
    const modalRef = useRef(null);
    const stripe = useStripe();
    const elements = useElements();
    const [bookData, setBookData] = useState('')

    const Country_code = [
        { "name": "Afghanistan", "code": "AF" },
        { "name": "land Islands", "code": "AX" },
        { "name": "Albania", "code": "AL" },
        { "name": "Algeria", "code": "DZ" },
        { "name": "American Samoa", "code": "AS" },
        { "name": "AndorrA", "code": "AD" },
        { "name": "Angola", "code": "AO" },
        { "name": "Anguilla", "code": "AI" },
        { "name": "Antarctica", "code": "AQ" },
        { "name": "Antigua and Barbuda", "code": "AG" },
        { "name": "Argentina", "code": "AR" },
        { "name": "Armenia", "code": "AM" },
        { "name": "Aruba", "code": "AW" },
        { "name": "Australia", "code": "AU" },
        { "name": "Austria", "code": "AT" },
        { "name": "Azerbaijan", "code": "AZ" },
        { "name": "Bahamas", "code": "BS" },
        { "name": "Bahrain", "code": "BH" },
        { "name": "Bangladesh", "code": "BD" },
        { "name": "Barbados", "code": "BB" },
        { "name": "Belarus", "code": "BY" },
        { "name": "Belgium", "code": "BE" },
        { "name": "Belize", "code": "BZ" },
        { "name": "Benin", "code": "BJ" },
        { "name": "Bermuda", "code": "BM" },
        { "name": "Bhutan", "code": "BT" },
        { "name": "Bolivia", "code": "BO" },
        { "name": "Bosnia and Herzegovina", "code": "BA" },
        { "name": "Botswana", "code": "BW" },
        { "name": "Bouvet Island", "code": "BV" },
        { "name": "Brazil", "code": "BR" },
        { "name": "British Indian Ocean Territory", "code": "IO" },
        { "name": "Brunei Darussalam", "code": "BN" },
        { "name": "Bulgaria", "code": "BG" },
        { "name": "Burkina Faso", "code": "BF" },
        { "name": "Burundi", "code": "BI" },
        { "name": "Cambodia", "code": "KH" },
        { "name": "Cameroon", "code": "CM" },
        { "name": "Canada", "code": "CA" },
        { "name": "Cape Verde", "code": "CV" },
        { "name": "Cayman Islands", "code": "KY" },
        { "name": "Central African Republic", "code": "CF" },
        { "name": "Chad", "code": "TD" },
        { "name": "Chile", "code": "CL" },
        { "name": "China", "code": "CN" },
        { "name": "Christmas Island", "code": "CX" },
        { "name": "Cocos (Keeling) Islands", "code": "CC" },
        { "name": "Colombia", "code": "CO" },
        { "name": "Comoros", "code": "KM" },
        { "name": "Congo", "code": "CG" },
        { "name": "Congo, The Democratic Republic of the", "code": "CD" },
        { "name": "Cook Islands", "code": "CK" },
        { "name": "Costa Rica", "code": "CR" },
        { "name": "Cote D Ivoire", "code": "CI" },
        { "name": "Croatia", "code": "HR" },
        { "name": "Cuba", "code": "CU" },
        { "name": "Cyprus", "code": "CY" },
        { "name": "Czech Republic", "code": "CZ" },
        { "name": "Denmark", "code": "DK" },
        { "name": "Djibouti", "code": "DJ" },
        { "name": "Dominica", "code": "DM" },
        { "name": "Dominican Republic", "code": "DO" },
        { "name": "Ecuador", "code": "EC" },
        { "name": "Egypt", "code": "EG" },
        { "name": "El Salvador", "code": "SV" },
        { "name": "Equatorial Guinea", "code": "GQ" },
        { "name": "Eritrea", "code": "ER" },
        { "name": "Estonia", "code": "EE" },
        { "name": "Ethiopia", "code": "ET" },
        { "name": "Falkland Islands (Malvinas)", "code": "FK" },
        { "name": "Faroe Islands", "code": "FO" },
        { "name": "Fiji", "code": "FJ" },
        { "name": "Finland", "code": "FI" },
        { "name": "France", "code": "FR" },
        { "name": "French Guiana", "code": "GF" },
        { "name": "French Polynesia", "code": "PF" },
        { "name": "French Southern Territories", "code": "TF" },
        { "name": "Gabon", "code": "GA" },
        { "name": "Gambia", "code": "GM" },
        { "name": "Georgia", "code": "GE" },
        { "name": "Germany", "code": "DE" },
        { "name": "Ghana", "code": "GH" },
        { "name": "Gibraltar", "code": "GI" },
        { "name": "Greece", "code": "GR" },
        { "name": "Greenland", "code": "GL" },
        { "name": "Grenada", "code": "GD" },
        { "name": "Guadeloupe", "code": "GP" },
        { "name": "Guam", "code": "GU" },
        { "name": "Guatemala", "code": "GT" },
        { "name": "Guernsey", "code": "GG" },
        { "name": "Guinea", "code": "GN" },
        { "name": "Guinea-Bissau", "code": "GW" },
        { "name": "Guyana", "code": "GY" },
        { "name": "Haiti", "code": "HT" },
        { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
        { "name": "Holy See (Vatican City State)", "code": "VA" },
        { "name": "Honduras", "code": "HN" },
        { "name": "Hong Kong", "code": "HK" },
        { "name": "Hungary", "code": "HU" },
        { "name": "Iceland", "code": "IS" },
        { "name": "India", "code": "IN" },
        { "name": "Indonesia", "code": "ID" },
        { "name": "Iran, Islamic Republic Of", "code": "IR" },
        { "name": "Iraq", "code": "IQ" },
        { "name": "Ireland", "code": "IE" },
        { "name": "Isle of Man", "code": "IM" },
        { "name": "Israel", "code": "IL" },
        { "name": "Italy", "code": "IT" },
        { "name": "Jamaica", "code": "JM" },
        { "name": "Japan", "code": "JP" },
        { "name": "Jersey", "code": "JE" },
        { "name": "Jordan", "code": "JO" },
        { "name": "Kazakhstan", "code": "KZ" },
        { "name": "Kenya", "code": "KE" },
        { "name": "Kiribati", "code": "KI" },
        { "name": "Korea, Democratic PeopleS Republic of", "code": "KP" },
        { "name": "Korea, Republic of", "code": "KR" },
        { "name": "Kuwait", "code": "KW" },
        { "name": "Kyrgyzstan", "code": "KG" },
        { "name": "Lao PeopleS Democratic Republic", "code": "LA" },
        { "name": "Latvia", "code": "LV" },
        { "name": "Lebanon", "code": "LB" },
        { "name": "Lesotho", "code": "LS" },
        { "name": "Liberia", "code": "LR" },
        { "name": "Libyan Arab Jamahiriya", "code": "LY" },
        { "name": "Liechtenstein", "code": "LI" },
        { "name": "Lithuania", "code": "LT" },
        { "name": "Luxembourg", "code": "LU" },
        { "name": "Macao", "code": "MO" },
        { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
        { "name": "Madagascar", "code": "MG" },
        { "name": "Malawi", "code": "MW" },
        { "name": "Malaysia", "code": "MY" },
        { "name": "Maldives", "code": "MV" },
        { "name": "Mali", "code": "ML" },
        { "name": "Malta", "code": "MT" },
        { "name": "Marshall Islands", "code": "MH" },
        { "name": "Martinique", "code": "MQ" },
        { "name": "Mauritania", "code": "MR" },
        { "name": "Mauritius", "code": "MU" },
        { "name": "Mayotte", "code": "YT" },
        { "name": "Mexico", "code": "MX" },
        { "name": "Micronesia, Federated States of", "code": "FM" },
        { "name": "Moldova, Republic of", "code": "MD" },
        { "name": "Monaco", "code": "MC" },
        { "name": "Mongolia", "code": "MN" },
        { "name": "Montenegro", "code": "ME" },
        { "name": "Montserrat", "code": "MS" },
        { "name": "Morocco", "code": "MA" },
        { "name": "Mozambique", "code": "MZ" },
        { "name": "Myanmar", "code": "MM" },
        { "name": "Namibia", "code": "NA" },
        { "name": "Nauru", "code": "NR" },
        { "name": "Nepal", "code": "NP" },
        { "name": "Netherlands", "code": "NL" },
        { "name": "Netherlands Antilles", "code": "AN" },
        { "name": "New Caledonia", "code": "NC" },
        { "name": "New Zealand", "code": "NZ" },
        { "name": "Nicaragua", "code": "NI" },
        { "name": "Niger", "code": "NE" },
        { "name": "Nigeria", "code": "NG" },
        { "name": "Niue", "code": "NU" },
        { "name": "Norfolk Island", "code": "NF" },
        { "name": "Northern Mariana Islands", "code": "MP" },
        { "name": "Norway", "code": "NO" },
        { "name": "Oman", "code": "OM" },
        { "name": "Pakistan", "code": "PK" },
        { "name": "Palau", "code": "PW" },
        { "name": "Palestinian Territory, Occupied", "code": "PS" },
        { "name": "Panama", "code": "PA" },
        { "name": "Papua New Guinea", "code": "PG" },
        { "name": "Paraguay", "code": "PY" },
        { "name": "Peru", "code": "PE" },
        { "name": "Philippines", "code": "PH" },
        { "name": "Pitcairn", "code": "PN" },
        { "name": "Poland", "code": "PL" },
        { "name": "Portugal", "code": "PT" },
        { "name": "Puerto Rico", "code": "PR" },
        { "name": "Qatar", "code": "QA" },
        { "name": "Reunion", "code": "RE" },
        { "name": "Romania", "code": "RO" },
        { "name": "Russian Federation", "code": "RU" },
        { "name": "RWANDA", "code": "RW" },
        { "name": "Saint Helena", "code": "SH" },
        { "name": "Saint Kitts and Nevis", "code": "KN" },
        { "name": "Saint Lucia", "code": "LC" },
        { "name": "Saint Pierre and Miquelon", "code": "PM" },
        { "name": "Saint Vincent and the Grenadines", "code": "VC" },
        { "name": "Samoa", "code": "WS" },
        { "name": "San Marino", "code": "SM" },
        { "name": "Sao Tome and Principe", "code": "ST" },
        { "name": "Saudi Arabia", "code": "SA" },
        { "name": "Senegal", "code": "SN" },
        { "name": "Serbia", "code": "RS" },
        { "name": "Seychelles", "code": "SC" },
        { "name": "Sierra Leone", "code": "SL" },
        { "name": "Singapore", "code": "SG" },
        { "name": "Slovakia", "code": "SK" },
        { "name": "Slovenia", "code": "SI" },
        { "name": "Solomon Islands", "code": "SB" },
        { "name": "Somalia", "code": "SO" },
        { "name": "South Africa", "code": "ZA" },
        { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
        { "name": "Spain", "code": "ES" },
        { "name": "Sri Lanka", "code": "LK" },
        { "name": "Sudan", "code": "SD" },
        { "name": "Suriname", "code": "SR" },
        { "name": "Svalbard and Jan Mayen", "code": "SJ" },
        { "name": "Swaziland", "code": "SZ" },
        { "name": "Sweden", "code": "SE" },
        { "name": "Switzerland", "code": "CH" },
        { "name": "Syrian Arab Republic", "code": "SY" },
        { "name": "Taiwan, Province of China", "code": "TW" },
        { "name": "Tajikistan", "code": "TJ" },
        { "name": "Tanzania, United Republic of", "code": "TZ" },
        { "name": "Thailand", "code": "TH" },
        { "name": "Timor-Leste", "code": "TL" },
        { "name": "Togo", "code": "TG" },
        { "name": "Tokelau", "code": "TK" },
        { "name": "Tonga", "code": "TO" },
        { "name": "Trinidad and Tobago", "code": "TT" },
        { "name": "Tunisia", "code": "TN" },
        { "name": "Turkey", "code": "TR" },
        { "name": "Turkmenistan", "code": "TM" },
        { "name": "Turks and Caicos Islands", "code": "TC" },
        { "name": "Tuvalu", "code": "TV" },
        { "name": "Uganda", "code": "UG" },
        { "name": "Ukraine", "code": "UA" },
        { "name": "United Arab Emirates", "code": "AE" },
        { "name": "United Kingdom", "code": "GB" },
        { "name": "United States", "code": "US" },
        { "name": "United States Minor Outlying Islands", "code": "UM" },
        { "name": "Uruguay", "code": "UY" },
        { "name": "Uzbekistan", "code": "UZ" },
        { "name": "Vanuatu", "code": "VU" },
        { "name": "Venezuela", "code": "VE" },
        { "name": "Viet Nam", "code": "VN" },
        { "name": "Virgin Islands, British", "code": "VG" },
        { "name": "Virgin Islands, U.S.", "code": "VI" },
        { "name": "Wallis and Futuna", "code": "WF" },
        { "name": "Western Sahara", "code": "EH" },
        { "name": "Yemen", "code": "YE" },
        { "name": "Zambia", "code": "ZM" },
        { "name": "Zimbabwe", "code": "ZW" }
    ]


    const cardElementStyle = {
        marginTop: 120,
        base: {
            fontSize: '16px',
            fontFamily: 'Arial, sans-serif',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: 'red',
        },
    }

    const download = () => {
        const modalContent = modalRef?.current;
        if (modalContent) {
            html2canvas(modalContent).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const doc = new jsPDF('landscape', 'mm', [600, 400]);
                const width = 190;
                const height = (width * canvas.height) / canvas.width;
                doc.addImage(imgData, 'PNG', 10, 10, 550, 380);
                doc.save('receipt.pdf');
            }).then(() => navigate('/'))
        }
    };

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const localData = JSON.parse(localStorage.getItem('data'));
    const token = localStorage.getItem("Token");

    useEffect(() => {
        if (!token) {
            alert("Hello")
            navigate("/")
        }
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])

    const {
        selected_flight_details,
        selected_date,
        adult_Count,
        children_Count,
        infant_Count,
        departure_Airport,
        arrival_Airport
    } = localData


    const [count, setCount] = useState(adult_Count + children_Count + infant_Count);
    const [loader, setLoader] = useState(false);

    const [formData, setFormData] = useState(Array.from({ length: count }, () => ({
        firstName: '',
        lastName: '',
        issuanceDate: '',
        holder: 'true',
        nationality: '',
        validityCountry: '',
        expiryDate: '',
        passportNumber: '',
        issuanceLocation: '',
        documentType: 'PASSPORT',
        gender: 'Male',
        phoneNumber: '',
        email: '',
    })));

    const [remarks, setRemarks] = useState("")
    const [TicketAgreement, setTicketAgreement] = useState("")


    const transformedObjects = formData.map((obj, index) => ({
        "id": (index + 1).toString(),
        "dateOfBirth": "1982-01-16",
        "name": {
            "firstName": obj.firstName.toUpperCase(),
            "lastName": obj.lastName.toUpperCase()
        },
        "gender": obj.gender.toUpperCase(),
        "contact": {
            "emailAddress": obj.email,
            "phones": [
                {
                    "deviceType": "MOBILE",
                    "countryCallingCode": "1",
                    "number": obj.phoneNumber.replace(/\D/g, "")
                }
            ]
        },
        "documents": [
            {
                "documentType": obj.documentType,
                "issuanceLocation": obj.issuanceLocation,
                "issuanceDate": obj.issuanceDate,
                "number": obj.passportNumber,
                "expiryDate": obj.expiryDate,
                "issuanceCountry": obj.validityCountry,
                "validityCountry": obj.validityCountry,
                "nationality": obj.nationality,
                "holder": obj.holder === "true"
            }
        ]
    }));

    const data = {
        "type": "flight-order",
        "flightOffers": [
            selected_flight_details
        ],
        "travelers":
            transformedObjects
        ,
        "remarks": {
            "general": [
                {
                    "subType": "GENERAL_MISCELLANEOUS",
                    "text": remarks
                }
            ]
        },
        "ticketingAgreement": {
            "option": "DELAY_TO_CANCEL",
            "delay": TicketAgreement,
            "contacts": [
                {
                    "addresseeName": {
                        "firstName": "PABLO",
                        "lastName": "RODRIGUEZ"
                    },
                    "companyName": "INCREIBLE VIAJES",
                    "purpose": "STANDARD",
                    "phones": [
                        {
                            "deviceType": "LANDLINE",
                            "countryCallingCode": "34",
                            "number": "480080071"
                        },
                        {
                            "deviceType": "MOBILE",
                            "countryCallingCode": "33",
                            "number": "480080072"
                        }
                    ],
                    "emailAddress": "support@increibleviajes.es",
                    "address": {
                        "lines": [
                            "Calle Prado, 16"
                        ],
                        "postalCode": "28014",
                        "cityName": "Madrid",
                        "countryCode": "ES"
                    }
                }
            ]
        }
    }


    const dataArray = {
        "type": "flight-order",
        "flightOffers": [
            selected_flight_details
        ],
        "travelers": transformedObjects,
        "remarks": {
            "general": [
                {
                    "subType": "GENERAL_MISCELLANEOUS",
                    "text": remarks
                }
            ]
        },
        "ticketingAgreement": {
            "option": "DELAY_TO_CANCEL",
            "delay": TicketAgreement,
            "contacts": [
                {
                    "addresseeName": {
                        "firstName": "PABLO",
                        "lastName": "RODRIGUEZ"
                    },
                    "companyName": "INCREIBLE VIAJES",
                    "purpose": "STANDARD",
                    "phones": [
                        {
                            "deviceType": "LANDLINE",
                            "countryCallingCode": "34",
                            "number": "480080071"
                        },
                        {
                            "deviceType": "MOBILE",
                            "countryCallingCode": "33",
                            "number": "480080072"
                        }
                    ],
                    "emailAddress": "support@increibleviajes.es",
                    "address": {
                        "lines": [
                            "Calle Prado, 16"
                        ],
                        "postalCode": "28014",
                        "cityName": "Madrid",
                        "countryCode": "ES"
                    }
                }
            ]
        }
    };

    const travelers = dataArray.travelers.map(traveler => ({
        travelerId: traveler.id,
        firstName: traveler.name.firstName,
        lastName: traveler.name.lastName,
        passportNumber: traveler.documents[0].number,
        phone: traveler.contact.phones,
        email: traveler.contact.emailAddress,
        dateOfBirth: traveler.dateOfBirth
    }));

    const flight = dataArray.flightOffers[0];
    const flightDetails = {
        travelorId: flight?.travelerPricings?.[0]?.travelerId,
        flightId: flight.id,
        originLocation: flight.itineraries[0].segments[0].departure.iataCode,
        originDestination: flight.itineraries[0].segments[flight.itineraries[0].segments.length - 1].arrival.iataCode,
        fare: flight.price.fareTotal,
        totalPrice: flight.price.total,
        cabinClass: flight.travelerPricings[0].fareDetailsBySegment[0].cabin,
        date: flight.itineraries[0].segments[0].departure.at.slice(0, 10),
        noOfTravelers: count,
        departureDate: flight.itineraries[0].segments[0].departure.at,
        departureTerminal: flight.itineraries[0].segments[0].departure.terminal,
        arrivalDate: flight.itineraries[0].segments[flight.itineraries[0].segments.length - 1].arrival.at,
        arrivalTerminal: flight.itineraries[0].segments?.[0]?.arrival?.terminal,
        carrierCode: flight.itineraries[0].segments[0].carrierCode,
        flightNumber: flight.itineraries[0].segments[0].number,
        adultId: adult_Count,
        childrenId: children_Count,
        infantsId: infant_Count,
        associatedAdultId: 1,
        Payment_status: 1,
        Booking_status: 1,
        txn_id: 1,
        customer_id: 2,
    };

    const ticketingAgreement = {
        option: dataArray.ticketingAgreement.option,
        delayToCancel: dataArray.ticketingAgreement.delay,
        addresseeFirstName: dataArray.ticketingAgreement.contacts[0].addresseeName.firstName,
        addresseeLastName: dataArray.ticketingAgreement.contacts[0].addresseeName.lastName,
        companyName: dataArray.ticketingAgreement.contacts[0].companyName,
        purpose: dataArray.ticketingAgreement.contacts[0].purpose,
        phones: dataArray.ticketingAgreement.contacts[0].phones.map(phone => ({
            deviceType: phone.deviceType,
            countryCallingCode: phone.countryCallingCode,
            number: phone.number
        })),
        emailAddress: dataArray.ticketingAgreement.contacts[0].emailAddress,
        addressLines: dataArray.ticketingAgreement.contacts[0].address.lines,
        postalCode: dataArray.ticketingAgreement.contacts[0].address.postalCode,
        cityName: dataArray.ticketingAgreement.contacts[0].address.cityName,
        countryCode: dataArray.ticketingAgreement.contacts[0].address.countryCode
    };

    const combinedObject = {
        travelers,
        flightDetails,
        ticketingAgreement,
    };

    const daysArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day1 = dayjs(selected_date).day();
    const date2 = dayjs(selected_date).format('MMMM, DD, YYYY');
    const formattedDate = `${daysArray[day1]}, ${date2}`;

    const formattedDepartureAirport = departure_Airport.charAt(0).toUpperCase() + departure_Airport.slice(1);
    const formattedArrivalAirport = arrival_Airport.charAt(0).toUpperCase() + arrival_Airport.slice(1);

    const handleFormChange = async (index, field, value) => {
        const updatedFormData = [...formData];
        updatedFormData[index][field] = value;
        setFormData(updatedFormData);
    };

    const ConfirmFlight = async (e) => {
        const passportRegex = /^(?!.*[IO])[A-Z0-9<]{9}$/;
        let expiryDate = true;
        const isEmpty = formData.some((field) => Object.values(field).some((value) => value === ''));
        let passportValid = true;
        formData.map(item => {
            if (!passportRegex.test(item?.passportNumber)) {
                passportValid = false;
            }
        });
        formData?.map(item => {
            if (dayjs(item?.expiryDate) < dayjs()) {
                expiryDate = false;
            }
        })
        if (isEmpty) {
            notification.error({
                message: 'Validation Error',
                description: 'Please fill in all required fields.',
            });
        }
        else if (!passportValid) {
            notification.error({
                message: 'Validation Error',
                description: 'Please fill correct Passport Number.',
            });
        }
        else if (!expiryDate) {
            notification.error({
                message: 'Validation Error',
                description: 'Please fill correct expiry Date of Passport.',
            });
        }
        else {
            setLoader(true);
            const { token, error } = await stripe.createToken(elements.getElement(CardElement))
            if (token) {
                const combinedObject2 = { ...combinedObject, token: token?.id }
                axios.post('https://developer-ma.com/ag_website/api/flightOfferCreate', {
                    data
                })
                    .then(function (response) {
                        if (response?.data) {
                            setBookData(response?.data)
                            console.log(response?.data)
                            notification.success({
                                message: "Your Flight has been booked Successfully!",
                            });
                            setLoader(false)
                        } else {
                            notification.error({
                                message: "An error occured!",
                            });
                        }
                        setOpen(true)
                    })
                    .catch(function (error) {
                        notification.error({
                            message: '"An error occured!"',
                        });
                        setLoader(false)
                    });
                axios.post('https://developer-ma.com/ag_website/api/FlightOfferCreateDetails',
                    combinedObject2
                )
                    .then(function (response) {
                        console.log(response)
                    })
                    .catch(function (error) {
                        console.log("eror", error);
                        setLoader(false)
                    });
            }
            setLoader(false);
        }
    }

    const handleRemarksChange = (e) => {
        setTicketAgreement(e.target.value);
    };

    return (
        <div className="container-fluid" style={{ backgroundColor: '#E0E0E0', height: "2500px", padding: "20px" }}>
            <Modal
                width={800}
                centered
                open={open}
                title=""
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={download}>
                        Download Receipt
                    </Button>
                ]}
            >
                <div ref={modalRef}>
                    <div className="container-fluid p-2">
                        <div style={{ background: "#DBAF66", width: '100%' }} className='p-3' >
                            <h3 className='text-center text-white'>&hearts; Thank You For Booking With Agile Tourism &hearts;</h3>
                        </div>

                        <div className='my-3' style={{ width: '100%', border: '2px solid gray', borderRadius: '10px' }}>
                            <div className="content text-center p-2 ">
                                <h3 style={{ color: '#cd61dd' }}>Your Flight reservation is complete</h3>
                                <div className='d-flex justify-content-center' style={{ marginTop: "24px" }}>
                                </div>
                                <div className='text-center'>
                                    <h5>Booking Reference</h5>
                                    <button style={{ width: 'fit-content', paddingInline: 14, heigh: '35px', border: "1px solid gray" }} >{bookData?.data?.id}</button>

                                    <h5 className='mt-3' >E-Ticket Number</h5>
                                    <button style={{ width: 'fit-content', heigh: '35px', border: "1px solid gray" }} >{bookData?.data?.associatedRecords?.[0]?.reference} </button>
                                </div>
                                <p className='mt-3' style={{ color: '#DBAF66' }}>Welcome</p>
                            </div>
                        </div>
                        <div className={`${styles.header} row  py-3`}>
                            <div className={`${styles.tag} col-md-2`}>
                                <span><img loading='lazy' src={Flight} alt="" />{" "}Departure</span>
                            </div>
                            <div className={styles.route}>
                                <div>
                                    <span>{formattedDepartureAirport} / </span>
                                    {bookData?.data?.flightOffers?.[0]?.itineraries?.[0]?.segments?.[0]?.departure?.at &&
                                        `${dayjs(bookData?.data?.flightOffers?.[0]?.itineraries?.[0]?.segments?.[0]?.departure?.at, 'YYYY-MM-DDTHH:mm:ss').format('dddd')}
                                        ${dayjs(bookData?.data?.flightOffers?.[0]?.itineraries?.[0]?.segments?.[0]?.departure?.at, 'YYYY-MM-DDTHH:mm:ss').format('MMMM, DD, YYYY HH:MM')}`
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.header} row  py-3`}>
                            <div className={`${styles.tag} col-md-2`}>
                                <span><img loading='lazy' src={Flight} alt="" />{" "}Arrival</span>
                            </div>
                            <div className={styles.route}>
                                <div>
                                    <span>{formattedArrivalAirport} /</span>
                                    {bookData?.data?.flightOffers?.[0]?.itineraries?.[0]?.segments?.[0]?.arrival?.at &&
                                        `${dayjs(bookData?.data?.flightOffers?.[0]?.itineraries?.[0]?.segments?.[0]?.arrival?.at, 'YYYY-MM-DDTHH:mm:ss').format('dddd')}
                                        ${dayjs(bookData?.data?.flightOffers?.[0]?.itineraries?.[0]?.segments?.[0]?.arrival?.at, 'YYYY-MM-DDTHH:mm:ss').format('MMMM, DD, YYYY HH:MM')}`
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className='container py-5'>

                {/* code for Header */}

                <div className={`${styles.header} row  py-3`}>
                    <div className={`${styles.tag} col-md-2`}>
                        <span><img loading='lazy' src={Flight} alt="" />{" "}Departure</span>
                    </div>
                    <div className={styles.route}>
                        <div>
                            <span>{formattedDepartureAirport} - {formattedArrivalAirport} /</span>  {formattedDate}
                        </div>
                    </div>
                </div>

                {/* code for price details */}

                <div className={`${styles.priceDetailsHeading} row py-3`}>
                    <div className="col-md-12">
                        <h3 className='py-2' style={{ color: '#000' }}>Price Details</h3>
                    </div>
                    <div className={`${styles.table}`}>
                        <div className="col-md-12">
                            <table border='0' className={`table`}>
                                <thead>
                                    <tr>
                                        <th className={`${styles.th} ${styles.thTotal}`}>Pessenger Type</th>
                                        <th className={`${styles.th} ${styles.thTotal}`}>Price</th>
                                        <th className={`${styles.th} ${styles.thTotal}`}>Tax</th>
                                        <th className={`${styles.th} ${styles.thTotal}`}>Service Fee</th>
                                        <th className={`${styles.th} ${styles.thTotal}`}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {localData?.selected_flight_details?.travelerPricings?.map(item => {

                                        return (
                                            <tr key={item?.id}>
                                                <td className={`${styles.td} ${styles.tdTotal}`}>{item?.travelerType}</td>
                                                <td className={`${styles.td} ${styles.customCell}`}>{item?.price?.total?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {item?.price?.currency}</td>
                                                <td className={`${styles.td} ${styles.customCell}`}>0 {selected_flight_details?.price?.currency}</td>
                                                <td className={`${styles.td} ${styles.customCell}`}>0 {selected_flight_details?.price?.currency}</td>
                                                <td className={`${styles.td} ${styles.tdTotal}`}>{item?.price?.total?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {item?.price?.currency}</td>
                                            </tr>
                                        )
                                    })

                                    }
                                </tbody>
                            </table>
                            <div className={styles.totalPrice}>
                                <h1>{selected_flight_details?.price?.grandTotal?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {selected_flight_details?.price?.currency}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-4'>
                    <div className="col-md-12">
                        <h3 className='py-2' style={{ color: '#000' }}>Pessengers Information</h3>
                    </div>

                    {formData.map((form, index) => (
                        <Collapse className='my-2'>
                            <Panel header={`Passenger # ${index + 1}`} key={index.toString()} style={{ backgroundColor: 'white' }}>
                                <form key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label style={{ width: '100%' }}>First Name:</label>
                                                <input
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                    type="text"
                                                    className=""
                                                    value={form.firstName}
                                                    onChange={(e) => handleFormChange(index, 'firstName', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Last Name:</label>
                                                <input
                                                    type="text"
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                    value={form.lastName}
                                                    onChange={(e) => handleFormChange(index, 'lastName', e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label>Email:</label>
                                                <input
                                                    type="email"
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                    value={form.email}
                                                    onChange={(e) => handleFormChange(index, 'email', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group booking">
                                                <label>Phone Number:</label>
                                                <PhoneInput
                                                    style={{ width: 515 }}
                                                    defaultCountry="ae"
                                                    value={form?.phone}
                                                    onChange={(e) => handleFormChange(index, 'phone', e)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label>Gender:</label>
                                                <select
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                    value={form.gender}
                                                    onChange={(e) => handleFormChange(index, 'gender', e.target.value)}
                                                >
                                                    <option value="Male">Male</option>
                                                    <option value="feMale">Female</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Issuance date</label>
                                                <input
                                                    type="date"
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                    value={form.issuanceDate}
                                                    onChange={(e) => handleFormChange(index, 'issuanceDate', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label>Issuance Location:</label>
                                                <input
                                                    type="text"
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                    value={form.issuanceLocation}
                                                    onChange={(e) => handleFormChange(index, 'issuanceLocation', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Passport Number:</label>
                                                <input
                                                    type="text"
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                    value={form.passportNumber}
                                                    onChange={(e) => handleFormChange(index, 'passportNumber', e.target.value)}
                                                />
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label>Expiry date</label>
                                                <input
                                                    type="date"
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                    value={form.expiryDate}
                                                    onChange={(e) => handleFormChange(index, 'expiryDate', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Validity Country :</label>

                                                <select
                                                    name="country"
                                                    id="country"
                                                    value={form.validityCountry}
                                                    onChange={(e) => handleFormChange(index, 'validityCountry', e.target.value)}
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                >
                                                    <option value="">Select your country</option>
                                                    {Country_code.map((item, i) => {
                                                        return (
                                                            <option key={i} value={item.code}>
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label>Nationality</label>
                                                <select
                                                    name="country"
                                                    id="country"
                                                    value={form.nationality}
                                                    onChange={(e) => handleFormChange(index, 'nationality', e.target.value)}
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                >
                                                    <option value="">Select your country</option>
                                                    {Country_code.map((item, i) => {
                                                        return (
                                                            <option key={i} value={item.code}>
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Holder</label>
                                                <select
                                                    style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                                    value={form.holder}
                                                    onChange={(e) => handleFormChange(index, 'holder', e.target.value)}
                                                >
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </form>

                            </Panel>
                        </Collapse>
                    ))}
                </div>
                {/* ------- */}

                {/* Passport Information */}


                <div className="my-4">
                    <div className="col-md-12">
                        <h3 className='py-2' style={{ color: 'black' }}>Remarks & Ticketing Agreement</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label>Remarks</label>
                            <input
                                type="text"
                                style={{ width: '100%', height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Delay to cancel</label>
                            <select
                                style={{ width: '100%', height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                value={TicketAgreement}
                                onChange={handleRemarksChange}
                            >
                                <option value="Select an option">Select an option</option>
                                <option value="1D">1 Day</option>
                                <option value="2D">2 Day</option>
                                <option value="3D">3 Day</option>
                                <option value="4D">4 Day</option>
                                <option value="5D">5 Day</option>
                                <option value="6D">6 Day</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* Payment code */}

                <h3 className='my-4' style={{ color: 'black' }}>Payment Information</h3>
                <div className={`${styles.PaymentInfo}`}>
                    <div className="row">
                        <div className="col-md-3">
                            <span className={styles.underLineCSS}>Credit card / Debit Card</span>
                        </div>
                        <div className="col-md-9" style={{ color: 'black', fontWeight: '700' }}>
                            Tranfer
                        </div>
                    </div>
                    <div style={{ width: '100%', maxWidth: '520px', marginTop: 24 }}>
                        <CardElement options={{ style: cardElementStyle }} />
                    </div>
                    <div className="row" style={{ marginTop: "30px", marginLeft: '-31px', fontWeight: 600, fontSize: "16px" }}>
                        <div className="col-md-12">
                            <div className={`form-check ${styles.radioOne}`}>
                                <input type="radio" className="form-check-input" id="radioSalman" name="radioName" />
                                <label className="form-check-label" htmlFor="radioSalman">
                                    I have read and accept the agency Rules , Ticket Rules and Primarily information form.
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className={`form-check ${styles.radioOne}`}>
                                <input type="radio" className={styles.radioButton} id="radioAli" name="radioName" />
                                <label className="form-check-label" htmlFor="radioAli">I have read the Peronal Data Protection and Privacy Policy.I agree.</label>
                            </div>
                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="col-md-12 d-flex justify-content-end align-items-center">
                            Total Payable Amount : {" "}<span style={{ fontSize: "32px", fontWeight: 500, marginLeft: 5 }}>{selected_flight_details?.price?.total?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {selected_flight_details?.price?.currency}</span>
                        </div>
                        <div style={{ marginTop: 24 }} className={`${styles.BuyNowButton} col-md-12 d-flex justify-content-end`}>
                            <button onClick={loader ? null : (e) => ConfirmFlight(e)}>
                                {loader ? (
                                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', fontSize: '15px' }}>
                                        Confirming Flight
                                        <CircularProgress color="inherit" size={18} />
                                    </div>
                                ) : (
                                    "Confirm Flights"
                                )}
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </div >
    )
}

export default Flight_ticket