
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container, Rating } from "@mui/material";
import Steeper from "../StepperForm/HorizontalLinearStepper";
import { Stack } from "@mui/material";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { useLocation } from 'react-router-dom'
import axios from "axios";

import {
  AiOutlineCalendar,
  AiOutlineCheckCircle,
  AiOutlineDelete,
  AiOutlineGift,
  AiOutlineStar,
} from "react-icons/ai";
import "./CheckOutProcess.css";
import { Modal, notification } from "antd";


const CheckOutProcess = () => {
  const [userDetails, setUserDetails] = useState({ name: '', email: '', country: '', phone: '' })
  const [payment, setPayment] = useState({ name: '', cardNumber: '', expiry: '', security: '', date: '' });
  const [starRating, setStarRating] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [promo, setPromo] = useState('');
  const [discount, setDiscount] = useState('');
  const [percentage, setPercentage] = useState('');



  const location = useLocation()
  const data = location.state;
  let rating = data?.all_data?.rating;

  const averageRating = starRating.length > 0
    ? starRating.reduce((sum, rating) => sum + rating, 0) / starRating.length
    : 0;


  let FinalRateCount = 0;
  useEffect(() => {
    if (rating?.length > 0) {
      let rateCount = 0;

      rating.forEach((i) => {
        rateCount += Number(i?.rating);
        let Avg = rateCount / rating.length;
        let AvgRound = Math.round(Avg);
        let FinalRateCount = AvgRound;
        setStarRating(FinalRateCount);
      });
    }
  }, [rating]);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePromoCode = async () => {
    try {
      const response = await axios.post(`https://developer-ma.com/ag_website/api/promocode/validate?coupon_code=${discount}`)
      setPromo(Number(handleTotalPrice()) - (Number(handleTotalPrice()) * (Number(response?.data?.promo_code_data?.discount?.replace('%', '')) / 100)))
      setPercentage(Number(response?.data?.promo_code_data?.discount?.replace('%', '')) / 100)
      handleOk();
      notification.success({ message: response?.data?.message });
      setDiscount('');
    }
    catch (error) {
      notification.error({ message: error?.response?.data?.message });
    }
  }

  const handleTotalPrice = () => {
    const price = data?.reduce((accumulator, currentValue) => Number(accumulator) + (Number(currentValue?.price) * Number(currentValue?.no_of_travellers)), 0);
    return price;
  }

  return (
    <Container>

      <Modal width={600} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h4>Promo Code</h4>
              <hr />
            </div>
            <div className="col-md-12  mt-2 text-center">
              <input value={discount} onChange={event => setDiscount(event.target.value)} type="text" placeholder="Enter Your Code Here!" style={{ width: "60%", height: "40px", padding: "10px" }} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 text-center">
              <button onClick={handlePromoCode} style={{ width: "60%", backgroundColor: "#DBB065", border: "none", borderRadius: "10px", height: "40px" }}>Apply Promo Code</button>
            </div>
          </div>
        </div>
      </Modal >


      <Grid marginTop={3} justifyContent="space-around" container spacing={2}>
        <Grid xs={12} sm={12} md={5} lg={6}>
          <Steeper data={data} percen={percentage} promo={typeof promo !== 'number' ? handleTotalPrice() : promo} setPayment={setPayment} payment={payment} userDetails={userDetails} setUserDetails={setUserDetails} />
        </Grid>
        <Grid xs={12} sm={12} md={5} lg={4}>
          <Stack paddingX={2} paddingY={5}>
            <Stack marginY={2}>
              <Typography
                variant="h4"
                sx={{ fontSize: "24px", fontWeight: 700 }}
              >
                Order Summary
              </Typography>
            </Stack>

            <Stack
              sx={{
                border: "2px solid #D9D9D9",

              }}
            >
              <Stack
                sx={{
                  padding: "10px 20px",
                  height: 350,
                  overflow: 'auto'
                }}
              >
                {data?.length && data?.map((data, index) => {
                  return (
                    <div key={index} >
                      <Stack>
                        <Typography
                          variant="h4"
                          sx={{ fontSize: "24px", fontWeight: 700 }}
                        >
                          {data?.all_data?.title}
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{ fontSize: "16px", fontWeight: 400 }}
                        >
                          <div dangerouslySetInnerHTML={{ __html: data?.all_data?.singleLineDetail }} />
                        </Typography>
                      </Stack>

                      <Stack mt={1} direction="row" alignItems="center">
                        <Typography variant="body1">{starRating}.0</Typography>
                        <Stack direction="row" marginLeft={1}>
                          <Stack>
                            <Rating name="half-rating-read" value={starRating} precision={0.5} readOnly />
                          </Stack>
                        </Stack>
                      </Stack>
                      <hr />
                      <Stack direction="column">
                        <Stack direction="row" alignItems="center">
                          <MdOutlinePeopleAlt />
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                            marginLeft={1}
                          >
                            {data?.adult} Adults (Age - 16 -99)
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <AiOutlineCalendar />
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                            marginLeft={1}
                          >
                            {data?.date}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <AiOutlineCheckCircle />
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                            marginLeft={1}
                          >
                            Starting Time : {data?.time_string} (local time)
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <AiOutlineCheckCircle />
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                            marginLeft={1}
                          >
                            Language : English
                          </Typography>
                        </Stack>
                      </Stack>
                      <hr />
                    </div>
                  )
                })}
              </Stack>
            </Stack>
            <Stack
              sx={{ backgroundColor: "#D9D9D9", padding: "10px 20px", }}
              direction="row"
              spacing={3}
              alignItems="center"
            >
              <Typography variant="body2" color="blue">
                <button onClick={showModal} style={{ backgroundColor: "none", border: "none", padding: "10px" }}><AiOutlineGift color="blue" /> Enter gift or promo code</button>
              </Typography>
            </Stack>
            <Stack padding={2} sx={{ backgroundColor: "#D9D9D9" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Stack>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "24px", fontWeight: "bolder" }}
                  >
                    Sub Total
                  </Typography>
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="start"
                  alignItems="start"
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "24px", fontWeight: "bolder" }}
                  >
                    {" "}
                    {handleTotalPrice()?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}
                  </Typography>
                  {typeof promo === "number" &&
                    <Stack direction='column' justifyContent='end'>
                      <Typography>
                        After Promo Code
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "24px", fontWeight: "bolder" }}
                      >
                        {" "}
                        {Number(promo)?.toFixed(2)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'}
                      </Typography>
                    </Stack>
                  }
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "10px",
                      color: "blue",
                      fontWeight: "bolder",
                    }}
                  >
                    All taxes and fees included
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container >
  );
};

export default CheckOutProcess;
