import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectHotel: "",
    name: ''
}

export const counterSlice = createSlice({
    name: 'hotel_data',
    initialState,
    reducers: {
        selectHoteltData: (state, action) => {
            state.selectHotel = [action.payload]
        },
        selectHoteltName: (state, action) => {
            state.name = action?.payload
        },
        deleteHotelData: (state) => {
            state.selectHotel = []
        }
    },
})


export const { selectHoteltData, selectHoteltName } = counterSlice.actions

export default counterSlice.reducer