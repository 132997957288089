import { Stack, Typography, Grid, Container, Button } from "@mui/material";
import React from "react";
import "./CityTour.css";
import textImg from "../../../assets/textImg.png";
import bgTour from "../../../assets/bgTour2.jpg";
import bgTourMain from "../../../assets/bgTour.jpg";
const CityTourStatic = () => {

  const ChatFunc = () => {
    window.open('https://wa.me/+971505042027?text=Hi, can I get more information?', '_blank');
  }

  return (
    <>
      <div className="customTourBg">
        <div className="textImageContainer">
          <img loading='lazy'
            src={bgTourMain}
            alt="Main Background Image"
            className="mainBackgroundImage"
          />
          <img loading='lazy' src={textImg} alt="Text Image" className="centeredImage" />
        </div>
      </div>

      <Container>
        <Grid mt={4} container spacing={1} justifyContent="center">
          <Grid sm={12} md={9} lg={9}>
            <Stack
              direction="row"
              justifyContent="center"
              alignContent="center"
            >
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 700, fontSize: "32px", color: "black" }}
                variant="h5"
              >
                Discover the World’s Most Unforgettable Travel Experiences with
                Your Trusted Partner Agile Tourism.
              </Typography>
            </Stack>
          </Grid>

          <Grid sm={12} md={9} lg={9}>
            <Stack
              direction="column"
              justifyContent="center"
              alignContent="center"
            >
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                Agile Tourism is a leading travel company based in the United
                Arab Emirates that offers a complete range of travel services
                such as flight bookings, hotel reservations, and holiday
                packages. Our team of experienced travel experts is passionate
                about creating incredible travel experiences for our clients.
              </Typography>
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                At Agile Tourism, we understand the importance of hassle-free
                travel, which is why our services are tailored to meet your
                every need. Whether you’re looking for a romantic getaway for
                two, a family vacation, or a corporate retreat, we have the
                expertise to curate a bespoke travel experience.
              </Typography>

              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                Our range of travel services includes flight bookings to any
                destination worldwide, hotel reservations, and vacation packages
                that cater to your every need. We have partnered with some of
                the world’s best airlines and hotels to provide our clients with
                the best deals and discounts available. Our team of experts
                monitors the travel industry to ensure that our clients get the
                best value for their money.
              </Typography>
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                Our user-friendly website provides an intuitive interface that
                allows customers to sort by destination, date, and budget. We
                strive to make the booking process easy and convenient for our
                clients. Our search and booking system is designed to help you
                find the best deals on flights and hotels based on your travel
                preferences.
              </Typography>
            </Stack>
          </Grid>

          <Grid mt={5} sm={12} md={9} lg={9}>
            <Stack
              direction="row"
              justifyContent="center"
              alignContent="center"
            >
              <img loading='lazy' src={bgTour} width="100%" />
            </Stack>
          </Grid>

          <Grid sm={12} md={9} lg={9}>
            <Stack
              direction="column"
              justifyContent="center"
              alignContent="center"
            >
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                We also ensure the safety and security of our clients by
                providing secure payment gateways, data encryption, and SSL
                certification. Our website is designed to protect client data
                and provide a secure booking experience. This is why our clients
                trust us to handle their travel arrangements.
              </Typography>
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                In addition, we provide reviews and ratings from previous
                customers on our website as social proof of the quality of
                service we offer. Our clients have consistently given us
                positive feedback about our services, and we are proud to have
                built a reputation as a trusted and reliable travel company.
              </Typography>
              <Typography
                textAlign="center"
                mt={5}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                In conclusion, Agile Tourism is committed to providing our
                clients with the best travel experiences. We are dedicated to
                ensuring that our clients’ travel plans are hassle-free and
                enjoyable. Our range of travel services, personalized customer
                service, and commitment to excellence make us the go-to travel
                company for your next adventure. Contact us today to learn more
                about our services and to book your next travel experience.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Stack direction="column" spacing={8} mt={3} sx={{ backgroundColor: "#D9D9D9", padding: "60px" }}>
        <Stack>
          <Typography
            textAlign="center"
            sx={{ color: "black", fontSize: "48px", fontWeight: 700 }}
            variant="h4"
          >
            Your trip, your way
          </Typography>
        </Stack>
        <Stack>
          <Typography
            textAlign="center"
            sx={{ color: "black", fontSize: "24px", fontWeight: 500 }}
            variant="h4"
          >
            Find the best things to do wherever you’re going.
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Button onClick={() => window.open('https://wa.me/+971505042027?text=Hi, can i get more information?', '_blank')} rel='noopener noreferrer' sx={{ backgroundColor: "#BD00FF", padding: "2px 60px", borderRadius: "23px", fontWeight: 600, fontSize: "24px" }} textAlign="center" variant="contained">
            Start planning
          </Button>
        </Stack>
      </Stack>



      <Container>
        <Grid mt={4} container spacing={1} justifyContent="center">
          <Grid sm={12} md={7} lg={7}>
            <Stack
              direction="row"
              justifyContent="center"
              alignContent="center"
            >
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 700, fontSize: "32px", color: "black" }}
                variant="h5"
              >
                Discover the World’s Most Unforgettable Travel Experiences with
                Your Trusted Partner Agile Tourism.
              </Typography>
            </Stack>
          </Grid>

          <Grid sm={12} md={8} lg={8}>
            <Stack
              direction="column"
              justifyContent="center"
              alignContent="center"
            >
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                Agile Tourism is a leading travel company based in the United
                Arab Emirates that offers a complete range of travel services
                such as flight bookings, hotel reservations, and holiday
                packages. Our team of experienced travel experts is passionate
                about creating incredible travel experiences for our clients.
              </Typography>
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                At Agile Tourism, we understand the importance of hassle-free
                travel, which is why our services are tailored to meet your
                every need. Whether you’re looking for a romantic getaway for
                two, a family vacation, or a corporate retreat, we have the
                expertise to curate a bespoke travel experience.
              </Typography>

              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                Our range of travel services includes flight bookings to any
                destination worldwide, hotel reservations, and vacation packages
                that cater to your every need. We have partnered with some of
                the world’s best airlines and hotels to provide our clients with
                the best deals and discounts available. Our team of experts
                monitors the travel industry to ensure that our clients get the
                best value for their money.
              </Typography>
              <Typography
                textAlign="center"
                mt={3}
                sx={{ fontWeight: 400, fontSize: "21px", color: "black" }}
                variant="h5"
              >
                Our user-friendly website provides an intuitive interface that
                allows customers to sort by destination, date, and budget. We
                strive to make the booking process easy and convenient for our
                clients. Our search and booking system is designed to help you
                find the best deals on flights and hotels based on your travel
                preferences.
              </Typography>
            </Stack>
          </Grid>



        </Grid>
      </Container>


      <Stack mt={5} direction="column" spacing={8} sx={{ backgroundColor: "#D9D9D9", padding: "60px" }}>
        <Stack>
          <Typography
            textAlign="center"
            sx={{ color: "black", fontSize: "48px", fontWeight: 700 }}
            variant="h4"
          >
            Need Help?
          </Typography>
        </Stack>
        <Stack>
          <Typography
            textAlign="center"
            sx={{ color: "black", fontSize: "24px", fontWeight: 500 }}
            variant="h4"
          >
            Whatever you need, we’re here to chat.
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Button sx={{ backgroundColor: "#BD00FF", padding: "2px 80px", borderRadius: "23px", fontWeight: 600, fontSize: "20px" }} textAlign="center" variant="contained" onClick={ChatFunc}>
            Contact
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default CityTourStatic;
