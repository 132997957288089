import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Divider, Stack } from "@mui/material";
import bgMain from "../../assets/bgSlider.jpg";
import { FaHeart } from "react-icons/fa";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { notification } from "antd";

export default function MediaCard(props) {
  const [counts, setCount] = useState("")
  const [length, setLenght] = useState("")
  const [lengthReview, setLenghtReview] = useState("")
  const [showOldprice, setshowOld] = useState(true)

  const {
    image,
    front_images,
    title,
    oldPrice,
    newPrice,
    totalDays,
    ageRestriction,
    howManyPeople,
    images,
    rating,
    review,
    data_id,
    tour_type_section
  } = props;

  useEffect(() => {
    if (oldPrice === 0) {
      setshowOld(false)
    } else {
      setshowOld(true)
    }
  }, [])


  const cardStyles = {
    card: {
      height: "524px",
      padding: "0px"
    },
    cardTitle: {
      fontWeight: 600,
      fontSize: "16px",
      width: "100%",
      cursor: "pointer",
      color: "black !important",
      lineHeight: 1.5,
    },
    discountBadge: {
      fontWeight: "400",
      backgroundColor: "#DBB065",
      width: "80px",
      borderRadius: "26px",
      textAlign: "center",
    },
    cardInfo: {
      fontWeight: "bold",
    },
    infoList: {
      fontSize: "14px",
      color: "black",
    },
    infoItem: {
      color: "black",
      fontWeight: 700,
      fontSize: "12px",
    },
    divider: {
      backgroundColor: "black",
      margin: 4
    },
    originalPrice: {
      textDecoration: "line-through",
      fontSize: "20px",
    },
    discountedPrice: {
      fontSize: "20px",
      fontWeight: 600,
    },
    perPerson: {
      fontSize: "10px",
    },
    buyButton: {
      padding: "5px 26px",
      borderRadius: "24px",
      backgroundColor: "#BD00FF",
      textDecoration: "underline",
      width: 220,
      marginTop: 12,
      textDecoration: "none"
    },
    heartIcon: {
      position: "relative",
      top: "10px",
      left: "0px",
      fontSize: "30px",
    },
  };

  console.log(props?.data_id, 'asasas')


  const dataImg = JSON.parse(images);
  const firstImage = dataImg[0];

  const handleAddWhistlist = async (data) => {
    console.log(data.data_id[0], "data get");
    try {
      const token = localStorage.getItem('Token');
      const userId = localStorage.getItem('user');
      if (userId) {
        const response = await axios.post('https://developer-ma.com/ag_website/api/addtowishlist', { 'user_id': userId, 'activity_type': props?.activity_type, 'activity_id': data?.id }, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        notification.success({ message: response?.data?.message })
        props?.fetchWistlist();
      }
      else {
        notification.error({ message: 'Please Login For Adding Whistlish' });
      }
    } catch (error) {
      notification.error({ message: 'Something went wrong' });

    }
  };



  // const id = localStorage.getItem('Token')?.split('|')?.[0];
  const id = localStorage.getItem('user');

  const location = useLocation();
  let packageData = location?.state?.data


  let dataIndex = 0;
  let arrlenght = 0;
  let reviewCount = 0;

  useEffect(() => {
    arrlenght = rating.length;
    rating.map((i) => {
      dataIndex += +i?.rating;
      const formula = dataIndex / arrlenght;
      setLenght(formula)
    })

    rating.map((i) => {
      setLenghtReview(arrlenght)
    })


  })



  console.log(props.id, "whichid");
  return (

    <Card style={cardStyles.card} >
      <div style={cardStyles.heartIcon}>
        <Stack style={{ cursor: 'pointer' }} direction="row" justifyContent="end" className="me-3">
          {props?.data_id?.includes(props?.id) ?
            <AiFillHeart onClick={() => notification.info({ message: 'Already Add in Wishlist' })} color="#DBB065" stroke="red" /> :
            <AiOutlineHeart onClick={() => handleAddWhistlist(props)} color="#DBB065" stroke="red" />
          }
        </Stack>
      </div>


      <CardMedia
        component="img"
        sx={{ height: 200, marginTop: "-50px", objectFit: 'cover', width: "100%", height: "260px" }}
        image={JSON.parse(front_images)[0]}
        alt="green iguana"
      />

      <div className="media-card">
        <CardContent style={{ paddingBottom: 0, height: "130px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to={`/citytour/${title?.toLowerCase()?.replaceAll(' ', '-')}`} style={{ color: "black" }} state={{ data: { ...props, fetchWistlist: '' } }}>
              <Typography
                style={cardStyles.cardTitle}
                gutterBottom
                variant="body1"
                component="div"
              >
                {title}
              </Typography>
            </Link>
            <div
              style={{
                alignItems: "center",
              }}
            >
              <Typography
                noWrap
                style={cardStyles.discountBadge}
                gutterBottom
                variant="body1"
                component="div"
              >
                <span className="rating" style={{ fontSize: "12px" }}>
                  {Math.round(length)}.0
                </span>
              </Typography>
              <div
                className="reviews"
                style={{ fontSize: "10px", textAlign: "center" }}
              >
                ({lengthReview} Reviews)
              </div>
            </div>
          </Stack>
          <Stack>
            <Typography
              noWrap
              style={cardStyles.cardInfo}
              variant="body2"
              color="text.secondary"
            >
              <div style={cardStyles.infoList}>
                <li style={cardStyles.infoItem}>{tour_type_section}</li>
                <li style={cardStyles.infoItem}>{totalDays}</li>
                <li style={cardStyles.infoItem}>
                  Minimum People {howManyPeople}
                </li>
              </div>
            </Typography>
          </Stack>

        </CardContent>
        <Divider style={cardStyles.divider} />


        <CardContent style={{ paddingTop: 0 }}>
          <Typography noWrap style={cardStyles.originalPrice} variant="body1">
            {showOldprice && (
              `${oldPrice} AED`
            )}
            {!showOldprice && (
              <div style={{ height: "25px" }}>
                {" "}
              </div>
            )}
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Typography style={cardStyles.discountedPrice} noWrap variant="body1">
              {newPrice?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} AED <span style={cardStyles.perPerson}>per person</span>
            </Typography>
          </Stack>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: "5px" }}>
            <Link to={`/${props.activity_type}/${title?.toLowerCase()?.replaceAll(' ', '-')}`} state={{ data: { ...props, fetchWistlist: '' } }} style={{ textDecoration: "none" }} >
              <Button variant="contained" style={cardStyles.buyButton} >
                BUY NOW
              </Button>
            </Link>
          </div>
        </CardContent>
      </div >
    </Card >
  );
}
