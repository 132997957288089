import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectHotelOffer: ["Salman", "salman"],
}

export const counterSlice = createSlice({
    name: 'hotel_offer',
    initialState,
    reducers: {
        selectHoteltoffer: (state, action) => {
            state.selectHotelOffer = [action.payload]
            
        },
        deleteHotelData: (state) => {
            state.selectHotelOffer = []
        }
    },
})


export const { selectHoteltoffer } = counterSlice.actions

export default counterSlice.reducer