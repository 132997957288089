import { Suspense, lazy, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import VisaImage_1 from '../../assets/VisaImages/Mask group.png';
import VisaImage_2 from '../../assets/VisaImages/_Sshutterstock_672416686 1.png';
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import image from '../../assets/desination.png'
import { axiosInstance } from "../../config/http/http";
import axios from "axios";
import { addCart } from "../../store/cartSlice";
import "./Home.css";
import { Link } from "react-router-dom";
import Chat from "../../components/WhatsAppButton/whatsappButton";
const SearchBar = lazy(() => import("../../components/SearchBar/searchbar"))
const CarouselComponent = lazy(() => import("../../components/Carousel/carousel"))
const TabBarMain = lazy(() => import("../mainTabBar/mainTabBar"))
const NavbarNew = lazy(() => import("../../components/Navbar/NavbarNew"))
const HotelsList = lazy(() => import("../HotelTab/HotelFilter"))




const Home = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('Token');
  const [Style, setStyle] = useState(false);
  const [tabManage, setTabManage] = useState({ first: false, second: false, third: true, fourth: false });
  const data_content_show = useSelector((state) => state.ShowContent);
  const showContent = data_content_show["showContent"]
  const [showCard, setShowCard] = useState(false);
  const [filter, setFilter] = useState('');
  const [filterAttraction, setFilterAttraction] = useState('');
  const [filterAdventure, setFilterAdventure] = useState('');
  const [cityTourData, setCityTourData] = useState([]);
  const [attraction, setattraction] = useState([]);
  const [adventure, setAdventure] = useState('');
  const [vipTransportation, setVipTransportation] = useState('');
  const [transportation, setTransportation] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [id, setId] = useState({ city: '', adventure: '', attraction: '', vip: '', transportation: '' });



  const [tourData_city, setTourData_city] = useState([]);
  const [tourData_adventure, setTourData_adventure] = useState([]);
  const [tourData_attraction, setTourData_attraction] = useState([]);
  const [tourData_Vip, setTourData_Vip] = useState([]);
  const [tourData_Trans, setTourData_Trans] = useState([]);




  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])




  const getCityTour = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/city_tour");
      const filterData = response?.data?.data?.filter(item => item?.title.toLowerCase().includes(filter?.toLowerCase()));
      setCityTourData(filterData);
      setLoading(false)
      setTourData_city(response?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getAttraction = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/attraction");
      const filterData = response?.data?.data?.filter(item => item?.title.toLowerCase().includes(filterAttraction?.toLowerCase()));
      setattraction(filterData);
      setLoading(false)
      setTourData_attraction(response?.data?.data)
    } catch (err) {
      console.error(err);
    }
  };

  const getAdventure = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/adventure");
      const filterData = response?.data?.data?.filter(item => item?.title?.toLowerCase()?.includes(filterAdventure?.toLowerCase()));
      setAdventure(filterData);
      setLoading(false)
      setTourData_adventure(response?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getVip = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/vip_transportation");
      const filterData = response?.data?.data?.filter(item => item?.title?.toLowerCase()?.includes(filterAdventure?.toLowerCase()));
      setVipTransportation(filterData);
      setLoading(false)
      setTourData_Vip(response?.data?.data)
    } catch (err) {
      console.error(err);
    }
  };

  const getTransportation = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/transportation");
      const filterData = response?.data?.data?.filter(item => item?.title?.toLowerCase()?.includes(filterAdventure?.toLowerCase()));
      setTransportation(filterData);
      setLoading(false)
      setTourData_Trans(response?.data?.data)
    } catch (err) {
      console.error(err);
    }
  };


  const fetchWistlist = async () => {
    // const id = localStorage.getItem('Token')?.split('|')?.[0];
    const id = localStorage.getItem('user');
    try {
      if (id) {
        const response = await axios.get(`https://developer-ma.com/ag_website/api/wishlist/${id}`)

        const cityID = response?.data?.filter(value => {
          const item = value?.city;
          if (item?.packagetime?.[0]?.package_name === 'citytour') {
            return item?.id
          }
        });
        const a = cityID?.map(item => item?.city?.id);

        const adventureID = response?.data?.filter(value => {
          const item = value?.city;
          if (item?.packagetime?.[0]?.package_name === 'adventure') {
            return item?.id;
          }
        });
        const b = adventureID?.map(item => item?.city?.id);

        const attractionID = response?.data?.filter(item => item?.city?.packagetime?.[0]?.package_name === 'attraction');
        const c = attractionID?.map(item => item?.city?.id);

        const vipID = response?.data?.filter(value => {
          const item = value?.city;
          if (item?.packagetime?.[0]?.package_name === 'viptransportation') {
            return item?.id
          }
        });
        const d = vipID?.map(item => item?.city?.id);

        const transportation = response?.data?.filter(value => {
          const item = value?.city;
          if (item?.packagetime?.[0]?.package_name == 'transportation') {
            return item?.id
          } else {
            return item?.id
          }
        });
        const e = transportation?.map(item => item?.city?.id);


        setId({ city: a, adventure: b, attraction: c, vip: d, transportation: e });
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  };

  const handleGetCart = async () => {
    // const id = localStorage.getItem('Token')?.split('|')?.[0];
    const id = localStorage.getItem('user');
    const token = localStorage.getItem('Token');
    try {
      const response = await axios(`https://developer-ma.com/ag_website/api/cart/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      dispatch(addCart(response?.data?.length));
      console.log(response?.data?.length)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getCityTour();
    getAttraction();
    getAdventure();
    getVip();
    getTransportation();
  }, [activeTab]);

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchWistlist();
    handleGetCart()
  }, [])


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])








  const filteredData_city = tourData_city.filter(item => item.featured === 1);
  const filteredData_adventure = tourData_adventure.filter(item => item.featured === 1);
  const filteredData_attraction = tourData_attraction.filter(item => item.featured === 1);
  const filteredData_Vip = tourData_Vip.filter(item => item.featured === 1);


  const result_city = filteredData_city.slice(0, 4);
  const result_adventure = filteredData_adventure.slice(0, 4);
  const result_attraction = filteredData_attraction.slice(0, 4);
  const result_Vip = filteredData_Vip.slice(0, 4);

  console.log(result_attraction, '22222');





  return (
    <Suspense fallback={<Spin />}>
      <Stack className="customBgMain">
        <CarouselComponent />
        <>
          <div style={{ width: '100%', position: "relative", bottom: 600, right: 0, zIndex: "10" }}>
            <NavbarNew navFromHome={true} Style={Style} />
            <div
              style={{ backgroundColor: Style ? "#F8F8F8B2" : "" }}
            >
              <div className="container">
                <SearchBar
                  filter={activeTab === 1 ? filter : activeTab === 2 ? filterAttraction : filterAdventure}
                  setFilter={activeTab === 1 ? setFilter : activeTab === 2 ? setFilterAttraction : setFilterAdventure}
                  loading={loading}
                  getCityTour={activeTab === 1 ? getCityTour : activeTab === 2 ? getAttraction : getAdventure}
                  setShowCard={setShowCard}
                  setTabManage={setTabManage}
                  setStyle={setStyle}
                />
              </div>
            </div>
          </div>
          {showContent && tabManage.second && (
            <div>
              <HotelsList />
            </div>
          )}
          {!showContent && tabManage.second && (
            <>
             
              {!showCard &&


                <div style={{ marginTop: -260, width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
                  <div style={{ width: '100%', maxWidth: 1140, marginTop: 50 }}>
                    <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending City Tours</h3>
                    <span style={{ color: 'black' }}>Discover the Heart and Soul of the City</span>
                    <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                      {result_city.map((tour) => (
                        <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                          <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                            <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                            <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                              <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                            </div>
                            <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                              <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3 mb-3">
                                <div className="col-md-6">
                                  <div className="col-md-12" style={{ fontSize: 20, fontWeight: 500, color: 'black', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED</div>
                                  <div className="col-md-12" style={{ fontSize: "9px", fontWeight: "900" }}>per person</div>
                                </div>
                                <div className="col-md-6">
                                  <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                                    <button style={{ backgroundColor: "#bd00ff", color: "white", width: "128px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>}
              {!showCard && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
                <div style={{ width: '100%', maxWidth: 1140, marginTop: 16 }}>
                  <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending Attractions</h3>
                  <span style={{ color: 'black' }}>Must-See Attractions and Thrilling Destinations</span>
                  <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                    {result_attraction.map((tour) => (
                      <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                        <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                          <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                          <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                            <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                          </div>
                          <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3 mb-3">
                              <div className="col-md-6">
                                <div className="col-md-12" style={{ fontSize: 20, fontWeight: 500, color: 'black', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED</div>
                                <div className="col-md-12" style={{ fontSize: "9px", fontWeight: "900" }}>per person</div>
                              </div>
                              <div className="col-md-6">
                                <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                                  <button style={{ backgroundColor: "#bd00ff", color: "white", width: "128px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>}
              {!showCard && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
                <div style={{ width: '100%', maxWidth: 1140, marginTop: 16 }}>
                  <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending Adventures</h3>
                  <span style={{ color: 'black' }}>Embark on Adventures That Stir Your Spirit</span>
                  <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                    {result_adventure.map((tour) => (
                      <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                        <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                          <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                          <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                            <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                          </div>
                          <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3 mb-3">
                              <div className="col-md-6">
                                <div className="col-md-12" style={{ fontSize: 20, fontWeight: 500, color: 'black', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED</div>
                                <div className="col-md-12" style={{ fontSize: "9px", fontWeight: "900" }}>per person</div>
                              </div>
                              <div className="col-md-6">
                                <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                                  <button style={{ backgroundColor: "#bd00ff", color: "white", width: "128px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>}
              {!showCard && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
                <div style={{ width: '100%', maxWidth: 1140, marginTop: 16 }}>
                  <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending Luxury Experiences</h3>
                  <span style={{ color: 'black' }}>Exquisite Experiences Crafted for Ultimate Sophistication</span>
                  <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                    {result_Vip.map((tour) => (
                      <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                        <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                          <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                          <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                            <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                          </div>
                          <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3 mb-3">
                              <div className="col-md-6">
                                <div className="col-md-12" style={{ fontSize: 20, fontWeight: 500, color: 'black', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED</div>
                                <div className="col-md-12" style={{ fontSize: "9px", fontWeight: "900" }}>per person</div>
                              </div>
                              <div className="col-md-6">
                                <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                                  <button style={{ backgroundColor: "#bd00ff", color: "white", width: "128px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>}
              {!showCard && (
                <div>
                  <div className="container-fluid p-5" style={{ marginTop: 0, width: '100%', backgroundColor: '#d9d9d9' }}>
                    <div className="container" style={{ width: '100%', maxWidth: 1140 }}>
                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="visaHeading">Visa Assistance</h3>
                        </div>
                        <div className="row bg-white p-1 m-2 d-flex justify-content-between">
                          <div className="col-md-6 p-2">
                            <img src={VisaImage_1} alt="" />
                          </div>
                          <div className="col-md-6 p-3 d-flex justify-content-between flex-column">
                            <div className="col-md-12">
                              <p className="visaSubHeading">Apply your Dubai, UAE Visa</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-between align-items-center">
                              <div className="col-md-10">
                                <p className="subSubHeading"> Assisting clients with visa processing and documentation required for their travel to Dubai.
                                </p>
                              </div>
                              <div className="col-md-2">
                                <Link to={'https://agile.developer-ma.com/dynamic/visa-services'}>
                                  <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Read more</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-5 mt-5">
                        <div className="col-md-12">
                          <h3 className="visaHeading">Transportation Services</h3>
                        </div>
                        <div className="row bg-white p-1 m-2 d-flex justify-content-between">
                          <div className="col-md-6 p-2">
                            <img src={VisaImage_2} alt="" />
                          </div>
                          <div className="col-md-6 p-3 d-flex justify-content-between flex-column">
                            <div className="col-md-12">
                              <p className="visaSubHeading">Transportation</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-between align-items-center">
                              <div className="col-md-10">
                                <p className="subSubHeading"> Providing transportation options, including airport transfers, private car rentals and group transportation for sightseeing tours.
                                </p>
                              </div>
                              <div className="col-md-2">
                                <Link to={'https://agile.developer-ma.com/dynamic/transportation-services'}>
                                  <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Read more</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="visaHeading">Concierge Services</h3>
                        </div>
                        <div className="row bg-white p-1 m-2 d-flex justify-content-between">
                          <div className="col-md-6 p-2">
                            <img src={VisaImage_1} alt="" />
                          </div>
                          <div className="col-md-6 p-3 d-flex justify-content-between flex-column">
                            <div className="col-md-12">
                              <p className="visaSubHeading">Concierge</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-between align-items-center">
                              <div className="col-md-10">
                                <p className="subSubHeading">Providing personalized concierge services to assist clients with any special requests or additional travel arrangements.
                                </p>
                              </div>
                              <div className="col-md-2">
                                <Link to={'https://agile.developer-ma.com/dynamic/concierge-services'}>
                                  <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Read more</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}




          {tabManage.third &&
            <TabBarMain
              fetchWistlist={fetchWistlist}
              id={id}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setFilter={setFilter}
              loading={loading}
              cityTourData={cityTourData}
              attraction={attraction}
              adventure={adventure}
              vip={vipTransportation}
              transportation={transportation}
            />}
          {showCard &&
            <div style={{ marginTop: -300, width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
              <div style={{ width: '100%', maxWidth: 1140, marginTop: 50 }}>
                <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending City Tours</h3>
                <span style={{ color: 'black' }}>Discover the Heart and Soul of the City</span>
                <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                  {result_city.map((tour) => {
                    console.log(tour?.time, 'package Time');
                    return (
                      <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                        <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                          <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                          <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                            <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                          </div>
                          <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3 mb-3">
                              <div className="col-md-6">
                                <div className="col-md-12" style={{ fontSize: 20, fontWeight: 500, color: 'black', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED</div>
                                <div className="col-md-12" style={{ fontSize: "9px", fontWeight: "900" }}>per person</div>
                              </div>
                              <div className="col-md-6">
                                <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                                  <button style={{ backgroundColor: "#bd00ff", color: "white", width: "128px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                </div>
              </div>
            </div>}
          {showCard && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
            <div style={{ width: '100%', maxWidth: 1140, marginTop: 16 }}>
              <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending Attractions</h3>
              <span style={{ color: 'black' }}>Must-See Attractions and Thrilling Destinations</span>
              <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                {result_attraction.map((tour) => (
                  <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                    <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                      <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                      <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                        <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                      </div>
                      <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3 mb-3">
                          <div className="col-md-6">
                            <div className="col-md-12" style={{ fontSize: 20, fontWeight: 500, color: 'black', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED</div>
                            <div className="col-md-12" style={{ fontSize: "9px", fontWeight: "900" }}>per person</div>
                          </div>
                          <div className="col-md-6">
                            <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                              <button style={{ backgroundColor: "#bd00ff", color: "white", width: "128px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>}
          {showCard && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
            <div style={{ width: '100%', maxWidth: 1140, marginTop: 16 }}>
              <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending Adventures</h3>
              <span style={{ color: 'black' }}>Embark on Adventures That Stir Your Spirit</span>
              <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                {result_adventure.map((tour) => (
                  <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                    <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                      <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                      <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                        <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                      </div>
                      <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3 mb-3">
                          <div className="col-md-6">
                            <div className="col-md-12" style={{ fontSize: 20, fontWeight: 500, color: 'black', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED</div>
                            <div className="col-md-12" style={{ fontSize: "9px", fontWeight: "900" }}>per person</div>
                          </div>
                          <div className="col-md-6">
                            <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                              <button style={{ backgroundColor: "#bd00ff", color: "white", width: "128px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>}
          {showCard && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
            <div style={{ width: '100%', maxWidth: 1140, marginTop: 16 }}>
              <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending Luxury Experiences</h3>
              <span style={{ color: 'black' }}>Exquisite Experiences Crafted for Ultimate Sophistication</span>
              <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                {result_Vip.map((tour) => (
                  <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                    <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                      <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                      <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                        <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                      </div>
                      <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3 mb-3">
                          <div className="col-md-6">
                            <div className="col-md-12" style={{ fontSize: 20, fontWeight: 500, color: 'black', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED</div>
                            <div className="col-md-12" style={{ fontSize: "9px", fontWeight: "900" }}>per person</div>
                          </div>
                          <div className="col-md-6">
                            <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                              <button style={{ backgroundColor: "#bd00ff", color: "white", width: "128px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>}
          {showCard && (
            <div>
              <div className="container-fluid p-5" style={{ marginTop: 0, width: '100%', backgroundColor: '#d9d9d9' }}>
                <div className="container" style={{ width: '100%', maxWidth: 1140 }}>
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="visaHeading">Visa Assistance</h3>
                    </div>
                    <div className="row bg-white p-1 m-2 d-flex justify-content-between">
                      <div className="col-md-6 p-2">
                        <img src={VisaImage_1} alt="" />
                      </div>
                      <div className="col-md-6 p-3 d-flex justify-content-between flex-column">
                        <div className="col-md-12">
                          <p className="visaSubHeading">Apply your Dubai, UAE Visa</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="col-md-10">
                            <p className="subSubHeading"> Assisting clients with visa processing and documentation required for their travel to Dubai.
                            </p>
                          </div>
                          <div className="col-md-2">
                            <Link to={'https://agile.developer-ma.com/dynamic/visa-services'}>
                              <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Read more</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 mt-5">
                    <div className="col-md-12">
                      <h3 className="visaHeading">Transportation Services</h3>
                    </div>
                    <div className="row bg-white p-1 m-2 d-flex justify-content-between">
                      <div className="col-md-6 p-2">
                        <img src={VisaImage_2} alt="" />
                      </div>
                      <div className="col-md-6 p-3 d-flex justify-content-between flex-column">
                        <div className="col-md-12">
                          <p className="visaSubHeading">Transportation</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="col-md-10">
                            <p className="subSubHeading"> Providing transportation options, including airport transfers, private car rentals and group transportation for sightseeing tours.
                            </p>
                          </div>
                          <div className="col-md-2">
                            <Link to={'https://agile.developer-ma.com/dynamic/transportation-services'}>
                              <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Read more</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="visaHeading">Concierge Services</h3>
                    </div>
                    <div className="row bg-white p-1 m-2 d-flex justify-content-between">
                      <div className="col-md-6 p-2">
                        <img src={VisaImage_1} alt="" />
                      </div>
                      <div className="col-md-6 p-3 d-flex justify-content-between flex-column">
                        <div className="col-md-12">
                          <p className="visaSubHeading">Concierge</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="col-md-10">
                            <p className="subSubHeading">Providing personalized concierge services to assist clients with any special requests or additional travel arrangements.
                            </p>
                          </div>
                          <div className="col-md-2">
                            <Link to={'https://agile.developer-ma.com/dynamic/concierge-services'}>
                              <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Read more</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}



          {!showContent && tabManage.fourth && (
            <>

              <div style={{ marginTop: 0, width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
                <div style={{ width: '100%', maxWidth: 1140, marginTop: 50 }}>
                  <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending City Tours</h3>
                  <span style={{ color: 'black' }}>Discover the Heart and Soul of the City</span>
                  <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                    {result_city.map((tour) => (
                      <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                        <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                          <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                          <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                            <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                          </div>
                          <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3">
                              <span style={{ fontSize: 20, fontWeight: 500, color: 'black', textAlign: 'center', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED  <sub style={{ fontSize: "9px", fontWeight: "900" }}>per person</sub></span>
                              <span style={{ fontSize: "10px", fontWeight: "bolder" }}></span>
                              <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                                <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                              </Link>
                            </div>
                          </div>
                          <div className="mt-3">

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
                <div style={{ width: '100%', maxWidth: 1140, marginTop: 16 }}>
                  <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending Attractions</h3>
                  <span style={{ color: 'black' }}>Must-See Attractions and Thrilling Destinations</span>
                  <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                    {result_attraction.map((tour) => (
                      <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                        <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                          <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                          <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                            <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                          </div>
                          <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3">
                              <span style={{ fontSize: 20, fontWeight: 500, color: 'black', textAlign: 'center', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED  <sub style={{ fontSize: "9px", fontWeight: "900" }}>per person</sub></span>
                              <span style={{ fontSize: "10px", fontWeight: "bolder" }}></span>
                              <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                                <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                              </Link>
                            </div>
                          </div>
                          <div className="mt-3">

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
                <div style={{ width: '100%', maxWidth: 1140, marginTop: 16 }}>
                  <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending Adventures</h3>
                  <span style={{ color: 'black' }}>Embark on Adventures That Stir Your Spirit</span>
                  <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                    {result_adventure.map((tour) => (
                      <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                        <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                          <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                          <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                            <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                          </div>
                          <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3">
                              <span style={{ fontSize: 20, fontWeight: 500, color: 'black', textAlign: 'center', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED  <sub style={{ fontSize: "9px", fontWeight: "900" }}>per person</sub></span>
                              <span style={{ fontSize: "10px", fontWeight: "bolder" }}></span>
                              <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                                <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                              </Link>
                            </div>
                          </div>
                          <div className="mt-3">

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#d9d9d9' }}>
                <div style={{ width: '100%', maxWidth: 1140, marginTop: 16 }}>
                  <h3 style={{ color: 'black', margin: 0 }} className="fs-2 fw-bolder">Trending Luxury Experiences</h3>
                  <span style={{ color: 'black' }}>Exquisite Experiences Crafted for Ultimate Sophistication</span>
                  <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', justifyContent: 'center', flexDirection: "row" }}>
                    {result_Vip.map((tour) => (
                      <div key={tour.id} style={{ display: 'flex', margin: '24px 2px', position: 'relative' }}>
                        <div style={{ width: 270, height: "auto", backgroundColor: 'white' }}>
                          <img loading='lazy' style={{ width: '100%', height: "200px", objectFit: "cover" }} src={JSON.parse(tour.front_images)[0]} alt={image} />
                          <div style={{ position: 'absolute', top: '10%', left: '20%', transform: 'translate(-20%, -20%)', textAlign: 'left', width: '80%' }}>
                            <div style={{ color: 'white', fontSize: "35px !important", fontWeight: 900, }}>{tour.title}</div>
                          </div>
                          <div style={{ width: '100%', height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 12px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: "space-between", width: "100%" }} className="mt-3">
                              <span style={{ fontSize: 20, fontWeight: 500, color: 'black', textAlign: 'center', lineHeight: '23px', whiteSpace: 'nowrap' }}>{tour.newPrice} AED  <sub style={{ fontSize: "9px", fontWeight: "900" }}>per person</sub></span>
                              <span style={{ fontSize: "10px", fontWeight: "bolder" }}></span>
                              <Link to={`/citytour/${tour?.title?.replaceAll(' ', '-')}`} state={{ data: { ...tour, time: tour?.time } }} >
                                <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Buy Now</button>
                              </Link>
                            </div>
                          </div>
                          <div className="mt-3">

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <div className="container-fluid p-5" style={{ marginTop: -180, width: '100%', backgroundColor: '#d9d9d9' }}>
                  <div className="container" style={{ width: '100%', maxWidth: 1140 }}>
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="visaHeading">Visa Assistance</h3>
                      </div>
                      <div className="row bg-white p-1 m-2 d-flex justify-content-between">
                        <div className="col-md-6 p-2">
                          <img src={VisaImage_1} alt="" />
                        </div>
                        <div className="col-md-6 p-3 d-flex justify-content-between flex-column">
                          <div className="col-md-12">
                            <p className="visaSubHeading">Apply your Dubai, UAE Visa</p>
                          </div>
                          <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <div className="col-md-10">
                              <p className="subSubHeading"> Assisting clients with visa processing and documentation required for their travel to Dubai.
                              </p>
                            </div>
                            <div className="col-md-2">
                              <Link to={'https://agile.developer-ma.com/dynamic/visa-services'}>
                                <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Read more</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-5 mt-5">
                      <div className="col-md-12">
                        <h3 className="visaHeading">Transportation Services</h3>
                      </div>
                      <div className="row bg-white p-1 m-2 d-flex justify-content-between">
                        <div className="col-md-6 p-2">
                          <img src={VisaImage_2} alt="" />
                        </div>
                        <div className="col-md-6 p-3 d-flex justify-content-between flex-column">
                          <div className="col-md-12">
                            <p className="visaSubHeading">Transportation</p>
                          </div>
                          <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <div className="col-md-10">
                              <p className="subSubHeading"> Providing transportation options, including airport transfers, private car rentals and group transportation for sightseeing tours.
                              </p>
                            </div>
                            <div className="col-md-2">
                              <Link to={'https://agile.developer-ma.com/dynamic/transportation-services'}>
                                <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Read more</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="visaHeading">Concierge Services</h3>
                      </div>
                      <div className="row bg-white p-1 m-2 d-flex justify-content-between">
                        <div className="col-md-6 p-2">
                          <img src={VisaImage_1} alt="" />
                        </div>
                        <div className="col-md-6 p-3 d-flex justify-content-between flex-column">
                          <div className="col-md-12">
                            <p className="visaSubHeading">Concierge</p>
                          </div>
                          <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <div className="col-md-10">
                              <p className="subSubHeading">Providing personalized concierge services to assist clients with any special requests or additional travel arrangements.
                              </p>
                            </div>
                            <div className="col-md-2">
                              <Link to={'https://agile.developer-ma.com/dynamic/concierge-services'}>
                                <button style={{ backgroundColor: "#bd00ff", color: "white", width: "100px", border: "none", borderRadius: "20px", fontSize: "15px", padding: "5px" }}>Read more</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </Stack>
      <Chat />
    </Suspense>
  );
};
export default Home;
