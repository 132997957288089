import { Provider } from 'react-redux';
import './App.css';
import RouterApp from './config/router/RouterApp';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { store } from '../src/store/store'
import PageLoader from './components/PageLoader/pageLoader';
import { useEffect, useState } from 'react';

const stripePromise = loadStripe('pk_live_51Nt1OYDJQ8XVibSQKNcTuB5hZ2MWcDNKaSJELyr5wfGkosbKE5LbizSMo6CFl6z4vHGMk3vlJhgSB5eyxpP4A11V00WN2PGyTp');

function App() {
  const [showData, setShowdata] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowdata(true);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        {showData && (
            <RouterApp />
        )}
        {!showData && (
          <PageLoader />
        )}
      </Provider>
    </Elements>
  )
}

export default App;
