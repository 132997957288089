import React, { useEffect, useState } from 'react'
import { Container, Grid, Typography, Stack, Button } from "@mui/material";
import { MdLockOutline } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import { AiOutlineMessage } from "react-icons/ai";
import {
    AiOutlineCalendar,
    AiOutlineCheckCircle,
    AiOutlineDelete,
    AiOutlineStar,
} from "react-icons/ai";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs';
import { Skeleton, notification } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addCart } from '../../store/cartSlice';

const Cart = () => {
    const [cart, setCart] = useState('');
    const dispatch = useDispatch();
    const token = localStorage.getItem('Token');
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            notification.error({ message: 'Please Login For Seeing Cart' });
            navigate('/');
            return
        }
    }, [])


    const handleNavigate = (item) => {
        const packageData = item?.city
        console.log(packageData)
        console.log(item)
        navigate('/checkout', {
            state: [{
                activity_type: packageData?.packagetime?.[0]?.package_name,
                price: packageData?.newPrice,
                id: packageData?.id,
                date: item?.date,
                time: item?.time_id,
                time_string: item?.start_time,
                no_of_travellers: item?.no_of_travellers,
                all_data: packageData,
            }]
        })
    }

    const handleData = (value) => {
        const data = value?.map((item) => {
            const city = item?.city;
            const attraction = item?.attraction;
            const adventure = item?.adventure;
            const viptransportation = item?.viptransportation;
            const packageData = city ? city : attraction ? attraction : adventure ? adventure : viptransportation;
            return {
                activity_type: packageData?.packagetime?.[0]?.package_name,
                price: packageData?.newPrice,
                id: packageData?.id,
                date: item?.date,
                time: item?.time_id,
                time_string: item?.start_time,
                no_of_travellers: item?.no_of_travellers,
                all_data: packageData,
            }
        })
        return data;
    }

    const handleNavigateAll = (item) => {
        navigate('/checkout', {
            state: handleData(item)
        })
    }

    const handleGetCart = async () => {
        // const id = localStorage.getItem('Token')?.split('|')?.[0];
        const id = localStorage.getItem('user');
        const token = localStorage.getItem('Token');
        try {
            const response = await axios(`https://developer-ma.com/ag_website/api/cart/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            setCart(response?.data)
            dispatch(addCart(response?.data?.length));
        } catch (error) {
            console.log(error)
            setCart([])
        }
    };

    useEffect(() => {
        handleGetCart();
    }, []);

    const handleDeleteCart = async (value) => {
        if (!token) {
            return notification.error({ message: 'Please Login For Delete Cart' })
        }
        try {
            const response = await axios.get(`https://developer-ma.com/ag_website/api/cartDelete/${value}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
            notification.success({ message: response?.data?.message });
            handleGetCart();
        } catch (error) {
            console.log(error);
            notification.error({ message: error?.response?.data?.message })
        }
    }

    const totalPrice = () => {
        const price = cart?.reduce((acc, value) => {
            let curr =
                value?.city ? value?.city :
                    value?.attraction ? value?.attraction :
                        value?.adventure ? value?.adventure :
                            value?.viptransportation ? value?.viptransportation :
                                value?.transportation
            const price = acc + (Number(value?.no_of_travellers) * Number(curr?.newPrice));
            return price
        }, 0)
        return price;
    }

    return (token && cart ?
        <Container>
            {cart?.length ? cart?.map((value, index) => {
                const city = value?.city;
                const attraction = value?.attraction;
                const adventure = value?.adventure;
                const viptransportation = value?.viptransportation;
                const transportation = value?.transportation;
                console.log(transportation, '-------')
                const item = city ? city : attraction ? attraction : adventure ? adventure : viptransportation ? viptransportation : transportation;
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                        <Grid
                            marginY={3}
                            container
                            justifyContent="space-between"
                            alignItems="start"
                        >
                            <Grid xs={12} sm={12} md={8} lg={8}>

                                <Stack direction="row" marginY={3} justifyContent="space-between">
                                    <Stack direction="row" spacing={2}>
                                        <img loading='lazy' src={JSON.parse(item?.images)?.[0]?.replace(/\\/g, "")} width="200px" height="200" alt="" />
                                        <Stack width={400}>
                                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                                {item?.title}
                                            </Typography>
                                            <div dangerouslySetInnerHTML={{ __html: item?.singleLineDetail }} />
                                            <Stack direction="row" alignItems="center">
                                                <Typography variant="body1">4.1</Typography>
                                                <Stack direction="row" marginLeft={1}>
                                                    <AiOutlineStar />
                                                    <AiOutlineStar />
                                                    <AiOutlineStar />
                                                    <AiOutlineStar />
                                                    <AiOutlineStar />
                                                </Stack>
                                            </Stack>
                                            <Stack direction="column">
                                                <Stack direction="row" alignItems="center">
                                                    <MdOutlinePeopleAlt />
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ fontSize: "16px" }}
                                                        marginLeft={1}
                                                    >
                                                        Number Of Travellers : {value?.no_of_travellers}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="row" alignItems="center">
                                                    <AiOutlineCalendar />
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ fontSize: "16px" }}
                                                        marginLeft={1}
                                                    >
                                                        {value?.date ? dayjs(value?.date).format('ddd, MMM, DD, YYYY') : ''}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="row" alignItems="center">
                                                    <AiOutlineCheckCircle />
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ fontSize: "16px" }}
                                                        marginLeft={1}
                                                    >
                                                        Starting Time: {value?.start_time} (local time)
                                                    </Typography>
                                                </Stack>
                                            </Stack>

                                            <Stack>
                                                <Link to="/citytour" state={{ data: { ...item, time: item?.packagetime } }} >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ fontSize: "16px" }}
                                                        color="blue"
                                                    >
                                                        Change date or participant(s)
                                                    </Typography>
                                                </Link>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" spacing={3}>
                                        <AiOutlineDelete color="#BD00FF" size={25} style={{ cursor: "pointer" }} onClick={() => handleDeleteCart(value?.cart_item)} />
                                        <Stack>
                                            <Typography marginTop={5} variant="h5">
                                                {item?.newPrice?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} AED
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <hr />
                            </Grid>

                            {index === 0 &&
                                <Grid paddingX={3} marginTop={2} xs={12} sm={12} md={4} lg={4}>
                                    <Stack sx={{ border: "1px solid black", borderRadius: "10px" }}>
                                        <Stack padding={3}>
                                            <Stack direction="row" justifyContent="space-between" alignItems='flex-start'>
                                                <Stack>
                                                    <Typography marginTop={0.95} variant="body2">Total :</Typography>
                                                </Stack>
                                                <Stack>
                                                    <Typography variant="h6">{totalPrice()?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} AED</Typography>
                                                    <Typography variant="caption" sx={{ fontSize: "10px" }}>
                                                        All taxes and fees included
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack marginTop={2}>
                                                <Button
                                                    onClick={() => handleNavigateAll(cart)}
                                                    variant="contained"
                                                    className="w-100"
                                                    sx={{
                                                        backgroundColor: "#BD00FF",
                                                        padding: "8px 16px",
                                                        borderRadius: "25px",
                                                        "&:hover": {
                                                            backgroundColor: "#BD00FF", // Change to the desired hover color
                                                        },
                                                    }}
                                                >
                                                    Checkout
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    <Stack
                                        sx={{
                                            border: "1px solid black",
                                            marginTop: "15px",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <Stack sx={{ padding: "15px" }}>
                                            <Typography fontWeight={700} variant="body1">Why book with us?</Typography>
                                        </Stack>
                                        <Stack padding={3} paddingTop={0}>
                                            <Stack
                                                direction="row"
                                                justifyContent="start"
                                                alignItems="start"
                                                spacing={1}
                                            >
                                                <Stack>
                                                    <MdLockOutline style={{ fontSize: 20 }} />
                                                </Stack>
                                                <Stack direction="column">
                                                    <Typography
                                                        fontSize={15}
                                                        fontWeight={600} variant="body1"
                                                    >
                                                        Secure payment
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                justifyContent="start"
                                                alignItems="start"
                                                spacing={1}
                                                marginY={1.5}
                                            >
                                                <Stack>
                                                    <FaRegCircleCheck style={{ fontSize: 20 }} />
                                                </Stack>
                                                <Stack direction="column">
                                                    <Typography
                                                        fontSize={15}
                                                        fontWeight={600} variant="body1"
                                                    >
                                                        No hidden costs
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                justifyContent="start"
                                                alignItems="start"
                                                spacing={1}
                                            >
                                                <Stack>
                                                    <AiOutlineMessage style={{ fontSize: 20 }} />
                                                </Stack>
                                                <Stack direction="column">
                                                    <Typography
                                                        fontSize={15}
                                                        fontWeight={600} variant="body1"
                                                    >
                                                        24/7 customer support worldwide
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            }
                        </Grid>
                    </div>
                )
            }) : (
                <div>
                    <h1 style={{ marginTop: 24, textAlign: "center" }} className="my-5 py-5">No Cart Found</h1>
                </div>
            )
            }
        </Container>
        :
        <Skeleton paragraph={{ rows: 6 }} />


    )
}

export default Cart;