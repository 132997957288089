import React, { useState } from 'react';
import styles from './Hotel_tab.module.css';
import favourite from '../../assets/favorite.png';
import MapImage from '../../assets/map.png';
import Place from '../../assets/Placwe.png';
import Building from '../../assets/building.png';
import Review from '../../assets/Group 126.png';
import Calendar from '../../assets/calendar_month.png'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { selectHoteltoffer } from '../../store/HotelOffer';
import { DatePicker, Divider, Space, notification } from 'antd';
import { RxCross2 } from 'react-icons/rx';
import { CircularProgress } from '@mui/material';
const { RangePicker } = DatePicker;


function HotelFilter() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.hotel_data);
    const HotelArray = data?.selectHotel?.[0];
    const placeName = useSelector(state => state?.hotel_data?.name);
    const [adultCount, setAdultCount] = useState(HotelArray?.map(item => 0));
    const [room, setRoom] = useState(HotelArray?.map(item => 0));
    const [travellerCount, setTravellerCount] = useState(HotelArray?.map(item => false));
    const [date, setDate] = useState(HotelArray?.map(item => ''));
    const [loading, setLoading] = useState(HotelArray?.map(item => false));


    const handleCardClick = async (hotelArray, index) => {



        if (!date[index]) return notification.error({ message: 'Please Enter Date' });
        else if (!adultCount[index]) return notification.error({ message: 'Please Enter Travellers' });
        else if (!room[index]) return notification.error({ message: 'Please Enter Room Count' });

        const hotelName = hotelArray?.name;
        const hotelId = hotelArray?.hotelId;
        const placeId = hotelArray?.imagess?.[0]?.place_id;
        const loader = [...loading];
        loader[index] = true;
        setLoading(loader);
        try {
            const response = await axios.post(
                `https://developer-ma.com/ag_website/api/hotelsearch?hotelIds=${hotelId}&adults=${adultCount[index]}&checkinDate=${date?.[index]?.[0]}&checkOutDate=${date?.[index]?.[1]}&roomQuantity=${room[index]}&includeClosed=${true}`,
            )
            if (response?.data?.data?.[0]?.available) {
                const response2 = await axios.get(`https://developer-ma.com/ag_website/api/place-details/${placeId}`)
                const loader = [...loading];
                loader[index] = false;
                setLoading(loader);
                return navigate('/hotelavailiblity', { state: { data: response?.data, images: response2?.data, guest: adultCount[index], room: room[index], checkin: date[index], } })
            }
            const loader = [...loading];
            loader[index] = false;
            setLoading(loader);
            return notification.error({ message: 'Hotel Unavailable' })
        } catch (error) {
            console.log(error);
            const loader = [...loading];
            loader[index] = false;
            setLoading(loader);
        }
    };

    const handleDate = (originalDate, dateString, index) => {
        const data = [...date];
        console.log(index)
        data[index] = dateString;
        setDate(data)
    }





    const ratings = [
        { stars: 5, label: '5 stars' },
        { stars: 4, label: '4 stars' },
        { stars: 3, label: '3 stars' },
        { stars: 2, label: '2 stars' },
        { stars: 1, label: '1 stars' },
        { stars: 0, label: 'Unrated' },
    ];

    const Accomdation = [
        { name: "Apartment" },
        { name: "Hotel" },
        { name: "Holiday Rental" },
        { name: "Resort" },
        { name: "Guest House" },
        { name: "Hotel" },
        { name: "Residence" },
        { name: "Private Home" },
    ];

    const Cancellation = [
        { name: "Free Cancellation" },
        { name: "Non Refundable" },
    ];

    const Meal = [
        { name: "Breakfast Included" },
        { name: "Meal not Included" },
    ];

    const Airlines = [
        { name: "Wi-Fi" },
        { name: "Airport Shuttle" },
        { name: "Parking" },
        { name: "Fitness" },
        { name: "Pool" },
        { name: "Spa" },
        { name: "Laundary" },
        { name: "Bar" },
    ];

    const Neighbour = [
        { name: "The Palm Jumeriah" },
        { name: "Deira" },
        { name: "Jumeriah" },
        { name: "Bur Dubai" },
        { name: "Al Barsha" },
        { name: "Dubai Land" },
        { name: "Desert" },
        { name: "Abu Dhabi" },
    ];


    const handleRoomCount = (index) => {
        const data = [...room];
        data[index] = data[index] + 1;
        setRoom(data);
    };

    const handleRoomDecrement = (index) => {
        const data = [...room];
        if (data[index] === 0) return;
        data[index] = data[index] - 1;
        setRoom(data);
    };

    const handleIncrement = (index) => {
        const data = [...adultCount];
        data[index] = data[index] + 1;
        setAdultCount(data);
    };

    const handleDecrement = (index) => {
        const data = [...adultCount];
        if (data[index] === 0) return;
        data[index] = data[index] - 1;
        setAdultCount(data);
    };

    const handleOpen = (index) => {
        const data = [...travellerCount];
        data[index] = true;
        setTravellerCount(data);
    }

    const handleClose = (index) => {
        const data = [...travellerCount];
        data[index] = false;
        setTravellerCount(data);
    }
    return (
        <div className="container-fluid" style={{ backgroundColor: '#E0E0E0', marginTop: "-250px" }}>
           
            <div className='container-fluid py-5'>
                <div className="row">
                    <div className="col-md-3">
                        <div className={styles.map}>
                            <div className="mapImage">
                                <img loading='lazy' src={MapImage} alt="" />
                            </div>
                            <p>See on map</p>
                        </div>
                        <div className={styles.filter}>
                            <div className={styles['filter-label']}>Price</div>
                            <div className={styles['filter-range']}>
                                <div className={styles['filter-range-label']}>0</div>
                                <div className={styles['filter-range-slider']}>
                                    <input type="range" min="0" max="5000" step="1" />
                                </div>
                                <div className={styles['filter-range-label']}>5000</div>
                            </div>
                            <div className={styles['filter-currency']}>
                                <span style={{ backgroundColor: 'white', border: '1px solid black', borderRadius: '5px', width: "50px", textAlign: 'center' }}>AED</span>
                                <span>-</span>
                                <span style={{ backgroundColor: 'white', border: '1px solid black', borderRadius: '5px', width: "50px", textAlign: 'center' }}>AED</span>
                            </div>
                        </div>
                        <div className={`${styles['star-rating']} ${styles.spacingClass}`}>
                            <div className={styles['star-label']}>Star Rating</div>
                            {ratings.map((rating, index) => (
                                <div className={`col-md-12  py-2 ${styles['star-container']}`} key={index}>
                                    <input type="radio" id={`rating-${rating.stars}`} name="rating" className={styles['star-radio']} />
                                    <label htmlFor={`rating-${rating.stars}`} className={styles['star-label']}>
                                        {rating.label}
                                    </label>
                                    {Array.from({ length: rating.stars }, (_, starIndex) => (
                                        <img loading='lazy'
                                            key={starIndex}
                                            src={favourite}
                                            alt=""
                                            className={styles.star}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className={`${styles['star-rating']} ${styles.spacingClass}`}>
                            <div className={styles['star-label']}>Accommodation type</div>
                            {Accomdation.map((accommodation, index) => (
                                <div className={`col-md-12  py-2 ${styles['star-container']}`} key={index}>
                                    <input type="radio" id={`rating-${accommodation.name}`} name="rating" className={styles['star-radio']} />
                                    <label htmlFor={`rating-${accommodation.name}`} className={styles['star-label']}>
                                        {accommodation.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className={`${styles['star-rating']} ${styles.spacingClass}`}>
                            <div className={styles['star-label']}>Cancellation Policy</div>
                            {Cancellation.map((Cancellation, index) => (
                                <div className={`col-md-12  py-2 ${styles['star-container']}`} key={index}>
                                    <input type="radio" id={`rating-${Cancellation.name}`} name="rating" className={styles['star-radio']} />
                                    <label htmlFor={`rating-${Cancellation.name}`} className={styles['star-label']}>
                                        {Cancellation.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className={`${styles['star-rating']} ${styles.spacingClass}`}>
                            <div className={styles['star-label']}>Meal Plan</div>
                            {Meal.map((Meal, index) => (
                                <div className={`col-md-12  py-2 ${styles['star-container']}`} key={index}>
                                    <input type="radio" id={`rating-${Meal.name}`} name="rating" className={styles['star-radio']} />
                                    <label htmlFor={`rating-${Meal.name}`} className={styles['star-label']}>
                                        {Meal.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className={`${styles['star-rating']} ${styles.spacingClass}`}>
                            <div className={styles['star-label']}>Airlines</div>
                            {Airlines.map((Airlines, index) => (
                                <div className={`col-md-12  py-2 ${styles['star-container']}`} key={index}>
                                    <input type="radio" id={`rating-${Airlines.name}`} name="rating" className={styles['star-radio']} />
                                    <label htmlFor={`rating-${Airlines.name}`} className={styles['star-label']}>
                                        {Airlines.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className={`${styles['star-rating']} ${styles.spacingClass}`}>
                            <div className={styles['star-label']}>Neighbourhoods</div>
                            {Neighbour.map((Neighbour, index) => (
                                <div className={`col-md-12  py-1 ${styles['star-container']}`} key={index}>
                                    <input type="radio" id={`rating-${Neighbour.name}`} name="rating" className={styles['star-radio']} />
                                    <label htmlFor={`rating-${Neighbour.name}`} className={styles['star-label']}>
                                        {Neighbour.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-12" style={{ fontWeight: "500", fontSize: '40px', color: 'black', textTransform: 'capitalize' }}>{placeName} Hotels</div>
                            {HotelArray?.map((hotelArray, index) => {
                                const image = hotelArray?.imagess[0]?.photo_reference?.length ?
                                    `https://developer-ma.com/ag_website/api/place-photos/${hotelArray?.imagess[0]?.photo_reference[0]?.photo_reference}` :
                                    '/hotels.jpg'
                                return (hotelArray?.status !== 400
                                    ?
                                    <div key={index} className="row my-3">
                                        <div className='row' style={{ backgroundColor: 'white', margin: '5px', padding: '15px', border: '1px solid black', borderRadius: '5px' }}>
                                            <div className="col-md-4">
                                                <img loading='lazy' src={`${image}`} height={200} width={280} alt="" />

                                            </div>
                                            <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRight: '1px solid black' }}>
                                                <div className="title">
                                                    <h5>{hotelArray.name}<img loading='lazy' src={Building} alt="" /></h5>
                                                    <img loading='lazy' src={Review} alt="" /> <br />
                                                    <span style={{ fontSize: '14px', fontWeight: 400, color: '#000000' }}>0.25 miles from Burj Al Arab </span>
                                                </div>
                                                <div className="review">
                                                    <span style={{ color: '#BD00FF', fontSize: '14px', fontWeight: 600 }}>{hotelArray.rating}/5 Excellent</span>
                                                </div>
                                                <div className={styles.button}>
                                                    <button>Great for Families</button>
                                                </div>
                                            </div>
                                            <div className="col-md-4" style={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 8, justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                                                    <RangePicker allowClear={false} style={{ marginTop: 0 }} onChange={(date, dateString) => handleDate(date, dateString, index)} />
                                                    <div style={{ display: 'flex', border: '1px solid gray', padding: 5, borderRadius: 4, justifyContent: 'space-between', alignItems: 'center', gap: '10' }}>
                                                        <span style={{ fontWeight: 600 }}>Adult</span>
                                                        <div>
                                                            <button onClick={() => handleDecrement(index)} style={{ width: 30, borderRadius: 50, backgroundColor: '#dbb065', color: 'white', border: 'none', fontWeight: 'bolder' }}>-</button>
                                                            <span style={{ padding: 10, width: 50 }}>{adultCount[index]}</span>
                                                            <button onClick={() => handleIncrement(index)} style={{ width: 30, borderRadius: 50, backgroundColor: '#dbb065', color: 'white', border: 'none', fontWeight: 'bolder' }}>+</button>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', border: '1px solid gray', padding: 5, borderRadius: 4, justifyContent: 'space-between', alignItems: 'center', gap: '10' }}>
                                                        <span style={{ fontWeight: 600 }}>Number of Rooms</span>
                                                        <div>
                                                            <button onClick={() => handleRoomDecrement(index)} style={{ width: 30, borderRadius: 50, backgroundColor: '#dbb065', color: 'white', border: 'none', fontWeight: 'bolder' }}>-</button>
                                                            <span style={{ padding: 10, width: 50 }}>{room[index]}</span>
                                                            <button onClick={() => handleRoomCount(index)} style={{ width: 30, borderRadius: 50, backgroundColor: '#dbb065', color: 'white', border: 'none', fontWeight: 'bolder' }}>+</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.buttonAvailiblity}>
                                                    <button onClick={() => handleCardClick(hotelArray, index)}>{!loading[index] ? 'See Availability And Price' : <div style={{ display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'center' }}><span>See Availability And Price</span> <CircularProgress size={18} color='inherit' /></div>}</button> <img loading='lazy' src={Calendar} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : 'Nothing Found'
                                );
                            })}

                        </div>

                    </div>
                </div>
            </div>
        </div >
    )

}

export default HotelFilter