import React from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import './whatsapp.css';
import { Link } from 'react-router-dom';

function WhatsappButton() {
    return (
        <Link to="https://wa.me/+971505042027?text=Hi, can i get more information?" target='_blank' rel='noopener noreferrer' className="whatsapp-btn">
            <IoLogoWhatsapp size={30} color='white' />
        </Link>
    );
}

export default WhatsappButton;
