import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import Favourite from '../../../assets/Group 126.png';
import styles from './HotelDetails.module.css';
import Building from '../../../assets/building.png'
import RoomCard from '../../../components/Cards/RoomCard';
import Place from '../../../assets/Placwe.png';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function HotelDetails() {

  const location = useLocation();
  console.log(location?.state, 'lll');
  const data = location?.state;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  const Data = [
    {
      "title": "Twin Room",
      "paragraphs": [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "Suspendisse in justo at odio venenatis tincidunt.",
        "Aenean non quam ac quam ultricies interdum."
      ],
      "buttonText": "Make a Reservation",
      "imageUrl": Place
    },
    {
      "title": "Deluxe Room",
      "paragraphs": [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "Suspendisse in justo at odio venenatis tincidunt.",
        "Aenean non quam ac quam ultricies interdum."
      ],
      "buttonText": "Make a Reservation",
      "imageUrl": Place
    },
    {
      "title": "King Room",
      "paragraphs": [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "Suspendisse in justo at odio venenatis tincidunt.",
        "Aenean non quam ac quam ultricies interdum."
      ],
      "buttonText": "Make a Reservation",
      "imageUrl": Place
    }
  ]

  const hotelInfoArray = [
    {
      title: "Hotel Information",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos amet pariatur soluta? Quisquam ut aliquam consectetur quos, quaerat dolore fugit accusantium quae. Dolorem quas nihil eum hic, quisquam accusamus ad!",
    },
    {
      title: "Another Hotel Information",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos amet pariatur soluta? Quisquam ut aliquam consectetur quos, quaerat dolore fugit accusantium quae. Dolorem quas nihil eum hic, quisquam accusamus ad!",
    },
    {
      title: "Yet Another Hotel Information",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos amet pariatur soluta? Quisquam ut aliquam consectetur quos, quaerat dolore fugit accusantium quae. Dolorem quas nihil eum hic, quisquam accusamus ad!",
    },
    {
      title: "One More Hotel Information",
      content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos amet pariatur soluta? Quisquam ut aliquam consectetur quos, quaerat dolore fugit accusantium quae. Dolorem quas nihil eum hic, quisquam accusamus ad!",
    },
  ];

  return (data &&
    <>
      <div className="container-fluid" style={{ backgroundColor: '#E0E0E0' }}>
        <div className="container">
          <div className="row">
            <div className={styles.title}>{data?.data?.data?.[0]?.hotel?.name}</div>
            <div className="review"><img loading='lazy' src={Favourite} alt="" /></div>
            <div className="status">0.28 miles from Burj Al Arab</div>
          </div>

          <div className="row align-items-center">
            <div className="col-md-8 border">
              <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                {data?.images?.length && data?.images?.map(item => {
                  return (
                    <SwiperSlide style={{ height: 500 }} key={item} >
                      <img loading='lazy' width={'100%'} height={'100%'} src={item} alt={item} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
            <div className="col-md-4" style={{ backgroundColor: 'white', border: "1px solid black", borderRadius: '6px', padding: '12px' }}>
              <div className={`${styles.sideCardOne} col-md-12`}>
                <div className={styles.name}>
                  <div style={{ fontSize: '20px' }}>{data?.data?.data?.[0]?.hotel?.name}</div>
                  <div style={{ fontSize: '18px' }}>{data?.data?.data?.[0]?.offers?.[0]?.guests?.adults} Adults</div>
                </div>
                <div className="icon">
                  <img loading='lazy' src={Building} alt="" style={{ height: '53px' }} />
                </div>
              </div>
              <div className="row my-3">
                <div className={`${styles.sideCardTwo} col-md-12`}>
                  <div className={styles.name}>
                    <div style={{ fontSize: '24px' }}>Total</div>
                    <div style={{ fontSize: '16px' }}>Inclusive of fees, Taxes and VAT</div>
                  </div>
                  <div className="icon">
                    <div style={{ fontSize: '24px', fontWeight: "500" }}>{data?.data?.data?.[0]?.offers?.[0]?.price?.total?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    <div style={{ fontSize: '24px', float: 'right', fontWeight: "600" }}>{data?.data?.data?.[0]?.offers?.[0]?.price?.currency}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <h6 style={{ fontSize: '16px', fontWeight: '600' }}>Hotel Feature</h6>
                <p style={{ fontSize: '15px' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod odit reprehenderit dolorum officia officiis laudantium inventore placeat, consequatur distinctio repudiandae tempora in nobis vitae! Quia debitis unde modi iure natus.</p>
              </div>
              <div className="col-md-12">
                <h6 style={{ fontSize: '16px', fontWeight: '600' }}>Hotel ammenities</h6>
                <ul>
                  <li>Dry cleaning/Laudary servise.</li>
                  <li>Wheelchair accessible (may have limitations).</li>
                  <li>Parking (limited space).</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className={`{${styles.RoomsHeading} col-md-12}`}>
              <h5>Rooms</h5>
            </div>
            {Data.map((card, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <RoomCard
                  data={data}
                  title={card.title}
                  paragraphs={card.paragraphs}
                  buttonText={card.buttonText}
                  imageUrl={card.imageUrl}
                />
              </div>
            ))}
          </div>

          {hotelInfoArray.map((info, index) => (
            <div className="row" key={index}>
              <div className="col-md-12 py-3">
                <h5 style={{ fontSize: "18px", fontWeight: '700' }}>{info.title}</h5>
                <p style={{ fontSize: "16px", fontWeight: 'lighter' }}>{info.content}</p>
              </div>
            </div>
          ))
          }

        </div>
      </div>

    </>
  )
}

export default HotelDetails