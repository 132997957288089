import { Form, Input, Spin, Button, Upload, Skeleton, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Stack, Typography } from "@mui/material";
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Chat from '../../components/WhatsAppButton/whatsappButton'

const DynamicPages = () => {
    const Navigate = useNavigate();
    const { id } = useParams();
    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`https://developer-ma.com/ag_website/api/page/${id}`)
            console.log(response)
            if (response?.data) {
                setContent(response?.data?.data)
            }
        }
        fetchData();
    }, [id])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])

    const ChatFunc = () => {
        window.open('https://wa.me/+971505042027?text=Hi, can I get more information?', '_blank');
    }

    const initialFormData = {
        name: '',
        phone_number: '',
        nationality: '',
        type_visa: '',
        number_pax: '',
        child_age: '',
        photo: '',
        passport_first_page: '',
        passport_second_page: '',
        additional_document1: '',
        additional_document2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [loading, setLoading] = useState(false);



    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                'https://developer-ma.com/ag_website/api/llvisaform',
                formData,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );
            console.log('Response:', response);
            notification.success({
                message: "Thank You We Will get Back to You within 24 hours"
            });
            setFormData(initialFormData);
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } catch (error) {
            console.error('Error:', error);
            notification.error({
                message: 'An error occurred. Please try again later.'
            });
        } finally {
            setLoading(false);
        }
    };


    return (content ?
        <div style={{ width: '100%' }} className=''>
            <img loading='lazy' style={{ width: '100%', height: '520px', objectFit: 'cover' }} src={content?.header_hero_image} alt={content?.header_hero_image} />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '100%', maxWidth: '1180px', margin: '24px 0px' }} dangerouslySetInnerHTML={{ __html: content?.content }} />
            </div>

            <Stack mt={0} direction="column" spacing={0} sx={{ backgroundColor: "#D9D9D9", padding: "20px" }}>
                {/* <Stack>
                    <Typography
                        textAlign="center"
                        sx={{ color: "black", fontSize: "48px", fontWeight: 700 }}
                        variant="h4"
                    >
                        Need Help?
                    </Typography>
                </Stack> */}
                {/* <Stack>
                    <Typography
                        textAlign="center"
                        sx={{ color: "black", fontSize: "24px", fontWeight: 500 }}
                        variant="h4"
                    >
                        Whatever you need, we’re here to chat.
                    </Typography>
                </Stack> */}
                <div className='col-md-12'>
                    <Form onFinish={handleSubmit} className="rounded p-5 card" layout='vertical'>
                        <h1 className='col-md-12 text-center mx-auto' style={{ fontSize: '24px', fontWeight: '600' }}> FILL THE FOLLOWING & GET A QUOTE</h1>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name' }]}>
                                    <Input style={{ height: '40px' }} placeholder="Name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Phone Number" name="phone_number" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                                    <Input style={{ height: '40px' }} type="tel" placeholder="Phone" value={formData.phone_number} onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item label="Nationality" name="nationality" rules={[{ required: true, message: 'Please enter your nationality' }]}>
                                    <Input style={{ height: '40px' }} placeholder="Nationality" value={formData.nationality} onChange={(e) => setFormData({ ...formData, nationality: e.target.value })} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Type of Visa" name="type_visa" rules={[{ required: true, message: 'Please enter type of visa' }]}>
                                    <Input style={{ height: '40px' }} placeholder="Type of Visa" value={formData.type_visa} onChange={(e) => setFormData({ ...formData, type_visa: e.target.value })} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item label="Number of Pax" name="number_pax" rules={[{ required: true, message: 'Please enter number of pax' }]}>
                                    <Input style={{ height: '40px' }} type="number" placeholder="Number of Pax" value={formData.number_pax} onChange={(e) => setFormData({ ...formData, number_pax: e.target.value })} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Child Age" name="child_age" rules={[{ required: true, message: 'Please enter child age' }]}>
                                    <Input style={{ height: '40px' }} type="number" placeholder="Child Age" value={formData.child_age} onChange={(e) => setFormData({ ...formData, child_age: e.target.value })} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item label="Upload Your Photo" name="photo" rules={[{ required: true, message: 'Please upload your Photo' }]}>
                                    <Input style={{ height: '40px' }} type="file" placeholder="Child Age" onChange={(info) => setFormData({ ...formData, photo: info.target.files[0] })} />
                                    {/* <Upload
                                        fileList={formData.photo}
                                        onChange={(info) => handleUpload('photo', info)}
                                        maxCount={1}
                                        beforeUpload={() => false}
                                    >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload> */}
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Upload Passport 1st Page" name="passport_first_page" rules={[{ required: true, message: 'Please upload your passport first page' }]}>
                                    <Input style={{ height: '40px' }} type="file" placeholder="Child Age" onChange={(info) => setFormData({ ...formData, passport_first_page: info.target.files[0] })} />
                                    {/* <Upload
                                        fileList={formData.passport_first_page}
                                        onChange={(info) => handleUpload('passport_first_page', info)}
                                        maxCount={1}
                                        beforeUpload={() => false}
                                    >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload> */}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item label="Upload Passport 2nd Page" name="passport_second_page" rules={[{ required: true, message: 'Please upload your passport second page' }]}>
                                    <Input style={{ height: '40px' }} type="file" placeholder="Child Age" onChange={(info) => setFormData({ ...formData, passport_second_page: info.target.files[0] })} />
                                    {/* <Upload
                                        fileList={formData.passport_second_page}
                                        onChange={(info) => handleUpload('passport_second_page', info)}
                                        maxCount={1}
                                        beforeUpload={() => false}
                                    >                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload> */}
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Upload Additional Document 1" name="additional_document1" rules={[{ required: true, message: 'Please upload additional document 1' }]}>
                                    <Input style={{ height: '40px' }} type="file" placeholder="Child Age" onChange={(info) => setFormData({ ...formData, additional_document1: info.target.files[0] })} />
                                    {/* <Upload
                                        fileList={formData.additional_document1}
                                        onChange={(info) => handleUpload('additional_document1', info)}
                                        maxCount={1}
                                        beforeUpload={() => false}
                                    >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload> */}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item label="Upload Additional Document 2" name="additional_document2" rules={[{ required: true, message: 'Please upload additional document 2' }]}>
                                    <Input style={{ height: '40px' }} type="file" placeholder="Child Age" onChange={(info) => setFormData({ ...formData, additional_document2: info.target.files[0] })} />
                                    {/* <Upload
                                        fileList={formData.additional_document2}
                                        onChange={(info) => handleUpload('additional_document2', info)}
                                        maxCount={1}
                                        beforeUpload={() => false}
                                    >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload> */}
                                </Form.Item>
                            </div>
                        </div>
                        <Spin spinning={loading}>
                            <div className="col-md-12 d-flex align-items-center justify-content-end">
                                <Form.Item>
                                    <Button
                                        style={{
                                            backgroundColor: '#BD00FF',
                                            borderRadius: '12px',
                                            color: 'white',
                                            height: '48px',
                                            fontSize: '17px'
                                        }}
                                        htmlType="submit"
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Spin>
                    </Form>



                </div>
            </Stack>
            <Chat />
        </div >
        :
        <Skeleton rows={12} paragraph={{
            rows: 12,
        }} />
    )
}

export default DynamicPages