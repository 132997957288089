import React, { useState } from 'react';
import {
    MDBContainer,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBBtn,
    MDBInput,
    MDBCheckbox
}
    from 'mdb-react-ui-kit';
import axios from 'axios';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';



function Registration() {
    const [justifyActive, setJustifyActive] = useState('tab1');;

    const navigate = useNavigate();
    const [name, setName] = useState('')
    const [lname, setLastName] = useState('')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleLastName = (e) => {
        setLastName(e.target.value);
    };


    const handleLogin = () => {
        const apiUrl = 'https://developer-ma.com/ag_website/api/login';

        axios.post(apiUrl, { email, password })
            .then((response) => {
                console.log('Login successful:', response.data);
                localStorage.setItem("Token", response.data.token)
                notification.success({
                    message: "Login successful",
                });
                setEmail('')
                setPassword('')
                navigate('/');
            })
            .catch((error) => {
                console.error('Login failed:', error);
                notification.error({
                    message: "Invalid Email Address and Password",
                });
            });
    };


    const handleRegister = () => {
        const apiUrl = 'https://developer-ma.com/ag_website/api/register';

        axios.post(apiUrl, { email, password, name, lname })
            .then((response) => {
                console.log('successful:', response.data.token);
                localStorage.setItem("Token", response.data.token)
                notification.success({
                    message: "User Registered Successfully!",
                });
                navigate("/")
                setEmail('')
                setPassword('')
                setName('')
                setLastName('');
            })
            .catch((error) => {
                console.error('Login failed:', error);
                notification.error({
                    message: "Registration failed",
                });
            });
    };



    const handleJustifyClick = (value) => {
        if (value === justifyActive) {
            return;
        }

        setJustifyActive(value);
    };

    return (
        <MDBContainer className="p-3 my-5 d-flex flex-column" style={{ width: "40%" }}>
            <MDBTabs pills justify className='mb-3 d-flex flex-row justify-content-between'>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'} >
                        Login
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                        Register
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>

                <MDBTabsPane show={justifyActive === 'tab1'}>
                    <MDBInput wrapperClass='mb-4 mt-2' label='Email address' id='form1' type='email' value={email} onChange={handleEmailChange} />
                    <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password' value={password} onChange={handlePasswordChange} />

                    <div className="d-flex justify-content-between mx-4 mb-4">
                        <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
                        <a href="!#">Forgot password?</a>
                    </div>

                    <MDBBtn className="mb-4 w-100" onClick={handleLogin}>Sign in</MDBBtn>
                    <p className="text-center">Not a member? <a href="#!">Register</a></p>

                </MDBTabsPane>

                <MDBTabsPane show={justifyActive === 'tab2'}>
                    <MDBInput wrapperClass='mb-4' label='Name' id='form1' type='text' value={name} onChange={handleName} />
                    <MDBInput wrapperClass='mb-4' label='Last Name' id='form1' type='text' value={lname} onChange={handleLastName} />
                    <MDBInput wrapperClass='mb-4' label='Email' id='form1' type='email' value={email} onChange={handleEmailChange} />
                    <MDBInput wrapperClass='mb-4' label='Password' id='form1' type='password' value={password} onChange={handlePasswordChange} />

                    <div className='d-flex justify-content-center mb-4'>
                        <MDBCheckbox name='flexCheck' id='flexCheckDefault' label='I have read and agree to the terms' />
                    </div>

                    <MDBBtn className="mb-4 w-100" onClick={handleRegister}>Sign up</MDBBtn>

                </MDBTabsPane>

            </MDBTabsContent>

        </MDBContainer>
    );
}

export default Registration;