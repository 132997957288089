import { CircularProgress, Container } from '@mui/material'
import { Button, Collapse, Modal, notification } from 'antd';
import styles from '../../FlightTab/Flight_ticket.module.css';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './index.css'
import { useEffect } from 'react';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const { Panel } = Collapse;


const HotelBooking = () => {

    const modalRef = useRef(null);
    const location = useLocation('');
    const [loader, setLoader] = useState(false);
    const data = location?.state;
    const [form, setForm] = useState({ title: 'MR', firstName: '', lastName: '', phone: '', email: '', note: '' });
    const [card, setCard] = useState({ cardNumber: '', date: '' })
    const token = localStorage.getItem('Token');
    const [bookingData, setBookingData] = useState('');
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    console.log(data)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])

    const handleFormChange = (input, value) => {
        setForm({ ...form, input: value });
        console.log(input, value);
    }

    const handleGuestData = () => {
        const data = form?.map((item, index) => {
            return {
                id: index + 1,
                name: {
                    title: item?.title,
                    firstName: item?.firstName,
                    lastName: item?.lastName
                },
                contact: {
                    phone: item?.phone,
                    email: item?.email,
                }
            }
        })
        return data;
    }

    const handleBooking = async () => {
        const empty = false
        if (empty || card?.cardNumber === '' || card?.date === '') {
            return notification.error({ message: 'Enter All Details' })
        }
        setLoader(true);
        try {
            const bookingData = {
                offerId: data?.data?.data?.[0]?.offers?.[0]?.id,
                guests: [
                    {
                        id: 1, name:
                        {
                            title: form?.title,
                            firstName: form?.firstName,
                            lastName: form?.lastName
                        },
                        contact: {
                            phone: form?.phone,
                            email: form?.email,
                        }
                    }],
                payments: [{
                    id: 1, method: 'creditCard', "card": {
                        "vendorCode": 'VI',
                        "cardNumber": card?.cardNumber,
                        "expiryDate": dayjs(card?.date).format('YYYY-MM')
                    }
                }],
                rooms: [{
                    guestIds: [1],
                    "paymentId": 1,
                    "specialRequest": form?.specialRequest
                }]
            }
            const response = await axios.post('https://developer-ma.com/ag_website/api/hotelbooking', {
                data: bookingData,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            )
            setLoader(false);
            if (response && !response?.data?.errors) {
                const response2 = await axios.post('https://developer-ma.com/ag_website/api/adminData', {
                    name: form?.firstName + form?.lastName,
                    email: form?.email,
                    phone: form?.phone,
                    hotelName: data?.data?.data?.[0]?.hotel?.name,
                    noOfAdult: data?.guest,
                    noOfRoom: data?.room,
                    price: data?.data?.data?.[0]?.offers?.[0]?.price?.total,
                    reference: response?.data?.data?.[0]?.associatedRecords?.[0]?.reference,
                    bookingId: response?.data?.data?.[0]?.id,
                    providerConfirmationId: response?.data?.data?.[0]?.providerConfirmationId,
                    checkin: `${data?.checkin?.[0]}/${data?.checkin?.[1]}`
                },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    });
                if (response2) {
                    notification.success({ message: 'Booking Successful' });
                    setBookingData(response?.data?.data?.[0]);
                    setOpen(true);
                }
                return;
            }
            notification.error({ message: response?.data?.errors?.[0]?.title });
        } catch (error) {
            console.log(error)
            setLoader(false);
        }
    }

    const download = () => {
        const modalContent = modalRef?.current;
        if (modalContent) {
            html2canvas(modalContent).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const doc = new jsPDF('landscape', 'mm', [600, 400]);
                const width = 190;
                const height = (width * canvas.height) / canvas.width;
                doc.addImage(imgData, 'PNG', 10, 10, 550, 380);
                doc.save('receipt.pdf');
            }).then(() => navigate('/'))
        }
    };


    return (
        <div style={{ backgroundColor: 'rgb(224, 224, 224)', height: 'fit-content' }}>
            <Modal
                width={800}
                centered
                open={open}
                title=""
                onCancel={() => setOpen(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={download}>
                        Download Receipt
                    </Button>
                ]}
            >
                <div ref={modalRef}>
                    <div className="container-fluid p-2">
                        <div style={{ background: "#DBAF66", width: '100%' }} className='p-3' >
                            <h3 className='text-center text-white'>&hearts; Thank You For Booking With Agile Tourism &hearts;</h3>
                        </div>
                        <h3 className='my-2'>{data?.data?.data?.[0]?.hotel?.name}</h3>
                        <div className='my-3' style={{ width: '100%', border: '2px solid gray', borderRadius: '10px' }}>
                            <div className="content text-center p-2 ">
                                <h3 style={{ color: '#cd61dd' }}>Your hotel reservation is complete</h3>
                                <div className='d-flex justify-content-center' style={{ marginTop: "24px" }}>
                                </div>
                                <div className='text-center'>
                                    <h5 className='my-2'>Booking Reference</h5>
                                    <button style={{ width: 'fit-content', paddingInline: 14, heigh: '35px', border: "1px solid gray" }} >{bookingData?.associatedRecords?.[0]?.reference}</button>

                                    <h5 className='mt-3 mb-2' >Provider Confirmation ID</h5>
                                    <button style={{ width: 'fit-content', heigh: '35px', border: "1px solid gray" }} >{bookingData?.providerConfirmationId}</button>
                                    <h5 className='mt-3 mb-2' >Number of Rooms</h5>
                                    <button style={{ width: 'fit-content', heigh: '35px', border: "1px solid gray", padding: '4px 16px' }} >{data?.room}</button>
                                </div>
                                <p className='mt-3' style={{ color: '#DBAF66' }}>Welcome</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Container style={{ padding: '24px 12px' }}>
                <h3 className='py-2' style={{ color: '#000' }}>Guest Information</h3>
                <Collapse defaultActiveKey={['1']} className='my-2'>
                    <Panel key={'1'} header={`Guest # ${1}`} style={{ backgroundColor: 'white' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label style={{ width: '100%' }}>Title</label>
                                    <select
                                        value={form.title}
                                        onChange={(e) => setForm({ ...form, 'title': e.target.value })}
                                        style={{ width: '100%', height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 4, fontSize: 16 }}
                                        name="" id="">
                                        <option value="MR">Mr</option>
                                        <option value="MRS">Mrs</option>
                                    </select>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label style={{ width: '100%' }}>First Name:</label>
                                    <input
                                        style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                        type="text"
                                        className=""
                                        value={form?.firstName}
                                        onChange={(e) => setForm({ ...form, 'firstName': e.target.value })}
                                    />
                                </div>
                                <div className="col-md-6 form-group my-3">
                                    <label>Last Name:</label>
                                    <input
                                        type="text"
                                        style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                        value={form?.lastName}
                                        onChange={(e) => setForm({ ...form, 'lastName': e.target.value })}
                                    />
                                </div>
                                <div className="col-md-6 form-group my-3">
                                    <label>Email:</label>
                                    <input
                                        type="email"
                                        style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                        value={form?.email}
                                        onChange={(e) => setForm({ ...form, 'email': e.target.value })}
                                    />
                                </div>
                                <div className="col-md-6 form-group booking">
                                    <label>Phone Number:</label>
                                    <PhoneInput
                                        style={{ width: 546 }}
                                        defaultCountry="ae"
                                        value={form?.phone}
                                        onChange={(value) => setForm({ ...form, 'phone': value })}
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Special Request:</label>
                                    <textarea
                                        value={form.note}
                                        onChange={(e) => setForm({ ...form, 'note': e.target.value })}
                                        style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                        name="" id="" cols="30" rows="2"></textarea>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </Collapse>
                <h3 className='my-4' style={{ color: 'black' }}>Payment Information</h3>
                <div style={{
                    backgroundColor: 'white',
                    border: '1px solid black',
                    borderRadius: 5,
                    padding: 20,
                    height: 220
                }}>
                    <div className="row">
                        <div style={{ color: 'black', fontWeight: '700' }} className="col-md-12 mb-3">
                            <span>Credit card / Debit Card</span>
                        </div>
                        <div className="col-md-4 form-group">
                            <label style={{ width: '100%' }}>Card Number:</label>
                            <input
                                style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                type="number"
                                className=""
                                value={card.cardNumber}
                                onChange={(e) => setCard({ ...card, cardNumber: e.target.value })}
                            />
                        </div>
                        <div className="col-md-4 form-group">
                            <label style={{ width: '100%' }}>Expiry Date:</label>
                            <input
                                style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                type="date"
                                className=""
                                value={card.date}
                                onChange={(e) => setCard({ ...card, date: e.target.value })}
                            />
                        </div>
                        {/* <div className="col-md-4 form-group">
                            <label style={{ width: '100%' }}>Vendor Code:</label>
                            <select
                                value={card.vendorCode}
                                onChange={(e) => setCard({ ...card, vendorCode: e.target.value })}
                                style={{ width: '100%', paddingRight: 8, height: '36px', borderRadius: 4, border: '1px solid #BD00FF', outline: 'none', paddingLeft: 14, fontSize: 16 }}
                                name="" id=""
                            >
                                {data?.data?.data?.[0]?.offers?.[0]?.policies?.guarantee?. <option value="card">Card</option>}
                            </select>
                        </div> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: 32 }}>
                        <div className='d-flex'>
                            <h3>Total Amount: </h3>
                            <h3 style={{ marginLeft: 12 }}> {data?.data?.data?.[0]?.offers?.[0]?.price?.total?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {data?.data?.data?.[0]?.offers?.[0]?.price?.currency}</h3>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: 24 }}>
                    <div className='hotel-booking'>
                        <button onClick={handleBooking}>
                            {loader ? (
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', fontSize: '15px' }}>
                                    Confirming Booking
                                    <CircularProgress color="inherit" size={18} />
                                </div>
                            ) : (
                                " Confirming Booking"
                            )}
                        </button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default HotelBooking