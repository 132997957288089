import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showContent: false
}

export const counterSlice = createSlice({

    name: 'ShowContent',
    initialState,
    reducers: {
        ShowContent: (state, action) => {
            console.log(action.payload)
            state.showContent = action.payload
        }
    },
})

export const { ShowContent } = counterSlice.actions

export default counterSlice.reducer