import React from 'react';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;

const DateRangePicker = ({style}) => (
    <Space direction="vertical" size={12} >
        <RangePicker style={style}/>
    </Space>
);

export default DateRangePicker;