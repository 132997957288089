import { createSlice } from '@reduxjs/toolkit';


const CartSlice = createSlice({
    name: 'cart',
    initialState: { count: 0 },

    reducers: {
        addCart: (state, action) => {
            state.count = action?.payload;
        }
    }
})


export default CartSlice.reducer;

export const { addCart } = CartSlice.actions;