import React from 'react'

function HotelReservation() {
    return (
        <div className="contaier-fluid" style={{ backgroundColor: "#e0e0e0" }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12"> 1.Personal {">"} 2.Additions {">"} 3.Payment</div>
                    <div className="col-md-12" style={{ fontWeight: '600', fontSize: '24px' }}>Enter Your Personal details</div>
                </div>
                <div className="row">
                    <div className="col-md-3" style={{ fontSize: '12px', fontWeight: 600 }}>Checkout is fast and secure</div>
                    <div className="col-md-3" style={{ fontSize: '12px', fontWeight: 600, float: "right", display: 'flex', justifyContent: 'flex-end' }}>*Required feilds</div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="col-md-12">
                            <label htmlFor="">
                                <input type="text" name="" id="" placeholder='*Full Name' />
                            </label>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="">
                                <input type="text" name="" id="" placeholder='*Full Name' />
                            </label>
                        </div>
                        <div className="col-md-9">
                            <label htmlFor="" style={{ display: "flex", flexDirection: "column" }}>
                                <select name="" id="">
                                    <option value="">Turkey (+90) </option>
                                </select>
                                <input type="number" />
                            </label>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </div>
        </div>
    )
}

export default HotelReservation