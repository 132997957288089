import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../config/http/http';
import { CircularProgress, Grid } from '@mui/material';
import axios from 'axios';
import MediaCard from '../../components/Cards/MediaCard';
import Chat from '../../components/WhatsAppButton/whatsappButton'


const Transportation = () => {

    const [data, setData] = useState('');
    const [cityTourData, setCityTourData] = useState('');
    const [id, setId] = useState({ city: '', adventure: '', attraction: '', vip: '', transportation: '' });

    const getCityTour = async () => {
        try {
            const response = await axiosInstance.get("/transportation");
            setCityTourData(response?.data?.data);
        } catch (err) {
            console.error(err);
        }
    };

    const fetchWistlist = async () => {
        const tokenID = localStorage.getItem('user');;
        try {
            if (tokenID) {
                const response = await axios.get(`https://developer-ma.com/ag_website/api/wishlist/${tokenID}`)

                const cityID = response?.data?.filter(value => {
                    const item = value?.city;
                    if (item?.packagetime?.[0]?.package_name === 'citytour') {
                        return item?.id
                    }
                });
                const a = cityID?.map(item => item?.city?.id);

                const adventureID = response?.data?.filter(value => {
                    const item = value?.city;
                    if (item?.packagetime?.[0]?.package_name === 'adventure') {
                        return item?.id;
                    }
                });
                const b = adventureID?.map(item => item?.city?.id);

                const attractionID = response?.data?.filter(value => {
                    const item = value?.city;
                    if (item?.packagetime?.[0]?.package_name === 'attraction') {
                        return item?.id;
                    }
                });
                const c = attractionID?.map(item => item?.city?.id);

                const vipID = response?.data?.filter(value => {
                    const item = value?.city;
                    if (item?.packagetime?.[0]?.package_name === 'viptransportation') {
                        return item?.id
                    }
                });

                const d = vipID?.map(item => item?.city?.id);

                const TransportID = response?.data?.filter(value => {
                    const item = value?.city;
                    if (item?.packagetime?.[0]?.package_name === 'Transportation') {
                        return item?.id
                    }
                });

                const e = TransportID?.map(item => item?.city?.id);

                setId({ city: a, adventure: b, attraction: c, vip: d, TransportID: e });
            }
        } catch (error) {
            console.log(error)
        }
    };



    useEffect(() => {
        (async () => {
            try {
                const response = await axiosInstance('bannersliders');
                setData(response?.data?.[5])
                console.log(response?.data)
            } catch (error) {
                console.log(error)
            }
        })();
        fetchWistlist();
        getCityTour();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])

    return ((data && cityTourData) ?
        <div style={{ width: '100%', backgroundColor: '#d9d9d9', paddingBottom: 24 }}>
            <div
                style={{
                    width: '100%',
                    height: '600px',
                    position: 'relative',
                    backgroundImage: `url(${JSON.parse(data?.images)?.[0]})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}

            >
                <img width='100%' height='600px' src={JSON.parse(data?.images)?.[0]} alt={JSON.parse(data?.images)?.[0]} />
                {/* <p style={{ fontWeight: 900, fontSize: 100, fontFamily: '', color: 'white' }}>{data?.bannername}</p> */}
            </div>
            <div style={{ margin: '24px 32px' }}>
                <h3
                    style={{ fontWeight: 700, fontSize: "36px", color: "black", letterSpacing: "0.5px" }}
                    className="p-2 mb-3"
                >
                    Airport Transfer
                </h3>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="start"
                    alignItems="start"
                    alignContent="center"
                    wrap="wrap"
                >
                    {cityTourData.length ? cityTourData?.map((e) => (
                        <Grid key={e?.id} item xs={12} sm={6} md={4} lg={3}>
                            <MediaCard fetchWistlist={fetchWistlist} {...e} activity_type={'adventure'} data_id={id?.adventure} />
                        </Grid>
                    )) : <h1 style={{ marginLeft: 16 }}>No Data Found</h1>}
                </Grid>
            </div>
            <Chat />
        </div> :
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "600px",
            }}
        >
            <CircularProgress color="secondary" />
        </div>
    )
}

export default Transportation