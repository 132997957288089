import React, { useState } from "react";
import styles from "./order.module.css";
import { BsPin } from "react-icons/bs";
import clock from "../../assets/nest_clock_farsight_analog.png";
import Eventbusy from "../../assets/event_busy.png";
import CalenderComponent from "./calender";
import DateRangePicker from "../DatePickerRange/RangeDate";

function Order() {
  const [visible, setVisible] = useState(false);
  const handleDropdownClick = () => {
    setVisible(true);
  };

  return (
    <>
      <div className="container">
        <div className={`${styles.CitySlot}`}>
          <div className="row p-4">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5 style={{ fontWeight: "bolder" }}>Luxirious RAK City Tour</h5>
            </div>
            <div className="timeLocation">
              <div className="row py-2">
                <div className="col-12 py-2">
                  <img loading='lazy' src={clock} alt="" /> 6 Hours
                </div>
                <div className="col-12 py-2">
                  <BsPin /> Meet at London, United Kingdom
                </div>
              </div>
              <hr style={{ color: "black" }} />
            </div>
            <div className="timeLocation">
              <div className="row py-2">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h5 style={{ fontWeight: "bolder" }}>
                    Select a starting time
                  </h5>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 ">
                  <button className={`${styles.startingTimeButtonActive} m-2`}>
                    8:45 am
                  </button>
                  <button
                    className={`${styles.startingTimeButtonInactive} m-2`}
                  >
                    1:15 am
                  </button>
                </div>
              </div>
              <hr style={{ color: "black" }} />
            </div>
          </div>

          <div>
            <div className="row p-4">
              <div
                className="col-lg-9 col-md-9 col-sm-9"
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <img loading='lazy' src={Eventbusy} alt="" style={{ height: "16px" }} />
                Cancel befotre 12:15 PM on June 12 For a full Refund
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">Price breakdown</div>
            </div>

            <div className={`${styles.CardFooter} p-4`}>
              <div className="row">
                <div className="col-lg-8">
                  <div className="col">
                    <h5>Total Price</h5>
                  </div>
                  <div className="col">
                    <h5>₺3,331.90</h5>
                  </div>
                  <div className="col">
                    <h5>All taxes and fees included</h5>
                  </div>
                </div>
                <div className="col-lg-4 d-flex align-items-center justify-content-center">
                  <button>Add to Cart</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Order;
